import {
  ADD_OFFER_REQUEST_FAIL,
  ADD_OFFER_REQUEST_SEND,
  ADD_OFFER_REQUEST_SUCCESS,
  GET_OFFERS_REQUEST_FAIL,
  GET_OFFERS_REQUEST_SEND,
  GET_OFFERS_REQUEST_SUCCESS,
  SET_STATUS_FALSE,
  UPDATE_OFFER_STATUS_REQUEST_FAIL,
  UPDATE_OFFER_STATUS_REQUEST_SEND,
  UPDATE_OFFER_STATUS_REQUEST_SUCCESS,
} from "../actionType";

const initialState = {
  loading: false,
  status: false,
  offers: [],
  error: null,
};

const offerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_OFFER_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case ADD_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: [...state.offers, payload],
        status: true,
      };

    case ADD_OFFER_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case GET_OFFERS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case GET_OFFERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: payload,
        status: true,
      };

    case GET_OFFERS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_OFFER_STATUS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case UPDATE_OFFER_STATUS_REQUEST_SUCCESS:
      const itemIndex = state.offers.findIndex(
        (item) => item.id === payload.id
      );
      if (itemIndex !== -1) {
        state.offers[itemIndex] = payload;
      }

      return {
        ...state,
        loading: false,
        status: true,
        offers: [...state.offers],
      };

    case UPDATE_OFFER_STATUS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SET_STATUS_FALSE:
      return {
        ...state,
        status: false,
      };

    default:
      return state;
  }
};

export default offerReducer;

import React, { useEffect } from "react";
import styled from "styled-components";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import {
  getAllCoupons,
  updateCouponAppliedKey,
  updateCouponSearchKey,
  updateCouponStatus,
  updateCouponStatusKey,
  visibleCouponToUser,
} from "../../../store/Coupon/CouponAction";
import AppPagination from "../../../components/AppPagination";
import Search from "../../../components/Search";
import SelectOptions from "../../../components/SelectOptions";
import {
  couponAppliedOptions,
  statusFilterOptions,
} from "../../../assets/staticData";
const CouponList = () => {
  const dispatch = useDispatch();

  const {
    loading,
    searchKey,
    statusKey,
    appliedKey,
    coupons,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
  } = useSelector((state) => state.couponReducer);

  useEffect(() => {
    if (searchKey || statusKey || appliedKey) {
      calCouponsList(true);
    } else {
      calCouponsList();
    }
  }, [searchKey, statusKey, appliedKey]);

  const calCouponsList = (refresh = false) => {
    // console.log(searchKey);
    dispatch(getAllCoupons(refresh));
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Coupon"
              breadcrumbItem="List"
              loading={loading}
              callList={calCouponsList}
              isAddNew={true}
              addNewRoute="coupon/add"
            />

            <Card>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <div>
                      <SelectOptions
                        label="Status"
                        value={statusKey}
                        options={statusFilterOptions}
                        onChange={(e) => dispatch(updateCouponStatusKey(e))}
                        name="status"
                      />
                    </div>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <Search
                      dispatchFunc={updateCouponSearchKey}
                      isLabel={true}
                      isPadding={false}
                    />
                  </Col>
                  <Col md={3}>
                    <SelectOptions
                      label="Applied By"
                      value={appliedKey}
                      options={couponAppliedOptions}
                      onChange={(e) => dispatch(updateCouponAppliedKey(e))}
                      name="applied"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Coupon List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Code</Th>
                      <Th>Type</Th>
                      <Th>Amount/Percentage</Th>
                      <Th>Date Range</Th>
                      <Th>Apply For</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {coupons.length > 0 &&
                      coupons.map((item, index) => {
                        return (
                          <Tr
                            key={index}
                            className="align-middle"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            <Th>{item?.code}</Th>
                            <Td className="text-capitalize">{item?.type}</Td>
                            <Td>{item?.amount}</Td>
                            <Td>
                              <p>
                                {new Date(item?.startDate).toLocaleDateString()}
                              </p>
                              <span>To</span>
                              <p>
                                {new Date(item?.endDate).toLocaleDateString()}
                              </p>
                            </Td>
                            <Td className="text-capitalize">
                              {item?.applyType}
                            </Td>
                            <Td className="text-capitalize">{item?.status}</Td>
                            <Td>
                              <div>
                                <Tooltip
                                  title={`${
                                    item.status === "active"
                                      ? "Inactive"
                                      : "Active"
                                  }`}
                                >
                                  <button
                                    className={`btn  button ${
                                      item?.status === "active"
                                        ? "btn-danger"
                                        : "btn-info"
                                    }`}
                                    onClick={() => {
                                      dispatch(updateCouponStatus(item.id));
                                    }}
                                    disabled={loading}
                                  >
                                    <i
                                      className={`fa ${
                                        item.status === "active"
                                          ? "fa-toggle-off"
                                          : "fa-toggle-on"
                                      }`}
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </Tooltip>
                              </div>

                              {item?.status === "active" && (
                                <div>
                                  <Tooltip title="Show in bid list">
                                    <input
                                      className="form-check-input cursor-pointer"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      value={item?.visibleToUserApp}
                                      onClick={() =>
                                        dispatch(visibleCouponToUser(item?.id))
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    {loading && (
                      <Tr>
                        <Td>
                          <Spinner
                            style={{
                              position: "fixed",
                              left: "50%",
                              top: "50%",
                            }}
                            animation="border"
                            color="warning"
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {!loading && coupons.length < 1 && (
                  <div className="text-center">
                    <h3>No Data</h3>
                  </div>
                )}
              </CardBody>
            </Card>
            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(getAllCoupons(true, page))}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

export default CouponList;

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Main")} </li> */}
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home" />
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-image" />
                <span>{props.t("Banner")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/banner">{props.t("Banner List")} </Link>
                </li>
                <li>
                  <Link to="/banner-add">{props.t("Banner Add")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-gallery" />
                <span>{props.t("Gallery")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/image-gallery">{props.t("Image Gallery")} </Link>
                </li>
                {/* <li>
                  <Link to="/image-folder">{props.t("Image Folder")}</Link>
                </li> */}

                <li>
                  <Link to="/image-upload">{props.t("Image Upload")} </Link>
                </li>
              </ul>
            </li>

            {/* USERS MENU */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-user" />
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/users/list">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/users/add">{props.t("Add")} </Link>
                </li>
              </ul>
            </li>

            {/* PARTNER MENU */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-user" />
                <span>{props.t("Partners")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/partner/list">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/partner/add">{props.t("Add")}</Link>
                </li>
              </ul>
            </li>

            {/* DRIVER MENU */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-user" />
                <span>{props.t("Driver")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/drivers">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/driver/add">{props.t("Add")}</Link>
                </li>
              </ul>
            </li>

            {/* CAR TYPES */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-car" />
                <span>{props.t("Car")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li />
                <li>
                  <Link to="/car/list">{props.t("List")} </Link>
                </li>
                <li>
                  <Link to="/car/Add">{props.t("Add")} </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    {props.t("Car Types")}
                  </Link>

                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/car-types">{props.t("List")}</Link>
                    </li>
                    <li>
                      <Link to="/add-car-type">{props.t("Add")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/color">{props.t("Color")} </Link>
                </li>
                <li>
                  <Link to="/year">{props.t("Year")} </Link>
                </li>
              </ul>
            </li>

            {/* RIDE  */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-car" />
                <span>{props.t("Ride")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/ride/list">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/ride/add">{props.t("Add")}</Link>
                </li>
              </ul>
            </li>

            {/*  POLICY */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-lock" />
                <span>{props.t("Policy")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li />
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user" />
                    {props.t("Partner")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/partner/about-us">{props.t("About Us")}</Link>
                    </li>
                    <li>
                      <Link to="/partner/privacy-policy">
                        {props.t("Privacy Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/partner/payment-policy">
                        {props.t("Payment Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/partner/cancellation-policy">
                        {props.t("Cancellation Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/partner/refund-policy">
                        {props.t("Refund Policy")}
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* User */}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="ti-user" />
                    {props.t("User")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/user/about-us">{props.t("About Us")}</Link>
                    </li>
                    <li>
                      <Link to="/user/privacy-policy">
                        {props.t("Privacy Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/payment-policy">
                        {props.t("Payment Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/cancellation-policy">
                        {props.t("Cancellation Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/refund-policy">
                        {props.t("Refund Policy")}
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Contact Us */}
                <li>
                  <Link to="/contact-us">
                    <i className="fa fa-phone" />
                    <span>{props.t("Contact Us")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* COUPON */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-gift" />
                <span>{props.t("Coupon")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/coupon/list">
                    <i className="fas fa-list-alt"></i>
                    <span>{props.t("List")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/coupon/add">
                    <i className="fas fa-plus-circle"></i>
                    <span>{props.t("Add")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* DISCOUNT */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-percentage" />
                <span>{props.t("Discount")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/discount/list">
                    <i className="fas fa-list-alt"></i>
                    <span>{props.t("List")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/discount/add">
                    <i className="fas fa-plus-circle"></i>
                    <span>{props.t("Add")}</span>
                  </Link>
                </li>
              </ul>
            </li> */}

            {/* Admin Controls */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-user" />
                <span>{props.t("Admin Control")}</span>
              </Link>
              <ul className="sub-menu " aria-expanded="false">
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    {props.t("Admins")}
                  </Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <Link to="/admin-list">{props.t("List")}</Link>
                    </li>
                    <li>
                      <Link to="/create-admin">{props.t("Create")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/role">{props.t("Role")}</Link>
                </li>
              </ul>
            </li> */}

            {/* TUTORIALS */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-youtube" />
                <span>{props.t("Tutorials")}</span>
              </Link>
              <ul className="sub-menu " aria-expanded="false">
                <li>
                  <Link to="/tutorials/list">{props.t("List")}</Link>
                </li>
                <li>
                  <Link to="/tutorials/add">{props.t("Add")}</Link>
                </li>
              </ul>
            </li>

            {/* SETTINGS */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-cog" />
                <span>{props.t("Setting")}</span>
              </Link>
              <ul className="sub-menu " aria-expanded="false">
                <li>
                  <Link to="/user-payment-condition">
                    <i className="fas fa-gavel" />
                    {props.t("Payment Conditions")}
                  </Link>
                </li>
                <li>
                  <Link to="/default-messages">
                    <i className="fas fa-comment" />
                    {props.t("Default Messages")}
                  </Link>
                </li>
                <li>
                  <Link to="/cancel-reason" className="waves-effect">
                    <i className="far fa-window-close" />
                    <span>{props.t("Cancel Reasons")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/system-configuration" className="waves-effect">
                    <i className="fas fa-cog" />
                    <span>{props.t("System Configs")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ride-cancel-conditions" className="waves-effect">
                    <i className="ti-car" />
                    <span>{props.t("Cancel Conditions")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* Payments */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-money" />
                <span>{props.t("Accounts")}</span>
              </Link>
              <ul className="sub-menu " aria-expanded="false">
                <li>
                  <Link to="/transactions" className="waves-effect">
                    <i className=" ti-money" />
                    <span>{props.t("Transactions")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/withdraw-requests" className="waves-effect">
                    <i className=" ti-money" />
                    <span>{props.t("Withdraw Request")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* GLOABAL NOTIFICATON */}

            <li>
              <Link to="/global-notification" className="waves-effect">
                <i className=" ti-bell" />
                <span>{props.t("Global Notification")}</span>
              </Link>
            </li>

            {/* NOTICES */}
            <li>
              <Link to="/notices" className="waves-effect">
                <i className="fas fa-newspaper" />
                <span>{props.t("Notices")}</span>
              </Link>
            </li>

            {/* OFFERS */}

            <li>
              <Link to="/offers" className="waves-effect">
                <i className="fas fa-gift" />
                <span>{props.t("Offers")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SingleMonthEarningChart = ({income = 0}) => {
    const options ={
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '45%',
                },
                dataLabels: {
                    value: {
                        show: false
                    }
                }
            },
        },
        colors: ['rgb(2, 164, 153)'],
        labels: ['']
    }
  return (
    <React.Fragment>

      <ReactApexChart
        options={options}
        series={[income]}
        type="radialBar"
        height="140px"
      />
   
    </React.Fragment>
  );
};

export default SingleMonthEarningChart;
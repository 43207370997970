import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Label,
  FormGroup,
  Modal,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllConfigs,
  updateConfig,
} from "../../../store/SystemConfigs/systemConfigsAction";
import EditButton from "../../../components/EditButton";
import Select from "react-select";
import { statusOptions } from "../../../assets/staticData";
import { alertMsg } from "../../../helpers/alertMsg";

const SystemConfigs = () => {
  const dispatch = useDispatch();
  const { configs, loading, status, error } = useSelector(
    (state) => state.systemConfigsReducer
  );

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [config, setConfig] = useState({
    id: null,
    title: "",
    code: "",
    value: "",
    status: null,
  });

  useEffect(() => {
    callConfigsList(true);
  }, []);

  const callConfigsList = (refresh = false) => {
    dispatch(getAllConfigs(refresh));
  };

  //   HANDLE UPDATE CONFIG

  const handleUpdate = (cId) => {
    const { id, title, code, value, status } = configs.find(
      (item) => item.id === cId
    );
    if (id) {
      const findStatus = statusOptions.find((item) => item.value === status);
      setConfig({
        ...config,
        id,
        title,
        code,
        value,
        status: findStatus,
      });

      setIsOpenAddModal(!isOpenAddModal);
    }
  };

  //   INPUT FIELD CHANGE

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConfig({ ...config, [name]: value });
  };

  //   UPDATE CONFIG

  const updateConfigData = (e) => {
    e.preventDefault();
    const { id, title, value, status } = config;
    if (!id) {
      return alertMsg("Invalid Config", "warning");
    }
    if (!title || !value || !status) {
      return alertMsg("Missing Config Data", "warning");
    }

    dispatch(
      updateConfig({
        id,
        title,
        value,
        status: status?.value,
      })
    );
  };

  useEffect(() => {
    if (status) {
      setConfig({
        id: null,
        title: "",
        code: "",
        value: "",
        status: null,
      });
      setIsOpenAddModal(false);
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="System Configuration"
              loading={loading}
              callList={callConfigsList}
            />

            <Row>
              <Col xl={12}>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <CardTitle style={{ flex: "2" }} className="h4">
                            Configurations List
                          </CardTitle>
                        </div>

                        <Table
                          id="tech-companies-1"
                          className="table table-bordered table-hover text-center"
                        >
                          <Thead>
                            <Tr>
                              <Th>Title</Th>
                              <Th>Value</Th>
                              <Th>Status</Th>
                              <Th>Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {configs.length > 0 &&
                              configs.map(
                                ({ title, code, value, status, id }, index) => {
                                  return (
                                    <Tr
                                      key={index}
                                      className="align-middle"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Th>{title}</Th>

                                      <Td>{value}</Td>
                                      <Td
                                        className={`text-capitalize ${
                                          status === "active"
                                            ? "active_color"
                                            : "inactive_color"
                                        }`}
                                      >
                                        {status}
                                      </Td>
                                      <Td>
                                        <div>
                                          <EditButton
                                            editItem={() => handleUpdate(id)}
                                          />
                                        </div>
                                      </Td>
                                    </Tr>
                                  );
                                }
                              )}
                          </Tbody>
                        </Table>
                        {configs.length < 1 && !loading && (
                          <div className="text-center">
                            <h4>No Data Found!</h4>
                          </div>
                        )}
                        {loading && (
                          <div className="text-center">
                            <Spinner animation="border" color="info" />
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/*MODAL FOR ADD AND EDIT CANCEL REASON  */}
        <Modal
          isOpen={isOpenAddModal}
          toggle={() => {
            setIsOpenAddModal(!isOpenAddModal);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{`Update ${config?.title}`}</h5>
            <button
              type="button"
              onClick={() => {
                setIsOpenAddModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={updateConfigData}>
              <div>
                <div className="mb-3">
                  <Label>Title</Label>
                  <Input
                    value={config?.title}
                    onChange={handleInputChange}
                    id="title"
                    className=""
                    name="title"
                    type="text"
                    placeholder="Enter title"
                  />
                </div>

                <div className="mb-3">
                  <Label>Value</Label>
                  <Input
                    value={config?.value}
                    onChange={handleInputChange}
                    id="value"
                    className=""
                    name="value"
                    type="text"
                    placeholder="Enter value"
                  />
                </div>

                <div className="mb-3">
                  <Label>Status</Label>
                  <Select
                    value={config.status}
                    onChange={(event) => {
                      setConfig({ ...config, status: event });
                    }}
                    options={statusOptions}
                    classNamePrefix="select2-selection"
                  />
                </div>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    className="px-5"
                    disabled={loading}
                  >
                    {loading ? "Saving.." : "Update"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .heading {
    color: red;
  }
`;

export default SystemConfigs;

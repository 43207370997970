import * as actionType from "../actionType";

const initialState = {
  loading: false,
  error: null,
  dashboard: null,
  status: false,
};
const dashboardReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case actionType.GET_ALL_DASHBOARD_DATA_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case actionType.GET_ALL_DASHBOARD_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: payload,
        status: true,
      };

    case actionType.GET_ALL_DASHBOARD_DATA_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;

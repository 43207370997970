import { TextField } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import {
  PARTNER_BALANCE_WITHDROW,
  USER_BALANCE_WITHDROW,
} from "../../network/Api";
import requestApi from "../../network/httpRequest";

const WithdrawBalance = ({ id, type, lisener }) => {
  const [amount, setAmount] = useState(0);
  const [adminNote, setAdminNote] = useState("");
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const banalceWithdrow = () => {
    if (!amount) {
      return toast.warn("Enter balance ", {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (!note) {
      return toast.warn(`Enter ${type} note`, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    submitData();
  };

  //  SUBMIT DATA

  const submitData = async () => {
    setIsLoading(true);
    try {
      let response = null;
      if (type) {
        if (type == "user") {
          const { data } = await requestApi().request(USER_BALANCE_WITHDROW, {
            method: "POST",
            data: {
              userId: id,
              amount,
              adminNote,
              userNote: note,
            },
          });
          response = data;
        } else {
          const { data } = await requestApi().request(
            PARTNER_BALANCE_WITHDROW,
            {
              method: "POST",
              data: {
                partnerId: id,
                amount,
                adminNote,
                partnerNote: note,
              },
            }
          );
          response = data;
        }
      }

      if (response) {
        setIsLoading(false);
        if (response.status) {
          toast.success(response.message, {
            // position: "bottom-right",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          lisener(response.data.accounts);
        } else {
          toast.warn(response.error, {
            // position: "bottom-right",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      console.log(error.messaage);
    }
  };

  return (
    <React.Fragment>
      <div>
        <TextField
          className="w-100"
          id="outlined-basic"
          label="Amount"
          variant="outlined"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
        <TextField
          id="filled-multiline-flexible"
          label="Admin Note"
          multiline
          maxRows={4}
          value={adminNote}
          onChange={(e) => setAdminNote(e.target.value)}
          variant="outlined"
          className="w-100 my-3"
        />

        <TextField
          id="filled-multiline-flexible"
          label={`${type} note`}
          multiline
          maxRows={4}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          variant="outlined"
          className="w-100"
        />
        <div className="mt-3 d-flex justify-content-center ">
          <Button
            outline={true}
            color="success"
            className="px-5"
            disabled={isLoading}
            onClick={banalceWithdrow}
          >
            {isLoading ? "Loading...." : "Withdrow"}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WithdrawBalance;

import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { getBiddingDetails } from "../../../store/Ride/rideAction";
import requestApi from "../../../network/httpRequest";
import { GET_SINGLE_BIDDING } from "../../../network/Api";
import Lightbox from "react-image-lightbox";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

const BiddingDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [expanded, setExpanded] = useState(true);
  const [bidding, setBidding] = useState(null);
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [acceptedAmountInfo, setAcceptedAmountInfo] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  useEffect(() => {
    if (id) {
      callApi(id);
      setUser(location.state.user);
    } else {
      history.push("/ride/list", { replace: true });
    }
  }, [id]);

  const callApi = async (bidId) => {
    try {
      const { data } = await requestApi().request(GET_SINGLE_BIDDING, {
        params: { id: bidId },
      });
      // console.log("single driver", data);
      if (data.status) {
        const bidding = data?.data?.bidding;
        const biddingAmounts = bidding?.bidding_amounts;
        console.log(biddingAmounts);
        setBidding(bidding);
        if (biddingAmounts?.length > 0) {
          biddingAmounts.map(
            (item) => item?.acceptedPrice && setAcceptedAmountInfo(item)
          );
        }
      } else {
        console.log(data.error);
      }
    } catch (error) {
      console.log("error");
    }
  };

  // DETAILS CARD

  const DetailsCard = (title, img, details, ...rest) => {
    return (
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <CardTitle className="h4">{title}</CardTitle>
            <Button
              outline={true}
              color="success"
              onClick={() => history.push(details)}
            >
              Details
            </Button>
          </div>
          <hr />
          <Row>
            <Col sm={6}>
              <ImageWrapper>
                {img ? (
                  <img
                    className="rounded-circle avatar-lg cursor-pointer"
                    alt="partner"
                    src={img}
                    loading="lazy"
                    onClick={() => {
                      setSelectedImg(img);
                      setIsOpen(true);
                    }}
                  />
                ) : (
                  <ShimmerCircularImage size={150} />
                )}
              </ImageWrapper>
            </Col>
            <Col sm={6} className="mt-3">
              {rest.map((item, index) => (
                <div key={index}>
                  <h5>
                    {item.title}: {item.value}
                  </h5>
                </div>
              ))}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Bidding"
              breadcrumbItem="Details"
              isRefresh={false}
            />

            {isOpen && (
              <Lightbox
                mainSrc={selectedImg}
                enableZoom={true}
                imageCaption="img"
                onCloseRequest={() => {
                  setIsOpen(!isOpen);
                }}
              />
            )}

            <Row className="pb-3">
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <CardTitle>Bidding conversation</CardTitle>
                      <span>
                        Status:
                        <span
                          style={{
                            color:
                              bidding?.status == "accepted"
                                ? "green"
                                : bidding?.status == "canceled"
                                ? "red"
                                : bidding?.status == "waiting"
                                ? "purple"
                                : "black",
                            fontStyle: "italic",
                          }}
                        >
                          {bidding?.status}
                        </span>
                      </span>
                    </div>
                    <hr />
                    <div>
                      <Accordion
                        expanded={expanded}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<i className="fa fa-angle-down"></i>}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Show Details
                          </Typography>
                          <Typography
                            sx={{ color: "text.secondary" }}
                          ></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <SimpleBar style={{ height: "300px" }}>
                              <ul
                                className="conversation-list"
                                data-simplebar
                                style={{
                                  maxHeight: "300px",
                                  width: "100%",
                                }}
                              >
                                {bidding?.bidding_amounts.map(
                                  (amount, index, arr) => (
                                    <div key={index}>
                                      {amount.offerSendBy == "partner" && (
                                        <li className="clearfix">
                                          <div className="chat-avatar">
                                            <img
                                              src={bidding.partner.img}
                                              className="avatar-xs rounded-circle"
                                              alt="Partner"
                                            />
                                          </div>
                                          <div className="conversation-text">
                                            <div className="ctext-wrap">
                                              <span className="user-name">
                                                {bidding.partner.name}
                                              </span>
                                              <strong>
                                                Offered: {amount.biddingAmount}
                                                TK.
                                              </strong>
                                              {arr[arr.length - 1]
                                                ?.biddingAmount ==
                                                arr[arr.length - 2]
                                                  ?.biddingAmount &&
                                                arr.length - 2 == index && (
                                                  <div>
                                                    <span
                                                      style={{
                                                        color: "green",
                                                      }}
                                                    >
                                                      Accepted
                                                    </span>
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        </li>
                                      )}

                                      {amount.offerSendBy == "user" && (
                                        <li className="clearfix odd">
                                          <div className="chat-avatar">
                                            <img
                                              src={user?.img}
                                              className="avatar-xs rounded-circle"
                                              alt="User"
                                            />
                                          </div>
                                          <div className="conversation-text">
                                            <div className="ctext-wrap">
                                              <span className="user-name">
                                                {user?.name}
                                              </span>
                                              <strong>
                                                Offered: {amount.biddingAmount}
                                                Tk.
                                              </strong>
                                              {amount.acceptedPrice && (
                                                <div>
                                                  <span
                                                    style={{ color: "green" }}
                                                  >
                                                    Accepted
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      )}
                                    </div>
                                  )
                                )}
                              </ul>
                            </SimpleBar>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle>Bidding Address</CardTitle>
                    <hr />
                    <div>
                      <DetailsWrapper>
                        <span className="me-2 title">Address:</span>
                        <span className="value">
                          {bidding?.bidding_address?.address}.
                        </span>
                      </DetailsWrapper>
                      <DetailsWrapper>
                        <span className="me-2 title">Sub Locality:</span>
                        <span className="value">
                          {bidding?.bidding_address?.subLocality
                            ? bidding?.bidding_address?.subLocality
                            : "N/A"}
                          .
                        </span>
                      </DetailsWrapper>
                      <DetailsWrapper>
                        <span className="me-2 title">Locality :</span>
                        <span className="value">
                          {bidding?.bidding_address?.locality}.
                        </span>
                      </DetailsWrapper>
                      <DetailsWrapper>
                        <span className="me-2 title">Country :</span>
                        <span className="value">
                          {bidding?.bidding_address?.country}.
                        </span>
                      </DetailsWrapper>
                      <DetailsWrapper>
                        <span className="me-2 title">Country Code:</span>
                        <span className="value">
                          {bidding?.bidding_address?.countryCode}.
                        </span>
                      </DetailsWrapper>
                    </div>
                  </CardBody>
                </Card>

                {acceptedAmountInfo && (
                  <Card>
                    <CardBody>
                      <CardTitle>Amount Details</CardTitle>
                      <hr />
                      <div>
                        <DetailsWrapper contentBetween="space-between">
                          <span className="me-2 title">Rent Amount:</span>
                          <span className="value">
                            {acceptedAmountInfo?.biddingAmount} TK.
                          </span>
                        </DetailsWrapper>
                        {acceptedAmountInfo?.userAdvancePayment > 0 && (
                          <DetailsWrapper contentBetween="space-between">
                            <span className="me-2 title">Advance Amount:</span>
                            <span className="value">
                              {acceptedAmountInfo?.userAdvancePayment ?? 0} TK.
                            </span>
                          </DetailsWrapper>
                        )}

                        {acceptedAmountInfo?.bankCharge > 0 && (
                          <DetailsWrapper contentBetween="space-between">
                            <span className="me-2 title">
                              Bank Charge Amount:
                            </span>
                            <span className="value">
                              {acceptedAmountInfo?.bankCharge ?? 0} TK.
                            </span>
                          </DetailsWrapper>
                        )}

                        {["accepted", "completed"].includes(
                          bidding?.status
                        ) && (
                          <>
                            <DetailsWrapper contentBetween="space-between">
                              <span className="me-2 title">
                                Partner Amount:
                              </span>
                              <span className="value">
                                {acceptedAmountInfo?.partnerGet} TK .
                              </span>
                            </DetailsWrapper>
                            <DetailsWrapper>
                              <span className="me-2 title">
                                Partner Cash Receive :
                              </span>
                              <span className="value">
                                {acceptedAmountInfo?.partnerCashReceived} TK .
                              </span>
                            </DetailsWrapper>
                            <DetailsWrapper>
                              <span className="me-2 title">
                                Quicar Amount :
                              </span>
                              <span className="value">
                                {acceptedAmountInfo?.quicarPercentageAmount}.
                              </span>
                            </DetailsWrapper>
                          </>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                {DetailsCard(
                  "User",
                  user?.img,
                  `/user/details/${user?.id}`,
                  { title: "Name", value: user?.name },
                  { title: "Phone", value: user?.phoneNumber }
                )}
              </Col>
              <Col xl={6}>
                {DetailsCard(
                  "Partner",
                  bidding?.partner?.img,
                  `/partner/details/${bidding?.partner?.id}`,
                  { title: "Name", value: bidding?.partner?.name },
                  { title: "Phone", value: bidding?.partner?.phone }
                )}
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                {DetailsCard(
                  "Driver",
                  bidding?.driver?.img,
                  `/driver/details/${bidding?.driver?.id}`,
                  { title: "Name", value: bidding?.driver?.name },
                  { title: "Phone", value: bidding?.driver?.phone },
                  { title: "Address", value: bidding?.driver?.address },
                  { title: "NID", value: bidding?.driver?.nid },
                  { title: "status", value: bidding?.driver?.status }
                )}
              </Col>
              <Col xl={6}>
                {DetailsCard(
                  "Car",
                  bidding?.car?.carFrontImage,
                  `/car/details/${bidding?.car?.id}`,
                  { title: "Car Type", value: bidding?.car?.car_type?.name },
                  { title: "Brand", value: bidding?.car?.car_brand.name },
                  { title: "Model", value: bidding?.car?.car_model.name },
                  { title: "Color", value: bidding?.car?.color.name },
                  { title: "Year", value: bidding?.car?.year.year }
                )}
              </Col>
            </Row>

            {/* BIDDING Details LISTS */}
            <Row>
              <Card>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Send Offer</Th>
                        <Th>Bidding Amount</Th>
                        <Th>Quicar Amount</Th>
                        <Th>Pertner Amount</Th>
                        <Th>Accept Bid</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {bidding && bidding?.bidding_amounts?.length > 0
                        ? bidding?.bidding_amounts?.map((info) => (
                            <Tr
                              key={info.id}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th>
                                <p className="text-capitalize">
                                  {info?.offerSendBy}
                                </p>
                              </Th>

                              <Td>
                                <p>{info?.biddingAmount}</p>
                              </Td>
                              <Td>
                                <p>{info?.quicarPercentageAmount}</p>
                              </Td>
                              <Td>
                                <p>{info?.partnerGet}</p>
                              </Td>
                              <Td>{info?.acceptedPrice ? "Yes" : "No"}</Td>
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                  {bidding?.bidding_amounts?.length === 0 && (
                    <div className="text-center w-100">
                      <h5>No Data !</h5>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.contentBetween};
  margin: 5px 0px;
  .title {
    font-size: 15px;
    color: #424242;
    font-weight: 450;
  }
  .value {
    font-size: 15px;
    color: #0e2c24;
    font-weight: 500;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-right: 1px solid lightgray;
  height: 100%;
  align-items: center;
  @media (max-width: 580px) {
    border-right: none;
  }
`;

export default BiddingDetails;

import { toast } from "react-toastify";
import { alertMsg } from "../../helpers/alertMsg";
import {
  ADD_CANCEL_REASON,
  DELETE_CANCEL_REASON,
  GET_ALL_CANCEL_REASON,
  UPDATE_CANCEL_REASON,
} from "../../network/Api";
import * as actionType from "../actionType";
import requestApi from "./../../network/httpRequest";

// ADD

export const addCancelReason = (reasonData) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_CANCEL_REASON_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_CANCEL_REASON, {
      method: "POST",
      data: reasonData,
    });

    if (data.status) {
      alertMsg(data.message, "success");

      dispatch({
        type: actionType.ADD_CANCEL_REASON_REQUEST_SUCCESS,
        payload: data.data.cancelReason,
      });
    } else {
      alertMsg(data.error, "warning");

      dispatch({
        type: actionType.ADD_CANCEL_REASON_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_CANCEL_REASON_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL

export const getAllCancelReasons =
  (refresh = false, reasonType = null) =>
  async (dispatch, getState) => {
    const { cancelReasons, typeKey } = getState().cancelReasonReducer;

    try {
      if (cancelReasons.length < 1 || refresh) {
        // console.log("call api---")
        dispatch({
          type: actionType.GET_ALL_CANCEL_REASON_REQUEST_SEND,
        });

        const { data } = await requestApi().request(GET_ALL_CANCEL_REASON, {
          params: {
            type: reasonType ? reasonType : typeKey.value,
          },
        });

        // console.log("all reasons reasons", data);

        if (data.status) {
          dispatch({
            type: actionType.GET_ALL_CANCEL_REASON_REQUEST_SUCCESS,
            payload: data.data.cancelReasons,
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_CANCEL_REASON_REQUEST_FAIL,
            paylaod: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_CANCEL_REASON_REQUEST_FAIL,
        paylaod: error.message,
      });
    }
  };

// EDIT

export const editCancelReason = (updateData) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.EDIT_CANCEL_REASON_REQUEST_SEND,
    });

    const { data } = await requestApi().request(UPDATE_CANCEL_REASON, {
      method: "POST",
      data: updateData,
    });

    // console.log("edited data", data);

    if (data.status) {
      alertMsg(data.message, "success");

      dispatch({
        type: actionType.EDIT_CANCEL_REASON_REQUEST_SUCCESS,
        payload: data.data.cancelReason,
      });
    } else {
      alertMsg(data.error, "warning");

      dispatch({
        type: actionType.EDIT_CANCEL_REASON_REQUEST_FAIL,
        paylaod: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.EDIT_CANCEL_REASON_REQUEST_FAIL,
      paylaod: error.message,
    });
  }
};

// DELETE

export const deleteCancelReason = (rId) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.DELETE_CANCEL_REASON_REQUEST_SEND,
    });

    const { data } = await requestApi().request(DELETE_CANCEL_REASON, {
      method: "POST",
      data: {
        id: rId,
      },
    });

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: actionType.DELETE_CANCEL_REASON_REQUEST_SUCCESS,
        payload: data.data.cancelReason,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: actionType.DELETE_CANCEL_REASON_REQUEST_FAIL,
        paylaod: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.DELETE_CANCEL_REASON_REQUEST_FAIL,
      paylaod: error.message,
    });
  }
};

// UPDATE TYPE KEY

export const updateCancelReasonTypeKey = (type) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TUTORIAL_TYPE_KEY,
    payload: type,
  });
};

import { alertMsg } from "../../helpers/alertMsg";
import {
  ADD_DEFAULT_MESSAGE,
  ALL_DEFAULT_MESSAGE,
  DELETE_DEFAULT_MESSAGE,
  EDIT_DEFAULT_MESSAGE,
} from "../../network/Api";
import requestApi from "../../network/httpRequest";
import {
  ADD_DEFAULT_MESSAGE_REQUEST_FAIL,
  ADD_DEFAULT_MESSAGE_REQUEST_SEND,
  ADD_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  DELETE_DEFAULT_MESSAGE_REQUEST_FAIL,
  DELETE_DEFAULT_MESSAGE_REQUEST_SEND,
  DELETE_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL,
  GET_ALL_DEFAULT_MESSAGE_REQUEST_SEND,
  GET_ALL_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL,
  UPDATE_DEFAULT_MESSAGE_REQUEST_SEND,
  UPDATE_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  UPDATE_DEFAULT_MESSAGE_SEARCH_KEY,
  UPDATE_DEFAULT_MESSAGE_STATUS_KEY,
} from "../actionType";

// ADD
export const addDefaultMessage =
  ({ title, content }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADD_DEFAULT_MESSAGE_REQUEST_SEND,
      });

      const { data } = await requestApi().request(ADD_DEFAULT_MESSAGE, {
        method: "POST",
        data: {
          title,
          content,
        },
      });

      if (data.status) {
        alertMsg(data.message, "success");
        dispatch({
          type: ADD_DEFAULT_MESSAGE_REQUEST_SUCCESS,
          payload: data.data,
        });
      } else {
        alertMsg(data.error, "warning");
        dispatch({
          type: ADD_DEFAULT_MESSAGE_REQUEST_FAIL,
          payload: data.error,
        });
      }
    } catch (error) {
      dispatch({
        type: ADD_DEFAULT_MESSAGE_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// GET ALL

export const getAllDefaultMessages =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    const { messages, statusKey, searchKey } = getState().defaultMessageReducer;

    try {
      if (messages.length < 1 || refresh) {
        dispatch({
          type: GET_ALL_DEFAULT_MESSAGE_REQUEST_SEND,
        });

        const { data } = await requestApi().request(ALL_DEFAULT_MESSAGE, {
          params: {
            status: statusKey.value,
            searchKey,
            page,
            pageSize: 100,
          },
        });

        if (data.status) {
          dispatch({
            type: GET_ALL_DEFAULT_MESSAGE_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL,
            paylaod: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL,
        paylaod: error.message,
      });
    }
  };

//   EDIT MESSAGE

export const editDefaultMessage = (values) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DEFAULT_MESSAGE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(EDIT_DEFAULT_MESSAGE, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: UPDATE_DEFAULT_MESSAGE_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// DELETE

export const deleteDefaultMessage = (mId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_DEFAULT_MESSAGE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(DELETE_DEFAULT_MESSAGE, {
      method: "POST",
      data: {
        id: mId,
      },
    });

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: DELETE_DEFAULT_MESSAGE_REQUEST_SUCCESS,
        payload: data.data.messaage,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: DELETE_DEFAULT_MESSAGE_REQUEST_FAIL,
        paylaod: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_DEFAULT_MESSAGE_REQUEST_FAIL,
      paylaod: error.message,
    });
  }
};

// SEARCH KEY

export const updateDefaultMessageSearchKey = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_DEFAULT_MESSAGE_SEARCH_KEY,
    payload: value,
  });
};

// UPDATE STATUS KEY

export const updateDefaultMessageStatusKey = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_DEFAULT_MESSAGE_STATUS_KEY,
    payload: value,
  });
};

import * as actionType from "../actionType";
import moment from "moment";

const initialState = {
  loading: false,
  error: null,
  accounts: [],
  status: false,
  sortBy: { label: "ASC", value: "ASC" },
  transType: { label: "All", value: "all" },
  startDate: moment().format("YYYY-MM-DD"),
  endDate: moment().add(1, "M").format("YYYY-MM-DD"),
  paginate: null,
  paging: [],
  hasNextPage: true,
  currentPage: 1,
  hasPreviousPage: false,
  withdrawUserType: { label: "All", value: "all" },
  withdrawStartDate: moment().format("YYYY-MM-DD"),
  withdrawEndDate: moment().add(1, "M").format("YYYY-MM-DD"),
  withdrawRequests: [],
  message: null,
};

const transactionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionType.GET_ALL_TRANSCATIONS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        errro: null,
      };

    case actionType.GET_ALL_TRANSCATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        accounts: payload.accounts,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
      };

    case actionType.GET_ALL_TRANSCATIONS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        errro: payload,
      };

    case actionType.UPDATE_SORT_BY_KEY:
      return {
        ...state,
        sortBy: payload,
      };

    case actionType.UPDATE_TRANS_START_DATE:
      return {
        ...state,
        startDate: payload,
      };

    case actionType.UPDATE_TRANS_END_DATE:
      return {
        ...state,
        endDate: payload,
      };

    case actionType.UPDATE_TYPE_KEY:
      return {
        ...state,
        transType: payload,
      };

    case actionType.UPDATE_WITHDRAW_START_DATE:
      return {
        ...state,
        withdrawStartDate: payload,
      };

    case actionType.UPDATE_WITHDRAW_END_DATE:
      return {
        ...state,
        withdrawEndDate: payload,
      };

    case actionType.UPDATE_WITHDRAW_TYPE_KEY:
      return {
        ...state,
        withdrawUserType: payload,
      };

    case actionType.GET_ALL_WITHDRAW_REQUEST_LIST_SEND:
      return {
        ...state,
        loading: true,
        errro: null,
      };

    case actionType.GET_ALL_WITHDRAW_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        withdrawRequests: payload.accounts,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
      };

    case actionType.GET_ALL_WITHDRAW_REQUEST_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errro: payload,
      };

    case actionType.ACCEPT_WITHDRAW_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        errro: null,
        message: null,
      };

    case actionType.ACCEPT_WITHDRAW_REQUEST_SUCCESS:
      const data = state?.withdrawRequests.filter(
        (req) => req.id != payload.id
      );

      return {
        ...state,
        loading: false,
        status: true,
        withdrawRequests: data,
        message: payload.message,
      };
    case actionType.ACCEPT_WITHDRAW_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        status: false,
        errro: payload,
      };
    default:
      return state;
  }
};

export default transactionsReducer;

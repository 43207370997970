import React from "react";
import ChartistGraph from "react-chartist";

const MonthlyEarningChart = ({ monthlyEarnings = [] }) => {
  const labelsData = monthlyEarnings?.map((item) => item.monthNumber);
  const seriesData = monthlyEarnings?.map((item) => item.income);

  let lineChartData = {
    labels: labelsData,
    series: [seriesData],
  };

  let lineChartOptions = {
    low: 0,
    showArea: true,
  };

  return (
    <React.Fragment>
      <ChartistGraph
        data={lineChartData}
        options={lineChartOptions}
        style={{ height: "300px" }}
        type={"Line"}
      />
    </React.Fragment>
  );
};

export default MonthlyEarningChart;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import styled from "styled-components";
import Breadcrumb from "../../components/Common/Breadcrumb";
import GlobalWrapper from "../../components/GlobalWrapper";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import SelectOptionsTwo from "../../components/SelectOptionsTwo";
import { alertMsg } from "../../helpers/alertMsg";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotice,
  deleteNotice,
  editNotice,
  getAllNotice,
  updateNoticeTypeKey,
} from "../../store/Notice/noticeAction";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import AppPagination from "../../components/AppPagination";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";

const Notices = () => {
  const dispatch = useDispatch();

  const {
    status,
    notices,
    loading,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    statusKey,
  } = useSelector((state) => state.noticeReducer);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [notice, setNotice] = useState({
    title: "",
    description: "",
    target: "",
    order: 0,
    status: "",
  });
  const [id, setId] = useState(null);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false);

  useEffect(() => {
    if (statusKey) {
      callNoticeList(true);
    }
  }, [statusKey]);

  // call list
  const callNoticeList = (refresh = false, page = 1) => {
    dispatch(getAllNotice(refresh, page));
  };

  // CLICK ADD BUTTON
  const addNewNotice = () => {
    setNotice({
      title: "",
      description: "",
      target: "",
      order: 0,
      status: "",
    });
    setOpenAddModal(!openAddModal);
  };

  // HANDLE CHANGE INPUT

  const handleNoticeInput = (e) => {
    const { name, value } = e.target;

    setNotice((prev) => ({ ...prev, [name]: value }));
  };

  // CLICK EDIT BUTTON

  const handleEdit = (id) => {
    if (!id) {
      return alertMsg("Invalid Notice Id!", "warning");
    }

    setId(id);
    const { status, title, description, order, target } = notices.find(
      (item) => item.id == id
    );

    setNotice({
      title,
      target,
      description,
      order,
      status,
    });
    setOpenAddModal(!openAddModal);
  };

  // CLICK DELTE BUTTON

  const handleDelete = (id) => {
    if (!id) {
      return alertMsg("Invalid Id!", "warning");
    }

    setId(id);
    setIsOpenDeleteConfirmModal(!isOpenDeleteConfirmModal);
  };

  // SUBMIT DATA

  const submitNotice = (e) => {
    e.preventDefault();

    const { title, description, target, order, status } = notice;
    if (!title || !description || !target || !order) {
      return alertMsg("Invalid Request Data!", "warning");
    }

    if (id && status == null) {
      return alertMsg("Invalid Status!", "warning");
    }
    console.log("udpate data", notice);
    if (id) {
      dispatch(
        editNotice(
          {
            ...notice,
            status: status ? 1 : 0,
          },
          id
        )
      );
    } else {
      dispatch(
        addNotice({
          title,
          description,
          target,
          order,
        })
      );
    }
  };

  // STATUS

  useEffect(() => {
    if (status) {
      setOpenAddModal(false);
      setId(null);
      setNotice({
        title: "",
        description: "",
        target: "",
        order: 0,
        status: "",
      });
      setIsOpenDeleteConfirmModal(false);
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Notices"
              isAddNew={true}
              isModalOpen={addNewNotice}
              callList={callNoticeList}
              loading={loading}
            />

            <Card>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <SelectOptionsTwo
                      value={statusKey}
                      label="Status"
                      onChange={(e) =>
                        dispatch(updateNoticeTypeKey(e.target.value))
                      }
                      name="statusKey"
                      options={[
                        { label: "All", value: "all" },
                        { label: "Active", value: 1 },
                        { label: "Inactive", value: 0 },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Notice List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>SL. No.</Th>
                      <Th>Title</Th>
                      <Th>For</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {notices?.length > 0 &&
                      notices?.map(({ id, title, target, status }, index) => (
                        <Tr
                          key={id}
                          className="align-middle"
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                            textAlign: "center",
                          }}
                        >
                          <Td>{index + 1}</Td>
                          <Th>{title}</Th>
                          <Td className="text-capitalize">{target}</Td>
                          <Td
                            className={`text-capitalize ${
                              status == "1" ? "active_color" : "inactive_color"
                            }`}
                          >
                            {status == "1" ? "Active" : "Inactive"}
                          </Td>
                          <Td>
                            <div>
                              <EditButton editItem={() => handleEdit(id)} />
                              <span className="mx-2"></span>
                              <DeleteButton
                                deleteItem={() => handleDelete(id)}
                              />
                            </div>
                          </Td>
                        </Tr>
                      ))}
                    {loading && (
                      <Tr>
                        <Td>
                          <Spinner
                            style={{
                              position: "fixed",
                              left: "50%",
                              top: "50%",
                            }}
                            animation="border"
                            color="success"
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {notices?.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found</h3>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => callNoticeList(true, page)}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>

      {/* FOR ADD AND EDIT NOTICE */}
      <Modal
        isOpen={openAddModal}
        toggle={() => {
          setOpenAddModal(!openAddModal);
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{`${
            id ? "Edit" : "Add"
          } Notice`}</h5>
          <button
            type="button"
            onClick={() => {
              setOpenAddModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="mb-4" onSubmit={submitNotice}>
            <div className="cancel_ride_wrapper">
              <div className="mb-3">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Notice For
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="target"
                    onChange={handleNoticeInput}
                    value={notice?.target}
                    required
                  >
                    <FormControlLabel
                      value="userApp"
                      control={<Radio />}
                      label="User App"
                    />
                    <FormControlLabel
                      value="userWeb"
                      control={<Radio />}
                      label="User Web"
                    />
                    <FormControlLabel
                      value="partnerApp"
                      control={<Radio />}
                      label="Partner App"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="mb-3">
                <TextField
                  id="outlined-required"
                  name="title"
                  label="Title"
                  className="w-100"
                  placeholder="Enter Title"
                  value={notice.title}
                  onChange={handleNoticeInput}
                  required
                />
              </div>
              <div className="mb-3">
                <TextField
                  label="Description"
                  required
                  name="description"
                  placeholder="Enter Description"
                  fullWidth
                  type="text"
                  id="description"
                  rows={3}
                  multiline
                  value={notice.description}
                  onChange={handleNoticeInput}
                />
              </div>

              <div className="mb-3">
                <TextField
                  label="Order By"
                  required
                  name="order"
                  placeholder="Enter Description"
                  fullWidth
                  type="number"
                  id="order"
                  value={notice.order}
                  onChange={handleNoticeInput}
                />
              </div>

              {id && (
                <div className="mb-3">
                  <SelectOptionsTwo
                    value={notice.status}
                    label="Status"
                    onChange={handleNoticeInput}
                    name="status"
                    options={[
                      { label: "Active", value: true },
                      { label: "Inactive", value: false },
                    ]}
                  />
                </div>
              )}

              <Button type="submit" className="btn-success" disabled={loading}>
                {loading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      {/* DELETE CONFIRMATION MODAL */}

      {isOpenDeleteConfirmModal && (
        <ConfirmDialog
          isOpen={isOpenDeleteConfirmModal}
          dispatchFunc={() => dispatch(deleteNotice(id))}
          closeDialog={() => setIsOpenDeleteConfirmModal(false)}
          title="Delete Notice"
          content="Are you sure! You want to delete this Notice?"
        />
      )}
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .heading {
    color: red;
  }
`;

export default Notices;

import requestApi from "./../../network/httpRequest";
import { alertMsg } from "../../helpers/alertMsg";
import {
  ADD_USER_PAYMENT_CONDITION,
  EDIT_USER_PAYMENT_CONDITION,
  GET_ALL_PAYMENT_CONDITIONS,
  RIDE_CANCEL_CONDITION,
} from "../../network/Api";

import {
  ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
  ADD_RIDE_CANCEL_CONDITION_REQUEST_SEND,
  ADD_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
  ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
  ALL_RIDE_CANCEL_CONDITION_REQUEST_SEND,
  ALL_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
  EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
  EDIT_RIDE_CANCEL_CONDITION_REQUEST_SEND,
  EDIT_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
  SET_STATUS_FALSE,
} from "../actionType";

// ADD USER PAYMENT CONDITION

export const addRideCancelCondition = (conditionData) => async (dispatch) => {
  // console.log("send data", conditionData);
  try {
    dispatch({
      type: ADD_RIDE_CANCEL_CONDITION_REQUEST_SEND,
    });

    const { data } = await requestApi().request(RIDE_CANCEL_CONDITION, {
      method: "POST",
      data: conditionData,
    });

    if (data.status) {
      alertMsg(data.message, "success");

      dispatch({
        type: ADD_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      alertMsg(data.message, "warning");
      dispatch({
        type: ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL CONDITIONS

export const getAllRideCancelConditions =
  (refresh) => async (dispatch, getState) => {
    const { conditions } = getState().RideCancelConditionReducer;

    if (conditions.length <= 0 || refresh) {
      try {
        dispatch({
          type: ALL_RIDE_CANCEL_CONDITION_REQUEST_SEND,
        });
        const { data } = await requestApi().request(RIDE_CANCEL_CONDITION);

        // console.log("all conditions", data);

        if (data.status) {
          const { cutSlabs } = data.data;
          dispatch({
            type: ALL_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
            payload: cutSlabs,
          });
        } else {
          dispatch({
            type: ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
            paylod: data.error,
          });
        }
      } catch (error) {
        dispatch({
          type: ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
          paylod: error.message,
        });
      }
    }
  };

// EDIT  CONDITION

export const editRideCancelCondition = (values, id) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_RIDE_CANCEL_CONDITION_REQUEST_SEND,
    });

    const { data } = await requestApi().request(
      RIDE_CANCEL_CONDITION + `/${id}`,
      {
        method: "PUT",
        data: values,
      }
    );
    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: EDIT_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const setConditionStatusFalse = () => (dispatch) => {
  dispatch({
    type: SET_STATUS_FALSE,
  });
};

import React from "react";

const DeleteButton = ({ deleteItem }) => {
  return (
    <>
      <button className="btn btn-danger button" onClick={deleteItem}>
        <i className="fa fa-trash" />
      </button>
    </>
  );
};

export default DeleteButton;

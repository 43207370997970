import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

// Custom Scrollbar
import SimpleBar from "simplebar-react";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import PaymentsIcon from "@mui/icons-material/Payments";
import RememberMeIcon from "@mui/icons-material/RememberMe";

// Charts
// import LineAreaChart from "../AllCharts/apex/Lineareachart";
// import RadialChart from "../AllCharts/apex/Apexdonut";
// import Apexdonut from "../AllCharts/apex/apexdonut1";
// import SparkLine from "../AllCharts/sparkline/sparkline";
// import SparkLine1 from "../AllCharts/sparkline/sparkline1";
// import Salesdonut from "../AllCharts/apex/salesdonut";

import GlobalWrapper from "../../components/GlobalWrapper";

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllDashboardData } from "../../store/Dashboard/dashboardAction";
import MonthlyEarningChart from "../../components/MonthlyEarningChart/MonthlyEarningChart";
import SingleMonthEarningChart from "../../components/SingleMonthEarningChart/SingleMonthEarningChart";
import CompleteMonthEarningChart from "../../components/CompleteMonthEarningChart/CompleteMonthEarningChart";
import { useHistory } from "react-router-dom";

const Dashboard = (props) => {
  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };

  const { loading, dashboard } = useSelector((state) => state.dashboardReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDashboardData());
  }, []);

  // COMPLETE MONTH INCOME

  const completedMonthIncome = () => {
    const amount = dashboard?.completedMonthsIncomeStatistics?.reduce(
      (sum, item) => sum + item.income,
      0
    );
    return amount;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <GlobalWrapper>
          <MetaTags>
            <title>Dashboard | Quicar</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  <h6 className="page-title">Dashboard</h6>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active">
                      Welcome to Quicar Dashboard
                    </li>
                  </ol>
                </Col>
              </Row>
            </div>
            <h4 className="text-warning">Ride</h4>
            <Row>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="On Bidding"
                  value={dashboard?.ride?.totalRideRequestWaiting}
                  Icon={RememberMeIcon}
                  link="/ride/list?status=send"
                />
              </Col>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Upcoming"
                  value={dashboard?.ride?.totalRideRequestUpcoming}
                  Icon={RememberMeIcon}
                  link="/ride/list?status=accepted"
                />
              </Col>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Expried"
                  value={dashboard?.ride?.totalRideRequestUnAccepted}
                  Icon={RememberMeIcon}
                  link="/ride/list?status=unaccepted"
                />
              </Col>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Canceled"
                  value={dashboard?.ride?.totalRideRequestCanceled}
                  Icon={RememberMeIcon}
                  link="/ride/list?status=canceled"
                />
              </Col>
            </Row>

            <Row>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Completed"
                  value={dashboard?.ride?.totalRideRequestCompleted}
                  Icon={RememberMeIcon}
                  link="/ride/list?status=completed"
                />
              </Col>
            </Row>

            <h4 className="text-warning">Driver/Car</h4>
            <Row>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Active Drivers"
                  value={dashboard?.totalDriver}
                  link="/drivers?status=active"
                  Icon={GroupIcon}
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Pending Drivers"
                  value={dashboard?.pendingDrivers}
                  Icon={GroupAddIcon}
                  link="/drivers?status=pending"
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Active Cars"
                  value={dashboard?.totalCar}
                  Icon={AirportShuttleIcon}
                  link="car/list?status=active"
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Pending Cars"
                  value={dashboard?.pendingCars}
                  Icon={AirportShuttleIcon}
                  link="car/list?status=pending"
                />
              </Col>
            </Row>

            <h4 className="text-warning">User/Partner</h4>

            <Row>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Total Users"
                  value={dashboard?.totalUser}
                  Icon={GroupIcon}
                  link="/users/list"
                />
              </Col>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Pending Users"
                  value={dashboard?.pendingUsers}
                  Icon={GroupAddIcon}
                  link="/users/list?status=pending"
                />
              </Col>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Total Partners"
                  value={dashboard?.totalPartner}
                  Icon={GroupIcon}
                  link="/partner/list"
                />
              </Col>
              <Col xl={3} md={6}>
                <SummaryCard
                  title="Pending Partners"
                  value={dashboard?.pendingPartners}
                  Icon={GroupAddIcon}
                  link="/partner/list?status=pending"
                />
              </Col>
            </Row>

            <h4 className="text-warning">Users Payment</h4>
            <Row>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Today"
                  value={
                    dashboard?.quicarIncome
                      ?.totalUserAdvanceQuicarChargePayToday
                  }
                  Icon={PaymentsIcon}
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="This Month"
                  value={
                    dashboard?.quicarIncome
                      ?.totalUserAdvanceQuicarChargePayThisMonth
                  }
                  Icon={PaymentsIcon}
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Last Month"
                  value={
                    dashboard?.quicarIncome
                      ?.totalUserAdvanceQuicarChargePayLastMonth
                  }
                  Icon={PaymentsIcon}
                />
              </Col>
            </Row>
            <h4 className="text-warning">Partners Payment</h4>
            <Row>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Today"
                  value={
                    dashboard?.quicarIncome?.totalPartnerQuicarChargePayToday
                  }
                  Icon={PaymentsIcon}
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="This Month"
                  value={
                    dashboard?.quicarIncome
                      ?.totalPartnerQuicarChargePayThisMonth
                  }
                  Icon={PaymentsIcon}
                />
              </Col>
              <Col xl={4} md={6}>
                <SummaryCard
                  title="Last Month"
                  value={
                    dashboard?.quicarIncome
                      ?.totalPartnerQuicarChargePayLastMonth
                  }
                  Icon={PaymentsIcon}
                />
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Monthly Earning</h4>
                    <Row>
                      <Col lg={8}>
                        <div>
                          <MonthlyEarningChart
                            monthlyEarnings={dashboard?.incomeListMonthly}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Row>
                          <Col md={4}>
                            <div className="text-center">
                              <p className="text-muted mb-4">Today</p>
                              <h5>
                                {dashboard?.quicarIncome?.totalPaymentToday}
                                Tk
                              </h5>
                              <SingleMonthEarningChart
                                income={
                                  dashboard?.quicarIncome?.totalPaymentToday
                                }
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="text-center">
                              <p className="text-muted mb-4">This month</p>
                              <h5>
                                {
                                  dashboard?.quicarIncome
                                    ?.totalAdvanceReceivedThisMonth
                                }
                                Tk.
                              </h5>
                              <SingleMonthEarningChart
                                income={
                                  dashboard?.quicarIncome
                                    ?.totalAdvanceReceivedThisMonth
                                }
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="text-center">
                              <p className="text-muted mb-4">Last month</p>
                              <h5>
                                {
                                  dashboard?.quicarIncome
                                    ?.totalAdvanceReceivedLastMonth
                                }
                                Tk.
                              </h5>
                              <SingleMonthEarningChart
                                income={
                                  dashboard?.quicarIncome
                                    ?.totalAdvanceReceivedLastMonth
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              {/* <Col xl={3}>
                <Card>
                  <CardBody>
                    <div>
                      <h4 className="card-title mb-4">Drivers And Cars</h4>
                    </div>
                    <div className="wid-peity mb-4">
                      <p className="text-muted">Driver</p>
                      <h5 className="mb-4">{dashboard?.totalDriver}</h5>
                    </div>
                    <div className="">
                      <p className="text-muted">Car</p>
                      <h5>{dashboard?.totalCar}</h5>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      Complete Month Income Report
                    </h4>
                    <div className="cleafix">
                      <p className="float-start">
                        <i className="mdi mdi-calendar me-1 text-primary"></i>
                        {`${
                          dashboard?.completedMonthsIncomeStatistics[0]?.month
                        } - ${
                          dashboard?.completedMonthsIncomeStatistics[
                            dashboard?.completedMonthsIncomeStatistics?.length -
                              1
                          ]?.month
                        }`}
                      </p>
                      <h5 className="font-18 text-end">
                        {completedMonthIncome()}TK.
                      </h5>
                    </div>
                    <div id="ct-donut" className="ct-chart wid pt-4">
                      <CompleteMonthEarningChart
                        months={dashboard?.completedMonthsIncomeStatistics}
                      />
                    </div>
                    <div className="mt-4">
                      <table className="table mb-0">
                        <tbody>
                          {dashboard?.completedMonthsIncomeStatistics?.map(
                            (item, index) => (
                              <tr key={index}>
                                <td>
                                  <span className="badge bg-primary">
                                    {item?.monthNumber}
                                  </span>
                                </td>
                                <td>{item?.month}</td>
                                <td className="text-end">{item?.income}Tk.</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Latest Transaction</h4>
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">(#) Id</th>
                            <th scope="col">Name</th>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col" colSpan="2">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboard?.latestTnx.map((item, index) => (
                            <tr key={index}>
                              <th scope="row">{item.id}</th>
                              <td>
                                <div>
                                  <img
                                    src={
                                      item.partner
                                        ? item.partner?.img
                                        : item?.user?.img
                                    }
                                    alt=""
                                    className="avatar-xs rounded-circle me-2"
                                  />
                                  {item.partner
                                    ? item.partner?.name
                                    : item?.user?.name}
                                </div>
                              </td>
                              <td>
                                {new Date(item.createdAt).toLocaleDateString()}
                              </td>
                              <td>{item?.amount}</td>
                              <td>
                                <span className="badge bg-success">
                                  {item?.status}
                                </span>
                              </td>
                              <td>{item.partnerId ? "Partner" : "User"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col xl={4}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Chat</h4>
                    <div className="chat-conversation">
                      <SimpleBar style={{ height: "365px" }}>
                        <ul
                          className="conversation-list"
                          data-simplebar
                          style={{ maxHeight: "367px" }}
                        >
                          <li className="clearfix">
                            <div className="chat-avatar">
                              <img
                                src={user2}
                                className="avatar-xs rounded-circle"
                                alt="male"
                              />
                              <span className="time">10:00</span>
                            </div>
                            <div className="conversation-text">
                              <div className="ctext-wrap">
                                <span className="user-name">John Deo</span>
                                <p>Hello!</p>
                              </div>
                            </div>
                          </li>
                          <li className="clearfix odd">
                            <div className="chat-avatar">
                              <img
                                src={user3}
                                className="avatar-xs rounded-circle"
                                alt="Female"
                              />
                              <span className="time">10:01</span>
                            </div>
                            <div className="conversation-text">
                              <div className="ctext-wrap">
                                <span className="user-name">Smith</span>
                                <p>
                                  Hi, How are you? What about our next meeting?
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="clearfix">
                            <div className="chat-avatar">
                              <img
                                src={user2}
                                className="avatar-xs rounded-circle"
                                alt="male"
                              />
                              <span className="time">10:04</span>
                            </div>
                            <div className="conversation-text">
                              <div className="ctext-wrap">
                                <span className="user-name">John Deo</span>
                                <p>Yeah everything is fine</p>
                              </div>
                            </div>
                          </li>
                          <li className="clearfix odd">
                            <div className="chat-avatar">
                              <img
                                src={user3}
                                className="avatar-xs rounded-circle"
                                alt="male"
                              />
                              <span className="time">10:05</span>
                            </div>
                            <div className="conversation-text">
                              <div className="ctext-wrap">
                                <span className="user-name">Smith</span>
                                <p>Wow that's great</p>
                              </div>
                            </div>
                          </li>
                          <li className="clearfix odd">
                            <div className="chat-avatar">
                              <img
                                src={user3}
                                className="avatar-xs rounded-circle"
                                alt="male"
                              />
                              <span className="time">10:08</span>
                            </div>
                            <div className="conversation-text">
                              <div className="ctext-wrap">
                                <span className="user-name mb-2">Smith</span>

                                <img
                                  src={smimg1}
                                  alt=""
                                  height="48"
                                  className="rounded me-2"
                                />
                                <img
                                  src={smimg2}
                                  alt=""
                                  height="48"
                                  className="rounded"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </SimpleBar>

                      <Row className="mt-3 pt-1">
                        <Col md="9" className="chat-inputbar col-8">
                          <Input
                            type="text"
                            className="chat-input"
                            placeholder="Enter your text"
                          />
                        </Col>
                        <Col md="3" className="chat-send col-4">
                          <div className="d-grid">
                            <Button
                              type="submit"
                              color="success"
                              className="btn-block"
                            >
                              Send
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </GlobalWrapper>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);

const SummaryCard = ({ title, value, link, Icon }) => {
  const history = useHistory();
  return (
    <Card
      className={`mini-stat bg-primary text-white ${link && "cursor-pointer"}`}
      style={{ height: "120px" }}
      title={link && "See Details"}
      onClick={() => history.push(link)}
    >
      <CardBody>
        <div className="float-start mini-stat-img me-4">
          {/* <img src={icon} alt={title} /> */}
          {Icon && <Icon />}
        </div>
        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
          {title}
        </h5>
        <h4 className="fw-medium font-size-24">{value}</h4>
      </CardBody>
    </Card>
  );
};

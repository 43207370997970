import React, { useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import GlobalWrapper from "../../../components/GlobalWrapper";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Lightbox from "react-image-lightbox";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import ImageSelectionDialog from "../../Utility/ImageSelectionDialog";
import { removeAllSelectedGalleryImage } from "../../../store/action/galleryAction";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, TextField } from "@mui/material";
import {
  addDriver,
  addPartner,
  getPartners,
  updateSearchKey,
  editDriver,
} from "../../../store/partner/partnerActions";
import requestApi from "../../../network/httpRequest";
import {
  ALL_PARTNER,
  GET_SINGLE_DRIVER,
  SINGLE_PARTNER,
} from "../../../network/Api";
import { toast } from "react-toastify";
// import { editDriver } from "../../../store/Driver/driverAction";
// import { addDriver, editDriver } from "../../../store/Driver/driverAction";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Select from "react-select";
import { alertMsg } from "../../../helpers/alertMsg";
import Search from "../../../components/Search";

const DriverAdd = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const { partners, searchKey, status, loading } = useSelector(
    (state) => state.partnerReducer
  );

  // const {loading, status, drivers} = useSelector(state => state.driverReducer)

  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [driverImage, setDriverImage] = useState("");
  const [nidFrontImage, setNidFrontImage] = useState("");
  const [nidBackImage, setNidBackImage] = useState("");
  const [licenseFrontImage, setLicenseFrontImage] = useState("");
  const [licenseBackImage, setLicenseBackImage] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [activeStatus, setActiveStatus] = useState(null);
  const [openPartnerSearch, setOpenPartnerSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isZoom, setIsZoom] = useState(false);

  const [driverInfo, setDriverInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    licenseNumber: "",
    nid: "",
  });

  // PARTNER ID
  useEffect(() => {
    const pID = searchParams.get("pID");

    if (pID) {
      const findPartner = partners.find((partner) => partner.id == pID);
      if (findPartner) {
        setPartner(findPartner);
      } else {
        callPartner(pID);
      }
    } else {
      setOpenPartnerSearch(true);
    }
  }, []);

  const setPartner = (partner) => {
    setSelectedPartner(partner);

    if (id) {
      if (partner) {
        const findDriver = partner.drivers.find((driver) => driver.id == id);
        if (findDriver) {
          const {
            name = "",
            email = "",
            phone = "",
            dob = "",
            img,
            address,
            licenseNumber,
            nid,
            nidFontPic,
            nidBackPic,
            licenseFontPic,
            licenseBackPic,
            status,
          } = findDriver;

          setDriverInfo((prev) => ({
            ...prev,
            name,
            email,
            phone,
            address,
            licenseNumber,
            nid,
          }));
          setDateOfBirth(dob.toLocaleString());
          setDriverImage(img);
          setNidFrontImage(nidFontPic);
          setNidBackImage(nidBackPic);
          setLicenseFrontImage(licenseFontPic);
          setLicenseBackImage(licenseBackPic);

          const findStatus = statusOptons.find((item) => item.value == status);
          setActiveStatus(findStatus);
        }
      }
    }
  };

  //  CALL PARTNER

  const callPartner = async (partnerId) => {
    const data = await requestApi().request(SINGLE_PARTNER + partnerId);
    if (data.status) {
      setPartner(data?.data?.data?.partner);
    }
  };

  // INPUT FORM EVENT HANDLE

  const handleChange = (props) => (event) => {
    setDriverInfo({
      ...driverInfo,
      [props]: event.target.value,
    });
  };

  useEffect(() => {
    if (searchKey) {
      callPartnerList(true);
    }
  }, [searchKey]);

  const callPartnerList = (refresh = false) => {
    dispatch(getPartners(refresh));
  };

  const handleImage = (imgId) => {
    const params = new URLSearchParams({ pID: selectedPartner.id });

    history.replace({ pathname: pathname, search: params.toString() });
    setImageId(imgId);
    setmodal_fullscreen(true);
  };

  // SELECT PARTNER

  const selectPartner = (partner) => {
    setOpenPartnerSearch(false);
    setPartner(partner);
    const params = new URLSearchParams({ pID: partner.id });
    history.replace({ pathname: pathname, search: params.toString() });
  };

  // SUCCESS

  useEffect(() => {
    if (status) {
      history.goBack();
    }
  }, [status]);

  const handleSubmit = () => {
    if (
      !driverInfo.name ||
      !driverInfo.nid ||
      !driverInfo.phone ||
      !driverInfo.address ||
      !driverInfo.licenseNumber ||
      !dateOfBirth
    ) {
      return alertMsg("Fill up All Required Field", "warning");
    }

    const phoneNumberPattern = /^(\+)?(88)?01[0-9]{9}$/;
    if (!phoneNumberPattern.test(driverInfo.phone)) {
      return alertMsg("Enter Driver Valid Phone number", "warning");
    }

    if (
      !driverImage ||
      !nidFrontImage ||
      !nidBackImage ||
      !licenseFrontImage ||
      !licenseBackImage
    ) {
      return alertMsg("Please Select Driver Required  Images", "warning");
    }

    if (id) {
      dispatch(
        editDriver({
          id,
          ...driverInfo,
          partnerId: selectedPartner.id,
          img: driverImage,
          dob: dateOfBirth,
          nidFontPic: nidFrontImage,
          nidBackPic: nidBackImage,
          licenseFontPic: licenseFrontImage,
          licenseBackPic: licenseBackImage,
          status: activeStatus.value,
        })
      );
    } else {
      dispatch(
        addDriver({
          ...driverInfo,
          partnerId: selectedPartner.id,
          img: driverImage,
          dob: dateOfBirth,
          nidFontPic: nidFrontImage,
          nidBackPic: nidBackImage,
          licenseFontPic: licenseFrontImage,
          licenseBackPic: licenseBackImage,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Quicer"
              title="Driver"
              breadcrumbItem={id ? "Edit" : "Add"}
              hideSettingBtn={true}
              isRefresh={false}
            />

            {isZoom ? (
              <Lightbox
                mainSrc={selectedPartner.img}
                enableZoom={true}
                imageCaption={selectedPartner.name}
                onCloseRequest={() => {
                  setIsZoom(!isZoom);
                }}
              />
            ) : null}

            {/* Partner Details */}

            <Card>
              <CardBody>
                <div>
                  <CardTitle>Partner</CardTitle>
                </div>
                <hr />
                {selectedPartner ? (
                  <Row>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex justify-content-center"
                      style={{ borderRight: "1px solid lightgray" }}
                    >
                      {selectedPartner.img ? (
                        <div
                          style={{ width: "215px", height: "200px" }}
                          className="d-flex justify-content-center"
                        >
                          <img
                            onClick={() => {
                              setIsZoom(true);
                            }}
                            className="img-fluid cursor-pointer"
                            alt="Partner"
                            src={selectedPartner.img}
                            style={{ height: "100%" }}
                          />
                        </div>
                      ) : (
                        <ShimmerThumbnail height={180} width={200} rounded />
                      )}
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex justify-content-between  align-items-center"
                    >
                      <div className="ps-4">
                        <div>
                          <h5>Partner Name:</h5>
                          <Value>{selectedPartner.name}</Value>
                        </div>
                        <div>
                          <h5>Phone:</h5>
                          <Value>
                            {selectedPartner.phone
                              ? selectedPartner.phone
                              : "N/A"}
                          </Value>
                        </div>
                        <div>
                          <h5>Gmail:</h5>
                          <Value>
                            {selectedPartner.email
                              ? selectedPartner.email
                              : "N/A"}
                          </Value>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="text-center">
                    <h5>Partner Not Found!</h5>
                  </div>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="pt-4">
                  <Col lg={6}>
                    <Row>
                      <Col xl={6} className="mt-4 my-xl-0">
                        <label className="control-label">Name</label>
                        <Input
                          value={driverInfo.name}
                          onChange={handleChange("name")}
                          id="name"
                          className="form-control"
                          type="text"
                          placeholder="Enter Full Name"
                          required
                          disabled={id && !driverInfo.name}
                        />
                      </Col>
                      <Col xl={6} className="mt-4 my-xl-0">
                        <label className="control-label">Email</label>
                        <Input
                          // style={{ border: '1px solid red' }}
                          value={driverInfo.email}
                          onChange={handleChange("email")}
                          id="email"
                          className="form-control"
                          type="email"
                          placeholder="Enter  Email"
                        />
                      </Col>
                    </Row>
                    <Row className="my-xl-4">
                      <Col xl={6} className="mt-4 my-xl-0">
                        <label className="control-label">NID</label>
                        <Input
                          // style={{ border: '1px solid red' }}
                          value={driverInfo.nid}
                          onChange={handleChange("nid")}
                          id="nid"
                          className="form-control"
                          type="number"
                          placeholder="Enter  NID Number"
                        />
                      </Col>
                      <Col xl={6} className="mt-4 my-xl-0">
                        <label className="control-label">Phone</label>
                        <Input
                          // style={{ border: '1px solid red' }}
                          value={driverInfo.phone}
                          onChange={handleChange("phone")}
                          id="phone"
                          className="form-control"
                          type="number"
                          placeholder="Enter  Phone Number"
                          required
                          disabled={id && !driverInfo.phone}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6} className="mt-4 my-xl-0">
                        <label className="control-label">Birth Date</label>
                        <div className="form-group mb-0">
                          <Flatpickr
                            className="form-control d-block"
                            id="dateOfBirth"
                            placeholder="Select Driver Date of Birth"
                            value={dateOfBirth}
                            onChange={(selectedDates, dateStr, instance) =>
                              setDateOfBirth(dateStr)
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xl={6} className="mt-4 mt-xl-0">
                        <label className="control-label">License Number</label>
                        <Input
                          // style={{ border: '1px solid red' }}
                          value={driverInfo.licenseNumber}
                          onChange={handleChange("licenseNumber")}
                          id="licenseNumber"
                          className="form-control"
                          type="text"
                          placeholder="Enter  Driver License Number"
                          required
                          disabled={id && driverInfo?.licenseNumber}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col xl={6}>
                        <label className="control-label">Address</label>
                        <Input
                          // style={{ border: '1px solid red' }}
                          value={driverInfo.address}
                          onChange={handleChange("address")}
                          id="address"
                          className="form-control"
                          type="textarea"
                          placeholder="Enter Address"
                          required
                          disabled={id && !driverInfo?.address}
                        />
                      </Col>
                      {id && (
                        <Col xl={6}>
                          <label className="control-label">Status</label>
                          <Select
                            palceholder="Select Status"
                            options={statusOptons}
                            classNamePrefix="select2-selection"
                            required
                            value={activeStatus}
                            onChange={(e) => setActiveStatus(e)}
                            defaultValue={""}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row className="mt-4">
                      {/* License Front Image */}
                      <Col xl={6}>
                        <div className="d-flex justify-content-center flex-column">
                          <h6>Upload License Front Image</h6>
                          <Card className="cursor-pointer">
                            <div
                              className="d-flex justify-content-center align-content-center"
                              style={{
                                border: "1px solid rgb(207 207 207)",
                                height: "145px",
                              }}
                            >
                              {licenseFrontImage ? (
                                <ImageView>
                                  <>
                                    <img
                                      src={licenseFrontImage}
                                      className="img-thumbnail img__view"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                      alt=""
                                    />
                                    <div className="button__wrapper">
                                      <button
                                        className="btn btn-danger "
                                        // onClick={() => handleDelete(item.id)}
                                        onClick={() => setLicenseFrontImage("")}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </div>
                                  </>
                                </ImageView>
                              ) : (
                                <div
                                  style={{ width: "100%", height: "100%" }}
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={() => handleImage(4)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ width: "50px" }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path strokeWidth="2" d="M12 4v16m8-8H4" />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </Card>
                        </div>
                      </Col>

                      {/* License Front Image */}
                      <Col xl={6}>
                        <div className="d-flex justify-content-center flex-column">
                          <h6>Upload License Back Image</h6>
                          <Card className="cursor-pointer">
                            <div
                              className="d-flex justify-content-center align-content-center"
                              style={{
                                border: "1px solid rgb(207 207 207)",
                                height: "145px",
                              }}
                            >
                              {licenseBackImage ? (
                                <ImageView>
                                  <>
                                    <img
                                      src={licenseBackImage}
                                      className="img-thumbnail img__view"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                      }}
                                      alt=""
                                    />
                                    <div className="button__wrapper">
                                      <button
                                        className="btn btn-danger "
                                        // onClick={() => handleDelete(item.id)}
                                        onClick={() => setLicenseBackImage("")}
                                      >
                                        <i className="fa fa-trash" />
                                      </button>
                                    </div>
                                  </>
                                </ImageView>
                              ) : (
                                <div
                                  style={{ width: "100%", height: "100%" }}
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={() => handleImage(5)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ width: "50px" }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path strokeWidth="2" d="M12 4v16m8-8H4" />
                                  </svg>
                                </div>
                              )}
                            </div>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column align-items-center mt-md-4 mt-lg-0"
                  >
                    {/* PARTNER IMAGE */}
                    <div className="d-flex justify-content-center   w-75 flex-column ">
                      <h6>Upload Driver Image</h6>
                      <Card className="cursor-pointer ">
                        <div
                          className="d-flex justify-content-center align-content-center w-100"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {driverImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={driverImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setDriverImage("")}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(1)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>

                    {/* PARTNER NID FRONT IMAGE */}

                    <div className="d-flex justify-content-center  w-75 flex-column">
                      <h6>Upload Driver NID Front Side</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center w-100 "
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {nidFrontImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={nidFrontImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setNidFrontImage("")}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(2)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>

                    {/* PARTNER NID BACK SIDE */}

                    <div className="d-flex justify-content-center flex-column w-75">
                      <h6>Upload Driver NID Back Side</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center w-100"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {nidBackImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={nidBackImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setNidBackImage("")}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(3)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  <Button
                    onClick={handleSubmit}
                    className="mt-5"
                    color="primary"
                    style={{ width: "250px" }}
                  >
                    {loading ? (
                      <Spinner animation="border" color="info" size="sm" />
                    ) : id ? (
                      "Update"
                    ) : (
                      "Add"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
          {/* IMAGE MODAL */}

          <Modal isOpen={openPartnerSearch} toggle={() => {}}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Select Partner
              </h5>
              <i
                className="ion ion-md-close cursor-pointer"
                style={{ fontSize: "21px" }}
                onClick={() => history.goBack()}
              ></i>
            </div>
            <div className="modal-body">
              <Search
                dispatchFunc={updateSearchKey}
                placeholder="Search Parnter"
              />
              <PartnerListWrapper>
                {partners.map((partner, index) => (
                  <div key={index}>
                    <div
                      className=" partner__wrapper"
                      onClick={() => selectPartner(partner)}
                    >
                      <div className="img__wrapper">
                        <img src={partner.img} alt="Partner" />
                      </div>
                      <div className="ms-3 d-flex content__wrapper">
                        <span>{partner.name}</span>
                        <span className="ms-1">{partner.phone}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </PartnerListWrapper>
            </div>
            <div className="modal-footer"></div>
          </Modal>

          <Modal
            size="xl"
            isOpen={modal_fullscreen}
            toggle={() => {
              setmodal_fullscreen(!modal_fullscreen);
            }}
            className="modal-fullscreen"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
                Select Image
              </h5>
              <button
                onClick={() => {
                  setmodal_fullscreen(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ImageSelectionDialog
                lisener={(list) => {
                  const image = list[0].path;
                  // console.log("")
                  if (imageId == 1) {
                    setDriverImage(image);
                  }
                  if (imageId == 2) {
                    setNidFrontImage(image);
                  }
                  if (imageId == 3) {
                    setNidBackImage(image);
                  }
                  if (imageId == 4) {
                    setLicenseFrontImage(image);
                  }
                  if (imageId == 5) {
                    setLicenseBackImage(image);
                  }

                  dispatch(removeAllSelectedGalleryImage());
                  setmodal_fullscreen(!modal_fullscreen);
                }}
                partnerId={selectedPartner?.id}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setmodal_fullscreen(!modal_fullscreen);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

const ImageView = styled.div`
  width: 100% !important;
  max-width: 300px;

  position: relative;
  width: 100%;

  .img_view {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .button__wrapper {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    .remove__btn {
      background-color: yellow;
      font-size: 18px;
      color: red;
    }
  }

  &:hover {
    .img_view {
      opacity: 0.3;
    }
    .button__wrapper {
      opacity: 1;
    }
  }
`;

const PartnerListWrapper = styled.div`
  padding: 20px;

  .partner__wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    cursor: pointer;
    height: 80px;
    &:hover {
      background-color: #f0f1f2;
    }

    .img__wrapper {
      width: 65px;
      height: 70px;

      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }

    .content__wrapper {
      display: flex;
      flex-direction: column;
      span {
        font-size: 17px;
        color: black;
        font-weight: 400;
      }
    }
  }
`;

const Value = styled.h5`
  color: #458110;
  font-style: italic;
  font-weight: bold;
  /* padding-left: 5px; */
`;

const statusOptons = [
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Cancel", value: "cancel" },
];

export default DriverAdd;

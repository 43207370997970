import * as actionType from "../actionType";

const initialState = {
  loading: false,
  cancelReasons: [],
  error: null,
  status: false,
  typeKey: { label: "All", value: "all" },
};

const cancelReasonReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // ADD
    case actionType.ADD_CANCEL_REASON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case actionType.ADD_CANCEL_REASON_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        cancelReasons: [...state.cancelReasons, payload],
      };

    case actionType.ADD_CANCEL_REASON_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    //   GET ALL

    case actionType.GET_ALL_CANCEL_REASON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };
    case actionType.GET_ALL_CANCEL_REASON_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelReasons: payload,
        status: false,
      };

    case actionType.GET_ALL_CANCEL_REASON_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionType.UPDATE_TUTORIAL_TYPE_KEY:
      return {
        ...state,
        typeKey: payload,
      };

    // EDIT

    case actionType.EDIT_CANCEL_REASON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case actionType.EDIT_CANCEL_REASON_REQUEST_SUCCESS:
      const update = state.cancelReasons.map((item) =>
        item.id == payload.id ? payload : item
      );

      return {
        ...state,
        loading: false,
        status: true,
        cancelReasons: update,
      };

    case actionType.EDIT_CANCEL_REASON_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // DELETE

    case actionType.DELETE_CANCEL_REASON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case actionType.DELETE_CANCEL_REASON_REQUEST_SUCCESS:
      const updateData = state.cancelReasons.filter(
        (item) => item.id != payload.id
      );

      return {
        ...state,
        loading: false,
        status: true,
        cancelReasons: [...updateData],
      };

    case actionType.DELETE_CANCEL_REASON_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default cancelReasonReducer;

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useSelector } from "react-redux";
import requestApi from "../../../network/httpRequest";
import { SINGLE_CAR } from "../../../network/Api";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import { ShimmerThumbnail, ShimmerCircularImage } from "react-shimmer-effects";
import moment from "moment";

const CarDetails = () => {
  const { id } = useParams();
  const { search, pathname } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  // console.log(" first id--------", id)

  const [car, setCar] = useState({});
  const [carImageGalley, setCarImageGalley] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imgIndex, setImgIndex] = useState(0);
  const [smartCardImg, setSmartCardImg] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const showImageGallery = (images) => {
    setCarImageGalley(images);
    // console.log("after images----", carImageGalley);
    setIsOpen(true);
  };

  useEffect(() => {
    if (id) {
      const getSingleCar = async () => {
        try {
          const { data } = await requestApi().request(SINGLE_CAR, {
            params: {
              id: id,
            },
          });
          if (data.status) {
            setCar(data.data.car);
            let smartImgs = [];
            const {
              carSmartCardFont,
              carSmartCardBack,
              taxTokenImage,
              fitnessCertificate,
              partner: { img },
            } = data.data.car;
            smartImgs.push(
              carSmartCardFont,
              carSmartCardBack,
              taxTokenImage,
              fitnessCertificate,
              img
            );
            if (smartImgs.length > 0) {
              setSmartCardImg(smartImgs);
            }
          } else {
            console.log(data.error);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getSingleCar();
    } else {
      // console.log("call-----------")
      history.push("/partner/list", { replace: true });
    }
  }, []);

  // EDIT CAR

  const editCar = (carId, partnerId) => {
    history.push({
      pathname: `/car/edit/${carId}`,
      search: `?pID=${partnerId}`,
      // state: { detail: 'some_value' }
    });
    // history.push(`/partner/edit/${partner.id}`)
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Quicar"
              breadcrumbItem="Details"
              title="Car"
              isRefresh={false}
            />

            {isOpen ? (
              <Lightbox
                // closeLabel={"close button"}
                // closeButtonAriaLabel={"close button"}
                mainSrc={smartCardImg[imgIndex]}
                nextSrc={smartCardImg[(imgIndex + 1) % smartCardImg.length]}
                prevSrc={
                  smartCardImg[
                    (imgIndex + smartCardImg.length - 1) % smartCardImg.length
                  ]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={
                  () =>
                    setImgIndex(
                      (imgIndex + smartCardImg.length - 1) % smartCardImg.length
                    )
                  // setPhotoIndex({
                  //   photoIndex:
                  //     (photoIndex + carImageGalley.length - 1) %
                  //     carImageGalley.length
                  // })
                }
                onMoveNextRequest={
                  () => setImgIndex((imgIndex + 1) % smartCardImg.length)
                  // setPhotoIndex({
                  //   photoIndex: (photoIndex + 1) % carImageGalley.length,
                  // })
                }
              />
            ) : null}

            <Row>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <CardTitle>Car Information</CardTitle>
                      <Button
                        outline={true}
                        color="success"
                        onClick={() => editCar(car?.id, car?.partner?.id)}
                      >
                        Edit
                      </Button>
                    </div>
                    <hr />
                    <Row>
                      <Col
                        xl={4}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>
                          {car.carFrontImage ? (
                            <img
                              className="rounded-circle avatar-xl cursor-pointer"
                              alt="partner"
                              src={car?.carFrontImage ? car?.carFrontImage : ""}
                              onClick={() => {
                                showImageGallery([
                                  car?.carFrontImage,
                                  car?.carBackImage,
                                  car?.carInnerImage,
                                ]);
                              }}
                            />
                          ) : (
                            <ShimmerCircularImage size={150} />
                          )}
                        </div>
                      </Col>
                      <Col xl={8}>
                        <div className="ps-4">
                          <Details>
                            <h5>Car Type:</h5>
                            <Value>{car?.car_type?.name}</Value>
                          </Details>
                          <Details>
                            <h5>Brand:</h5>
                            <Value>{car?.car_brand?.name}</Value>
                          </Details>
                          <Details>
                            <h5>Model:</h5>
                            <Value>{car?.car_model?.name}</Value>
                          </Details>
                          <Details>
                            <h5>Color:</h5>
                            <Value>{car?.color?.name}</Value>
                          </Details>
                          <Details>
                            <h5>year:</h5>
                            <Value>{car?.year?.year}</Value>
                          </Details>
                          <Details>
                            <h5>Fuel:</h5>
                            <Value>{car?.car_fuel_type?.name}</Value>
                          </Details>
                          <Details>
                            <h5>Reg Number:</h5>
                            <Value>{car?.carRegisterNumber}</Value>
                          </Details>
                          <Details>
                            <h5>Status:</h5>
                            <Value>{car?.status}</Value>
                          </Details>
                          <Details>
                            <h5>Current Status:</h5>
                            <Value>{car?.currentStatus}</Value>
                          </Details>
                          <Details>
                            <h5>Join Date:</h5>
                            <Value>
                              {moment(car?.createdAt).format("MMMM Do YYYY")}
                            </Value>
                          </Details>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6}>
                <Card style={{ height: "273px" }}>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <CardTitle>Partner Information</CardTitle>

                      <Button
                        onClick={() =>
                          history.push(`/partner/details/${car.partner.id}`)
                        }
                        outline={true}
                        color="primary"
                      >
                        Details
                      </Button>
                    </div>
                    <hr />
                    <Row>
                      <Col
                        xl={4}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>
                          {car?.partner?.img ? (
                            <img
                              className="rounded-circle avatar-xl cursor-pointer"
                              alt="partner"
                              src={car?.partner?.img}
                              onClick={() => {
                                setImgIndex(2);
                                setIsOpen(true);
                              }}
                            />
                          ) : (
                            <ShimmerCircularImage size={150} />
                          )}
                        </div>
                      </Col>
                      <Col
                        xl={8}
                        className="d-flex justify-content-start align-items-center"
                      >
                        <div className="ps-4 ">
                          <Details>
                            <h5>Name:</h5>
                            <Value>{car?.partner?.name}</Value>
                          </Details>
                          <Details>
                            <h5>Phone:</h5>
                            <Value>{car?.partner?.phone}</Value>
                          </Details>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div className="d-flex justify-content-center">
              <Card
                style={{
                  maxWidth: "600px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <CardBody>
                  <CardTitle>Car Smart Card Images</CardTitle>
                  <Row>
                    {smartCardImg.length > 0 &&
                      [...smartCardImg.slice(0, 4)].map((img, index) => (
                        <Col lg={3} key={index}>
                          {img ? (
                            <img
                              src={img}
                              alt=""
                              className="img-thumbnail cursor-pointer"
                              onClick={() => {
                                setImgIndex(index);
                                setIsOpen(true);
                              }}
                              style={{ height: "200px" }}
                            />
                          ) : (
                            <ShimmerThumbnail
                              height={180}
                              width={200}
                              rounded
                            />
                          )}
                        </Col>
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const Details = styled.div`
  display: flex;
  /* justify-content: space-evenly; */
`;

const Value = styled.h5`
  color: lightcoral;
  font-style: italic;
  font-weight: 500;
  margin-left: 4px;
  /* padding-left: 5px; */
`;

export default CarDetails;

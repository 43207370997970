import React, { useState } from "react";
import styled from "styled-components";
import GlobalWrapper from "../../components/GlobalWrapper";
import { TextField, Tooltip } from "@mui/material";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  offerTypes,
  partnerOfferApplyTypes,
  statusOptions,
  userOfferApplyTypes,
  userTypes,
} from "../../assets/staticData";
import SelectOptionsTwo from "../../components/SelectOptionsTwo";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { alertMsg } from "../../helpers/alertMsg";
import { useDispatch, useSelector } from "react-redux";
import {
  addOffer,
  getOffers,
  setStatusFalse,
  updateOffer,
} from "../../store/Offer/offerAction";
import { useEffect } from "react";
import requestApi from "../../network/httpRequest";
import { OFFERS } from "../../network/Api";

const Offers = () => {
  const dispatch = useDispatch();
  const { loading, status, offers } = useSelector(
    (state) => state.offerReducer
  );

  const [openAddModal, setOpenAddModal] = useState(false);
  const [offer, setOffer] = useState({
    id: null,
    type: "",
    applyFor: "",
    startDate: "",
    endDate: "",
    applyType: "",
    applyTypeAmount: "",
    amount: "",
    status: "",
  });

  const addNewOffer = () => {
    setOffer({
      type: "",
      applyFor: "",
      startDate: "",
      endDate: "",
      applyType: "",
      applyTypeAmount: "",
      amount: "",
    });

    setOpenAddModal(!openAddModal);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getOffers());
    }

    return () => (mounted = false);
  }, []);

  // HANDLE NEW OFFER INPUT FIELD

  const handleAddOffer = (e) => {
    const { name, value } = e.target;
    if (name === "type" && value === "signUp") {
      setOffer((prev) => ({
        ...prev,
        [name]: value,
        applyType: "signUp",
        applyTypeAmount: "",
      }));
    } else {
      setOffer((prev) => ({ ...prev, [name]: value }));
    }
  };

  // SUBMIT OFFER DATA

  const submitOffer = (e) => {
    e.preventDefault();
    const {
      id,
      type,
      applyFor,
      startDate,
      endDate,
      applyType,
      applyTypeAmount,
      amount,
      status,
    } = offer;

    if (!startDate || !endDate || new Date(startDate) >= new Date(endDate)) {
      return alertMsg("Invalid Start and End Date!", "warning");
    }

    if (id) {
      if (!status) {
        return alertMsg("Status was not found!", "warning");
      }

      const data = {
        id,
        status,
        startDate,
        endDate,
      };

      dispatch(updateOffer(data));
    } else {
      if (!type || !startDate || !amount) {
        return alertMsg("Fillup Required Fields!", "warning");
      }

      if (type === "trip" && !applyType) {
        return alertMsg("Select Apply Type", "warning");
      }

      if (applyType !== "signUp" && !applyTypeAmount) {
        return alertMsg(`Enter ${applyType}`, "warning");
      }

      dispatch(addOffer(offer));
    }
  };

  useEffect(() => {
    if (status) {
      setOpenAddModal(false);
      setOffer({
        type: "",
        applyFor: "",
        startDate: "",
        endDate: "",
        applyType: "",
        applyTypeAmount: "",
        amount: "",
      });
    }

    return () => dispatch(setStatusFalse());
  }, [status]);

  // UPDATE OFFER

  const editOffer = async (offerId) => {
    try {
      if (!offerId) {
        throw new Error("Invalid Request! Try again.");
      }

      const { data } = await requestApi().request(`${OFFERS}/${offerId}`);

      if (!data.status) {
        throw new Error(data.message ?? "Offer not found");
      }

      const {
        startDate,
        endDate,
        status,
        type,
        amount,
        applyFor,
        applyType,
        applyTypeAmount,
      } = data?.data;

      setOffer({
        id: offerId,
        startDate,
        endDate,
        status,
        type,
        amount,
        applyFor,
        applyType,
        applyTypeAmount,
      });

      setOpenAddModal(!openAddModal);
    } catch (error) {
      alertMsg(error.message, "warning");
    }
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Offers"
              isAddNew={true}
              isModalOpen={addNewOffer}
              //   callList={callNoticeList}
              //   loading={loading}
            />
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4">Offers</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper  table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Type</Th>
                      <Th>Apply For</Th>
                      <Th>Condition</Th>
                      <Th>Bonus</Th>
                      <Th>Start Date</Th>
                      <Th>End Date</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {offers?.length > 0 &&
                      offers?.map(
                        (
                          {
                            id,
                            amount,
                            applyFor,
                            applyType,
                            applyTypeAmount,
                            startDate,
                            endDate,
                            status,
                            type,
                          },
                          index
                        ) => (
                          <Tr
                            key={id}
                            className="align-middle"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                              textAlign: "center",
                            }}
                          >
                            <Td>{type === "signUp" ? "Sign Up" : "Trip"}</Td>
                            <Th className="text-capitalize">{applyFor}</Th>
                            <Td className="text-capitalize">
                              {" "}
                              {applyType === "signUp"
                                ? "New User"
                                : `${applyTypeAmount} ${
                                    applyType === "tripCount" ? "Trip's" : "TK"
                                  }`}
                            </Td>
                            <Th className="text-capitalize">{amount}TK</Th>
                            <Th>
                              <p>{new Date(startDate).toDateString()}</p>
                              <p>{new Date(startDate).toLocaleTimeString()}</p>
                            </Th>
                            <Th>
                              <p>{new Date(endDate).toDateString()}</p>
                              <p>{new Date(endDate).toLocaleTimeString()}</p>
                            </Th>
                            <Td
                              className={`text-capitalize ${
                                status == "active"
                                  ? "active_color"
                                  : "inactive_color"
                              }`}
                            >
                              {status}
                            </Td>
                            <Td>
                              <div>
                                <Tooltip title={`Edit`}>
                                  <button
                                    className={`btn me-xl-3 button btn-info`}
                                    onClick={() => editOffer(id)}
                                    disabled={loading}
                                  >
                                    <i
                                      className={`fa fa-pen`}
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </Tooltip>
                              </div>
                            </Td>
                          </Tr>
                        )
                      )}
                    {loading && (
                      <Tr>
                        <Td>
                          <Spinner
                            style={{
                              position: "fixed",
                              left: "50%",
                              top: "50%",
                            }}
                            animation="border"
                            color="success"
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {offers?.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found</h3>
                  </div>
                )}
              </CardBody>
            </Card>
          </Container>
        </div>

        {/* ADD MODAL */}

        <Modal
          isOpen={openAddModal}
          toggle={() => {
            setOpenAddModal(!openAddModal);
          }}
          centered={true}
          className="modal-lg"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {offer?.id ? "Edit" : "Add"} Offer
            </h5>
            <button
              type="button"
              onClick={() => {
                setOpenAddModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={submitOffer}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl={6}>
                      <SelectOptionsTwo
                        label="Offer Type"
                        options={offerTypes}
                        value={offer?.type}
                        name="type"
                        required
                        onChange={handleAddOffer}
                        disabled={offer?.id}
                      />
                    </Col>
                    <Col xl={6} className="mt-4 mt-xl-0">
                      <SelectOptionsTwo
                        label="Apply For"
                        options={userTypes}
                        value={offer?.applyFor}
                        name="applyFor"
                        required
                        onChange={handleAddOffer}
                        disabled={offer?.id}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col xl={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Start Date & Time"
                          value={offer?.startDate}
                          onChange={(e) =>
                            setOffer((prev) => ({ ...prev, startDate: e }))
                          }
                          type="datetime-local"
                          renderInput={(params) => (
                            <TextField {...params} fullWidth name="startDate" />
                          )}
                        />
                      </LocalizationProvider>
                    </Col>
                    <Col xl={6} className="mt-4 mt-xl-0">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="End Date&Time"
                          value={offer?.endDate}
                          type="datetime-local"
                          onChange={(e) =>
                            setOffer((prev) => ({ ...prev, endDate: e }))
                          }
                          renderInput={(params) => (
                            <TextField {...params} fullWidth name="endDate" />
                          )}
                        />
                      </LocalizationProvider>
                    </Col>
                  </Row>

                  {offer?.type && offer?.type !== "signUp" && (
                    <Row className="mt-4">
                      <Col xl={6}>
                        <SelectOptionsTwo
                          label="Apply Type"
                          options={
                            offer?.applyFor === "user"
                              ? userOfferApplyTypes
                              : partnerOfferApplyTypes
                          }
                          name="applyType"
                          onChange={handleAddOffer}
                          value={offer?.applyType}
                          disabled={!offer?.applyFor || offer?.id}
                          required={offer.type === "trip"}
                        />
                      </Col>
                      <Col xl={6} className="mt-4 mt-xl-0">
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          name="applyTypeAmount"
                          label={
                            offer?.applyType === "tripCount"
                              ? "Completed Trip"
                              : "Total Trip Amount"
                          }
                          variant="outlined"
                          onChange={handleAddOffer}
                          type="number"
                          placeholder={
                            offer?.applyType === "tripCount"
                              ? "Enter total trip number to get this offer"
                              : "Enter amount to get this offer"
                          }
                          value={offer?.applyTypeAmount}
                          disabled={!offer?.applyType || offer?.id}
                          required={offer.type === "trip"}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row className="mt-4">
                    <Col xl={6}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        name="amount"
                        label="Bonus Amount"
                        variant="outlined"
                        onChange={handleAddOffer}
                        type="number"
                        placeholder="Enter Bonus amount"
                        value={offer?.amount}
                        required
                        disabled={offer?.id}
                      />
                    </Col>
                    {offer?.id && (
                      <Col xl={6} className="mt-4 mt-xl-0">
                        <SelectOptionsTwo
                          label="Status"
                          value={offer?.status}
                          options={statusOptions}
                          name="status"
                          onChange={handleAddOffer}
                          required={true}
                        />
                      </Col>
                    )}
                  </Row>

                  <div className="d-flex justify-content-center mt-5">
                    <Button color="primary" className="px-5">
                      {loading ? (
                        <Spinner
                          color="info"
                          animation="border"
                          size="sm"
                        ></Spinner>
                      ) : offer?.id ? (
                        "Update"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </div>
        </Modal>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .heading {
    color: red;
  }
`;

export default Offers;

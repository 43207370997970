import { alertMsg } from "../../helpers/alertMsg";
import {
  NOTICE,
  DELETE_CANCEL_REASON,
  GET_ALL_CANCEL_REASON,
  UPDATE_CANCEL_REASON,
} from "../../network/Api";
import * as actionType from "../actionType";
import requestApi from "./../../network/httpRequest";

// ADD

export const addNotice = (noticeData) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_NOTICE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(NOTICE, {
      method: "POST",
      data: noticeData,
    });
    if (data.status) {
      alertMsg(data.message, "success");
      const { notice } = data.data;
      dispatch({
        type: actionType.ADD_NOTICE_REQUEST_SUCCESS,
        payload: notice,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: actionType.ADD_NOTICE_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_NOTICE_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL

export const getAllNotice =
  (refresh = false, page) =>
  async (dispatch, getState) => {
    const { notices, statusKey } = getState().noticeReducer;
    try {
      if (notices?.length < 1 || refresh) {
        // console.log("call api---")
        dispatch({
          type: actionType.ALL_NOTICE_REQUEST_SEND,
        });

        const { data } = await requestApi().request(NOTICE, {
          params: {
            status: parseInt(statusKey),
            page: page,
            pageSize: 100,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.ALL_NOTICE_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.ALL_NOTICE_REQUEST_FAIL,
            paylaod: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.ALL_NOTICE_REQUEST_FAIL,
        paylaod: error.message,
      });
    }
  };

// EDIT

export const editNotice = (updateData, id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.EDIT_NOTICE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(NOTICE + `/${id}`, {
      method: "POST",
      data: updateData,
    });

    // console.log("edited data", data);

    if (data.status) {
      alertMsg(data.message, "success");
      const { notice } = data.data;
      dispatch({
        type: actionType.EDIT_NOTICE_REQUEST_SUCCESS,
        payload: notice,
      });
    } else {
      alertMsg(data.error, "warning");

      dispatch({
        type: actionType.EDIT_NOTICE_REQUEST_FAIL,
        paylaod: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.EDIT_NOTICE_REQUEST_FAIL,
      paylaod: error.message,
    });
  }
};

// DELETE

export const deleteNotice = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.DELETE_NOTICE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(NOTICE + `/${id}`, {
      method: "DELETE",
    });

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: actionType.DELETE_NOTICE_REQUEST_SUCCESS,
        payload: id,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: actionType.DELETE_NOTICE_REQUEST_FAIL,
        paylaod: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.DELETE_NOTICE_REQUEST_FAIL,
      paylaod: error.message,
    });
  }
};

// UPDATE TYPE KEY

export const updateNoticeTypeKey = (type) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_NOTICE_TYPE_KEY,
    payload: type,
  });
};

// import * as actionType from "../actionType";

import {
  ADD_DEFAULT_MESSAGE_REQUEST_FAIL,
  ADD_DEFAULT_MESSAGE_REQUEST_SEND,
  ADD_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  DELETE_DEFAULT_MESSAGE_REQUEST_FAIL,
  DELETE_DEFAULT_MESSAGE_REQUEST_SEND,
  DELETE_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL,
  GET_ALL_DEFAULT_MESSAGE_REQUEST_SEND,
  GET_ALL_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL,
  UPDATE_DEFAULT_MESSAGE_REQUEST_SEND,
  UPDATE_DEFAULT_MESSAGE_REQUEST_SUCCESS,
  UPDATE_DEFAULT_MESSAGE_SEARCH_KEY,
  UPDATE_DEFAULT_MESSAGE_STATUS_KEY,
} from "../actionType";

const initialState = {
  loading: false,
  messages: [],
  error: null,
  status: false,
  searchKey: "",
  statusKey: { label: "All", value: "all" },
  paginate: null,
  paging: [],
  hasNextPage: true,
  currentPage: 1,
  hasPreviousPage: false,
};

const defaultMessageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // ADD
    case ADD_DEFAULT_MESSAGE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case ADD_DEFAULT_MESSAGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: [...state.messages, payload],
        status: true,
      };
    case ADD_DEFAULT_MESSAGE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        status: false,
        error: payload,
      };

    //   GET ALL

    case GET_ALL_DEFAULT_MESSAGE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_DEFAULT_MESSAGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: payload.messages,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        status: false,
      };

    case GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL:
      return {
        ...state,
        error: payload,
        status: false,
        loading: false,
      };

    // EDIT

    case UPDATE_DEFAULT_MESSAGE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case UPDATE_DEFAULT_MESSAGE_REQUEST_SUCCESS:
      const itemIndex = state.messages.findIndex(
        (item) => item.id === payload.id
      );
      if (itemIndex !== -1) {
        state.messages[itemIndex] = payload;
      }
      return {
        ...state,
        loading: false,
        messages: [...state.messages],
        status: true,
      };
    case UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        status: false,
        error: payload,
      };

    // DELETE

    case DELETE_DEFAULT_MESSAGE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case DELETE_DEFAULT_MESSAGE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        messages: state.messages.filter((item) => item.id != payload.id),
      };

    case DELETE_DEFAULT_MESSAGE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case UPDATE_DEFAULT_MESSAGE_SEARCH_KEY:
      return {
        ...state,
        searchKey: payload,
      };
    case UPDATE_DEFAULT_MESSAGE_STATUS_KEY:
      return {
        ...state,
        statusKey: payload,
      };
    default:
      return state;
  }
};

export default defaultMessageReducer;

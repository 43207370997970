import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import GlobalWrapper from "../../../components/GlobalWrapper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import AppPagination from "../../../components/AppPagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Lightbox from "react-image-lightbox";
import { useHistory, useLocation } from "react-router-dom";
import {
  allDrivers,
  clearSearchKey,
  updateCreatedByKey,
  updateCurrntStatusKey,
  updateSearchKey,
  updateStatusKey,
} from "../../../store/Driver/driverAction";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Search from "../../../components/Search";

const DriversList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParam = searchParams.get("status");

  const {
    statusKey,
    drivers,
    paging,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    searchKey,
    currentStatusKey,
    loading,
  } = useSelector((state) => state.driverReducer);

  useEffect(() => {
    dispatch(clearSearchKey());
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (queryParam) {
        dispatch(updateStatusKey(queryParam));
      } else {
        dispatch(updateStatusKey("all"));
      }
    }

    return () => (mounted = false);
  }, [queryParam]);

  useEffect(() => {
    if (!!searchKey || !!statusKey || !!currentStatusKey) {
      callDriverList(true);
    } else {
      callDriverList(true);
    }
  }, [searchKey, statusKey, currentStatusKey]);

  const callDriverList = (refresh = false) => {
    // console.log(searchKey);
    dispatch(allDrivers(refresh));
  };

  // EDIT PARTNER

  const handleEdit = (driverId, partnerId) => {
    history.push({
      pathname: `/driver/edit/${driverId}`,
      search: `?pID=${partnerId}`,
      // state: { detail: 'some_value' }
    });
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Drivers"
              breadcrumbItem="List"
              hideSettingBtn={true}
              loading={loading}
              callList={callDriverList}
              isAddNew={true}
              addNewRoute="driver/add"
            />

            {/* {isZoom
              ? <Lightbox
                  mainSrc={partnerImage}
                  enableZoom={true}
                  onCloseRequest={() => {
                    setIsZoom(!isZoom);
                  }}
                />
              : null} */}

            <Card>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={statusKey}
                          label="Status"
                          onChange={(event) =>
                            dispatch(updateStatusKey(event.target.value))
                          }
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"active"}>active</MenuItem>
                          <MenuItem value={"cancel"}>cancel</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <Search
                      dispatchFunc={updateSearchKey}
                      placeholder="Search drivers"
                    />
                  </Col>
                  <Col md={3}>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Current Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={currentStatusKey}
                          label="Current Status"
                          onChange={(event) =>
                            dispatch(updateCurrntStatusKey(event.target.value))
                          }
                        >
                          <MenuItem value={""}>All</MenuItem>
                          <MenuItem value={"online"}>Online</MenuItem>
                          <MenuItem value={"offline"}>Offline</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Drivers List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Image</Th>
                      <Th>Name</Th>
                      <Th>Email</Th>
                      <Th>Phone</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {drivers.map((driver, index) => {
                      return (
                        <Tr
                          key={index}
                          className="align-middle"
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          <Th>
                            <div style={{ width: "50px", height: "50px" }}>
                              <img
                                //   onClick={() => {
                                //     setIsZoom(true);
                                //     setPartnerImage(partner.img);
                                //   }}
                                className="img-fluid"
                                loading="lazy"
                                alt=""
                                src={driver.img}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </Th>

                          <Td>{driver?.name}</Td>
                          <Td>{driver?.email ?? "N/A"}</Td>
                          <Td>{driver?.phone}</Td>
                          <Td
                            className={
                              driver?.status === "active"
                                ? "text-success"
                                : "text-warning"
                            }
                          >
                            {driver?.status}
                          </Td>
                          <Td>
                            <ButtonWrapper>
                              <Tooltip title="Edit">
                                <button
                                  className="btn btn-info me-xl-3"
                                  onClick={() =>
                                    handleEdit(driver?.id, driver?.partnerId)
                                  }
                                >
                                  <i className="fa fa-edit" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Details">
                                <button
                                  className="btn btn-success "
                                  onClick={() =>
                                    history.push(`/driver/details/${driver.id}`)
                                  }
                                >
                                  <i className="fa fa-eye" />
                                </button>
                              </Tooltip>
                            </ButtonWrapper>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
                {loading && (
                  <Spinner
                    style={{ position: "fixed", left: "50%", top: "50%" }}
                    animation="border"
                    color="warning"
                  />
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(allDrivers(true, page))}
                  />
                  {/* <h2>Paginate</h2> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid #d3d1d1;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  .btn {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
  }
`;

export default DriversList;

import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Label,
  FormGroup,
  Modal,
  Form,
} from "reactstrap";
import Select from "react-select";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useDispatch, useSelector } from "react-redux";
import {
  addCancelReason,
  deleteCancelReason,
  getAllCancelReasons,
  updateCancelReasonTypeKey,
} from "../../../store/CancelReason/cancelReasonAction";
import { editCancelReason } from "../../../store/CancelReason/cancelReasonAction";
import { alertMsg } from "../../../helpers/alertMsg";
import {
  CancelReasonfilterTypes,
  cancelReasonTypes,
  statusOptions,
} from "../../../assets/staticData";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import SelectOptions from "../../../components/SelectOptions";
import EditButton from "../../../components/EditButton";
import DeleteButton from "../../../components/DeleteButton";

const CancelReason = () => {
  const dispatch = useDispatch();

  const { loading, status, cancelReasons, typeKey } = useSelector(
    (state) => state.cancelReasonReducer
  );

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState(null);
  const [reasonStatus, setReasonStatus] = useState(null);
  const [reasonId, setReasonId] = useState(null);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false);

  useEffect(() => {
    if (typeKey) {
      callReasonList(true);
    }
  }, [typeKey]);

  const callReasonList = (refresh = false) => {
    dispatch(getAllCancelReasons(refresh));
  };

  //   SUBMIT

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!type) {
      return alertMsg("Enter Type", "warning");
    }

    if (!message) {
      return alertMsg("Enter Cancel Reason Message", "warning");
    }

    if (reasonId) {
      dispatch(
        editCancelReason({
          id: reasonId,
          name,
          message,
          type: type.value,
          status: reasonStatus.value,
        })
      );
    } else {
      dispatch(
        addCancelReason({
          name,
          message,
          type: type.value,
        })
      );
    }
  };

  // ADD NEW

  const addNewReason = () => {
    setMessage("");
    setName("");
    setType(null);
    setReasonId(null);
    setReasonStatus(null);
    setIsOpenAddModal(!isOpenAddModal);
  };

  // HANDLE EDIT EVENT

  const handleEdit = (rId) => {
    if (rId) {
      setReasonId(rId);
      const { name, status, type, message } = cancelReasons.find(
        (item) => item.id == rId
      );
      const findStatus = statusOptions.find((item) => item.value == status);
      const findType = cancelReasonTypes.find((item) => item.value == type);
      setMessage(message);
      setName(name);
      setReasonStatus(findStatus);
      setType(findType);
      setIsOpenAddModal(!isOpenAddModal);
    }
  };

  // DELETE EVENT

  const handleDelete = (rId) => {
    // dispatch(deleteCancelReason({ id: rId }));
    setReasonId(rId);
    setIsOpenDeleteConfirmModal(!isOpenDeleteConfirmModal);
  };

  useEffect(() => {
    if (status) {
      setMessage("");
      setName("");
      setType(null);
      setReasonId(null);
      setReasonStatus(null);
      setIsOpenAddModal(false);
      setIsOpenDeleteConfirmModal(false);
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Cancel Reason"
              hideSettingBtn={true}
              loading={loading}
              callList={callReasonList}
              isAddNew={true}
              isModalOpen={addNewReason}
            />

            <Card>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <SelectOptions
                      label="Type"
                      value={typeKey}
                      options={CancelReasonfilterTypes}
                      onChange={(e) => dispatch(updateCancelReasonTypeKey(e))}
                      name="type"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col xl={12}>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <CardTitle style={{ flex: "2" }} className="h4">
                            Cancel Reason List
                          </CardTitle>
                        </div>

                        <Table
                          id="tech-companies-1"
                          className="table table-bordered table-hover text-center"
                        >
                          <Thead>
                            <Tr>
                              <Th>Title</Th>
                              <Th>Type</Th>
                              <Th>Status</Th>
                              <Th>Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {cancelReasons.length > 0 &&
                              cancelReasons.map(
                                ({ name, type, status, id }, index) => {
                                  return (
                                    <Tr
                                      key={index}
                                      className="align-middle"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Th style={{ maxWidth: "250px" }}>
                                        {name}
                                      </Th>
                                      <Td className="text-capitalize">
                                        {type}
                                      </Td>
                                      <Td
                                        className={`text-capitalize ${
                                          status === "active"
                                            ? "active_color"
                                            : "inactive_color"
                                        }`}
                                      >
                                        {status}
                                      </Td>
                                      <Td>
                                        <div>
                                          <EditButton
                                            editItem={() => handleEdit(id)}
                                          />
                                          <span className="mx-2"></span>
                                          <DeleteButton
                                            deleteItem={() => handleDelete(id)}
                                          />
                                        </div>
                                      </Td>
                                    </Tr>
                                  );
                                }
                              )}
                          </Tbody>
                        </Table>
                        {cancelReasons.length < 1 && !loading && (
                          <div className="text-center">
                            <h4>No Data Found!</h4>
                          </div>
                        )}
                        {loading && (
                          <div className="text-center">
                            <Spinner animation="border" color="info" />
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/*MODAL FOR ADD AND EDIT CANCEL REASON  */}
        <Modal
          isOpen={isOpenAddModal}
          toggle={() => {
            setIsOpenAddModal(!isOpenAddModal);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{`${
              reasonId ? "Edit" : "Add"
            } Cancel Reason`}</h5>
            <button
              type="button"
              onClick={() => {
                setIsOpenAddModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={handleSubmit}>
              <div>
                <div className="mb-3">
                  <Label>Title</Label>
                  <Input
                    // style={{ border: '1px solid red' }}
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    id="name"
                    className=""
                    type="text"
                    placeholder="Enter Title"
                  />
                </div>
                <div className="mb-3">
                  <Label>Type</Label>
                  <Select
                    value={type}
                    onChange={(event) => {
                      setType(event);
                    }}
                    options={cancelReasonTypes}
                    label="Select Type"
                    classNamePrefix="select2-selection"
                  />
                </div>

                <div className="mb-3">
                  <Label>Reason Message</Label>
                  <Input
                    // style={{ border: '1px solid red' }}
                    value={message}
                    onChange={(event) => {
                      setMessage(event.target.value);
                    }}
                    id="reason"
                    className=""
                    type="textarea"
                    rows={3}
                    placeholder="Enter Cancel Reason"
                  />
                </div>

                {reasonId && (
                  <div className="mb-3">
                    <Label>Select Status</Label>
                    <Select
                      value={reasonStatus}
                      onChange={(event) => {
                        setReasonStatus(event);
                      }}
                      options={statusOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                )}

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    className="px-4"
                    disabled={loading}
                  >
                    {loading ? "Saving.." : reasonId ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>

        {/* DELETE CONFIRMATION MODAL */}

        {isOpenDeleteConfirmModal && (
          <ConfirmDialog
            isOpen={isOpenDeleteConfirmModal}
            dispatchFunc={() => dispatch(deleteCancelReason(reasonId))}
            closeDialog={() => setIsOpenDeleteConfirmModal(false)}
            title="Delete Reason"
            content="Are you sure! You want to delete this cancel reason?"
          />
        )}
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default CancelReason;

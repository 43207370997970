import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addDiscount,
  selectUser,
} from "../../../store/Discount/discountAction";
import { usersList } from "../../../store/Users/UsersAction";
import { alertMsg } from "../../../helpers/alertMsg";

const DiscountAdd = () => {
  const dispatch = useDispatch();

  const { loading, status, selectedUser } = useSelector(
    (state) => state.discountReducer
  );
  const { users } = useSelector((state) => state.usersReducer);

  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [applyType, setApplyType] = useState("");
  const [searchUser, setSearchUser] = useState("");

  // const handleChange = (newValue) => {
  //   // setStartDate(newValue);
  //   setStartDate(newValue.getDate())
  // };

  // SUBMIT

  const handleSubmit = () => {
    if (!name || amount <= 0 || !applyType) {
      return alertMsg("Please Fillup all Fields", "warning");
    }
    if (startDate >= endDate) {
      return alertMsg("End Date Can't Be Less Then Start Date", "warning");
    }
    if (applyType == "specific" && selectedUser == null) {
      return alertMsg("Select a user", "warning");
    }

    dispatch(
      addDiscount({
        name,
        amount,
        startingDate: startDate,
        expiryDate: endDate,
        applyType,
        applyToUser: selectedUser?.id,
      })
    );
  };

  // USER LIST

  useEffect(() => {
    dispatch(usersList());
  }, [users]);

  // SUCCESS

  useEffect(() => {
    if (status) {
      setName("");
      setAmount(0);
      setStartDate(new Date());
      setEndDate(new Date());
      setApplyType("");
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Discount"
              breadcrumbItem="Add"
              //   loading={loading}
              //   callList={callColorList}
            />

            <Card>
              <CardBody>
                <div className="pb-4">
                  <h4> Discount Informations</h4>
                  <hr />
                </div>
                <Row>
                  <Col xl={6}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      variant="outlined"
                      placeholder="Enter discount name"
                    />
                  </Col>
                  <Col xl={6} className="mt-4 mt-xl-0">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Amount"
                      variant="outlined"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      type="number"
                      placeholder="Enter discount amount"
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Start Date&Time"
                        value={startDate}
                        onChange={(event) => setStartDate(event)}
                        type="datetime-local"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xl={6} className="mt-4 mt-xl-0">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="End Date&Time"
                        value={endDate}
                        type="datetime-local"
                        // onChange={handleChange}
                        onChange={(event) => setEndDate(event)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xl={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={applyType}
                        label="Age"
                        onChange={(e) => setApplyType(e.target.value)}
                        placeholder="Apply Type"
                        defaultValue={""}
                      >
                        <MenuItem value="global">Global</MenuItem>
                        <MenuItem value="specific">Specific</MenuItem>
                        <MenuItem value="new">New</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  {applyType == "specific" && (
                    <Col xl={6} className="mt-4 mt-xl-0">
                      <Autocomplete
                        value={selectedUser}
                        onChange={(event, newValue) => {
                          dispatch(selectUser(newValue));
                          // console.log("new",newValue)
                        }}
                        getOptionLabel={(option) => (option ? option.name : "")}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        inputValue={searchUser}
                        onInputChange={(event, newInputValue) => {
                          setSearchUser(newInputValue);
                          // console.log("input value", newInputValue);
                        }}
                        id="controllable-states-demo6"
                        options={users.length > 0 ? users : []}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select User" />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="60"
                              src={option.img}
                              alt=""
                            />
                            {option.name}
                          </Box>
                        )}
                      />
                    </Col>
                  )}
                </Row>

                <div className="d-flex justify-content-center mt-5">
                  <Button
                    color="primary"
                    className="px-5"
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <Spinner
                        color="info"
                        animation="border"
                        size="sm"
                      ></Spinner>
                    ) : (
                      "Add"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default DiscountAdd;

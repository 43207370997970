import { toast } from "react-toastify";
import { alertMsg } from "../../helpers/alertMsg";
import { ADD_DISCOUNT, GET_ALL_DISCOUNTS } from "../../network/Api";
import * as actionType from "../actionType";
import requestApi from "./../../network/httpRequest";

// ADD

export const addDiscount = (values) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_DISCOUNT_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_DISCOUNT, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: actionType.ADD_DISCOUNT_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: actionType.ADD_DISCOUNT_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_DISCOUNT_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL

export const getAllDiscounts =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    // console.log("searchKey",searchKey);
    try {
      const { discounts, searchKey, statusKey, appliedKey } =
        getState().discountReducer;
      // console.log("searchKey",searchKey);
      if (discounts.length <= 0 || refresh) {
        dispatch({
          type: actionType.GET_ALL_DISCOUNT_REQUEST_SEND,
        });

        const { data } = await requestApi().request(GET_ALL_DISCOUNTS, {
          params: {
            searchKey: searchKey,
            page: page,
            pageSize: 100,
            status: statusKey,
            applyType: appliedKey,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.GET_ALL_DISCOUNT_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_DISCOUNT_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_DISCOUNT_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// SELECT USER

export const selectUser = (value) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_USER_FOR_DISCOUNT,
    payload: value,
  });
};

// SEARCH KEY UPDATE

export const updateDiscountSearchKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_SEARCH_KEY,
    payload: value,
  });
};

// UPDATE STATUS KEY

export const updateDiscountStatusKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_STATUS_KEY,
    payload: value,
  });
};

// UPDATE CREATED BY KEY

export const updateDiscountAppliedKey = (value) => (dispatch) => {
  //   console.log("applied key", value)
  dispatch({
    type: actionType.UPDATE_APPLIED_KEY,
    payload: value,
  });
};

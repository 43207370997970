import React, { useEffect, useState, useRef } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap";

import {
  updateTransTypeKey,
  userTransactions,
  updateTransStartDate,
  updateTransEndDate,
  updateSortByKey,
} from "../../../store/Users/UsersAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
// import { jsPDF } from "jspdf";
import { utils, writeFile } from "xlsx";
import AppPagination from "./../../../components/AppPagination";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import {
  sortByOptions,
  typeOptions,
} from "../../Partners/PartnerTransactions/PartnerTransactions";

const UserTransactions = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const componentRef = useRef();

  const {
    transType,
    loading,
    accounts,
    balance,
    paging,
    paginate,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    startDate,
    endDate,
    sortBy,
  } = useSelector((state) => state.usersReducer);

  const [isPrintOpen, setIsPrintOpen] = useState(false);

  useEffect(() => {
    if (id) {
      if (transType || (startDate && endDate) || sortBy) {
        callTransactionsList(true);
      }
    } else {
      history.push("/users/list", { replace: true });
    }
  }, [id, transType, startDate, endDate, sortBy]);

  const callTransactionsList = (refresh = false) => {
    //   console.log({id})
    dispatch(userTransactions(refresh, id));
  };

  // EXPORT FILE

  const exportFile = () => {
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(accounts);

    utils.book_append_sheet(wb, ws, "MySheet");
    writeFile(wb, "Transactions.xlsx");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Quicar User Transactions",
  });

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Drop"
              title="User"
              breadcrumbItem="Transactions"
              loading={loading}
              callList={callTransactionsList}
            />

            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-end h-100 mb-3"></div>
                <Row>
                  <Col md={3}>
                    <div>
                      <label className="control-label">Type</label>
                      <Select
                        palceholder="Select Status"
                        options={typeOptions}
                        classNamePrefix="select2-selection"
                        value={transType}
                        onChange={(event) =>
                          dispatch(updateTransTypeKey(event))
                        }
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex my-3 my-md-0 ">
                      <div className=" w-100">
                        <label>Start Date</label>
                        <div className="form-group mb-0 w-100">
                          <Flatpickr
                            className="form-control d-block"
                            id="startDate"
                            placeholder="Start Date"
                            value={startDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateTransStartDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                      <div className="ms-2 w-100">
                        <label>End Date</label>
                        <div className="form-group mb-0">
                          <Flatpickr
                            className="form-control w-100"
                            id="endDate"
                            placeholder="Select End Date"
                            value={endDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateTransEndDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div>
                      <label className="control-label">Sort By</label>
                      <Select
                        palceholder="Select Status"
                        options={sortByOptions}
                        classNamePrefix="select2-selection"
                        value={sortBy}
                        onChange={(event) => dispatch(updateSortByKey(event))}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mt-4 d-flex justify-content-between align-items-center">
                  <span style={{ color: "green" }}>
                    Balance: {balance?.toFixed(2) ?? 0} Tk.
                  </span>
                  <div>
                    <Button color="success" onClick={exportFile}>
                      <i className="fa fa-download"></i> Export
                    </Button>
                    <Button
                      color="success"
                      className="ms-3"
                      onClick={handlePrint}
                    >
                      <i className="fa fa-print"></i> Print
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div ref={componentRef}>
                  <h4 className="ms-3 mt-2">User Transactions</h4>
                  <hr className="my-2" />
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>User Note</Th>
                        <Th>Admin Note</Th>
                        <Th>Coupon Amount</Th>
                        <Th>Discount Amount</Th>
                        <Th>Debits</Th>
                        <Th>Credits</Th>
                        <Th>Balance</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {accounts.length > 0 &&
                        accounts.map((item, index) => {
                          return (
                            <Tr
                              key={index}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </Th>
                              <Td>{item?.message}</Td>
                              <Td>{item?.note} </Td>
                              <Td>
                                {[
                                  "userPointWithdrawByAdmin",
                                  "userPointWithdrawByUser",
                                  "userPointReduceForBalanceAdjustment",
                                  "userRideCancelAndPointRemove",
                                ].includes(item?.type) && item?.amount}{" "}
                              </Td>
                              <Td>
                                {[
                                  "userRideCancelAndPointGet",
                                  "userPointReceivedForBonus",
                                  "userGetPointForPartnerCancelRide",
                                  "adminPointAddToUser",
                                  "userSignUpBonus",
                                  "userReferralAndGetBonus",
                                ].includes(item?.type) && item?.amount}
                              </Td>
                              <Td>{item?.couponAmount ?? 0} </Td>
                              <Td>{item?.discountAmount ?? 0} </Td>
                              <Td>{item?.after}</Td>
                              {/* <Td>{item?.paymentReceivedNumber}</Td> */}
                            </Tr>
                          );
                        })}
                      {loading && (
                        <Tr>
                          <Td>
                            <Spinner
                              style={{
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                              }}
                              animation="border"
                              variant="success"
                            />
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </div>

                {accounts.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found</h3>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) =>
                      dispatch(userTransactions(true, id, page))
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default UserTransactions;

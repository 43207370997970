import { DASHBOARD_DATA } from "../../network/Api";
import requestApi from "../../network/httpRequest";
import * as actionType from "../actionType";

export const getAllDashboardData = () => async (dispatch, getState) => {
  const { dashboard } = getState().dashboardReducer;

  if (!dashboard) {
    try {
      dispatch({
        type: actionType.GET_ALL_DASHBOARD_DATA_REQUEST_SEND,
      });

      const {
        data: { status, data, error },
      } = await requestApi().request(DASHBOARD_DATA);

      if (status) {
        dispatch({
          type: actionType.GET_ALL_DASHBOARD_DATA_REQUEST_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: actionType.GET_ALL_DASHBOARD_DATA_REQUEST_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_DASHBOARD_DATA_REQUEST_FAIL,
        payload: error.message,
      });
    }
  }
};

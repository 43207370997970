import React from "react";
import Select from "react-select";

const SelectOptions = ({
  label,
  value,
  onChange,
  options,
  disabled = false,
  required = true,
  name = "",
}) => {
  return (
    <div>
      <label>{label}</label>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        classNamePrefix="select2-selection"
        disabled={disabled}
        required={required}
        name={name}
        label={label}
      />
    </div>
  );
};

export default SelectOptions;

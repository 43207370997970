import { toast } from "react-toastify";
import {
  ADD_COUPON,
  GET_ALL_COUPONS,
  UPDATE_COUPON,
  VISIBLE_COUPON_TO_USER,
} from "../../network/Api";
import requestApi from "../../network/httpRequest";
import * as actionType from "../actionType";
import { alertMsg } from "../../helpers/alertMsg";
// ADD

export const addCoupon = (values) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_COUPON_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_COUPON, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      toast.success(data.message, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: actionType.ADD_COUPON_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.success(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: actionType.ADD_COUPON_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_COUPON_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL

export const getAllCoupons =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    // console.log("searchKey",searchKey);
    try {
      const { coupons, searchKey, statusKey, appliedKey } =
        getState().couponReducer;
      // console.log("searchKey",searchKey);
      if (coupons.length <= 0 || refresh) {
        dispatch({
          type: actionType.GET_ALL_COUPON_REQUEST_SEND,
        });

        const { data } = await requestApi().request(GET_ALL_COUPONS, {
          params: {
            searchKey,
            page,
            pageSize: 100,
            status: statusKey.value,
            applyType: appliedKey.value,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.GET_ALL_COUPON_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_COUPON_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_COUPON_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// SELECT USER

export const selectCouponUser = (value) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_USER_FOR_COUPON,
    payload: value,
  });
};

// SEARCH KEY

export const updateCouponSearchKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_SEARCH_KEY,
    payload: value,
  });
};

// UPDATE STATUS KEY

export const updateCouponStatusKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_STATUS_KEY,
    payload: value,
  });
};

// UPDATE CREATED BY KEY

export const updateCouponAppliedKey = (value) => (dispatch) => {
  //   console.log("applied key", value)
  dispatch({
    type: actionType.UPDATE_APPLIED_KEY,
    payload: value,
  });
};

// UPDATE COUPON STATUS

export const updateCouponStatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.UPDATE_COUPON_STATUS_REQUEST_SEND,
    });

    const { data } = await requestApi().request(UPDATE_COUPON + `/${id}`, {
      method: "POST",
    });

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: actionType.UPDATE_COUPON_STATUS_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      alertMsg(data.error, "warning");
      dispatch({
        type: actionType.UPDATE_COUPON_STATUS_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (e) {
    dispatch({
      type: actionType.UPDATE_COUPON_STATUS_REQUEST_FAIL,
      payload: e.message,
    });
  }
};

// VISIBLE COUPON IN USER APP

export const visibleCouponToUser = (couponId) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.VISIBLE_COUPON_REQUEST_SEND,
    });

    const { data } = await requestApi().request(
      VISIBLE_COUPON_TO_USER + `/${couponId}`,
      {
        method: "POST",
      }
    );

    if (data.status) {
      alertMsg(data.message, "success");
      dispatch({
        type: actionType.VISIBLE_COUPON_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      alertMsg(data.message, "warning");

      dispatch({
        type: actionType.VISIBLE_COUPON_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.VISIBLE_COUPON_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

import {
  ACCEPT_WITHDRAW_REQUEST,
  GET_ALL_TRANSACTIONS,
  GET_ALL_WITHDRAW_REQUESTS,
} from "../../network/Api";
import * as actionType from "../actionType";
import requestApi from "../../network/httpRequest";

export const getAllTransactions =
  (refresh = false, page) =>
  async (dispatch, getState) => {
    const { accounts, transType, startDate, endDate, sortBy } =
      getState().transactionsReducer;

    if (accounts.length < 1 || refresh) {
      try {
        dispatch({
          type: actionType.GET_ALL_TRANSCATIONS_REQUEST_SEND,
        });

        const {
          data: { status, error, data = null },
        } = await requestApi().request(GET_ALL_TRANSACTIONS, {
          params: {
            page,
            pageSize: 100,
            pagingRange: 5,
            type: transType.value,
            startDate,
            endDate,
            sortBy: sortBy.value,
          },
        });

        if (status) {
          dispatch({
            type: actionType.GET_ALL_TRANSCATIONS_REQUEST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_TRANSCATIONS_REQUEST_FAIL,
            payload: error,
          });
        }
      } catch (error) {
        dispatch({
          type: actionType.GET_ALL_TRANSCATIONS_REQUEST_FAIL,
          payload: error.message,
        });
      }
    }
  };

export const updateAllTransSortByKey = (type) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_SORT_BY_KEY,
    payload: type,
  });
};

export const updateAllTransStartDate = (startDate) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TRANS_START_DATE,
    payload: startDate,
  });
};

export const updateAllTransEndDate = (date) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TRANS_END_DATE,
    payload: date,
  });
};

export const updateAllTransTypeKey = (status) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TYPE_KEY,
    payload: status,
  });
};

export const updateWthdrawStartDate = (startDate) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_WITHDRAW_START_DATE,
    payload: startDate,
  });
};

export const updateWithdrawEndDate = (date) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_WITHDRAW_END_DATE,
    payload: date,
  });
};

export const updateWithdrawUserTypeKey = (type) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_WITHDRAW_TYPE_KEY,
    payload: type,
  });
};

export const getAllWithdrawRequests =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    const {
      withdrawRequests,
      withdrawUserType,
      withdrawStartDate,
      withdrawEndDate,
    } = getState().transactionsReducer;

    if (withdrawRequests.length < 1 || refresh) {
      try {
        dispatch({
          type: actionType.GET_ALL_WITHDRAW_REQUEST_LIST_SEND,
        });

        const {
          data: { status, error, data = null },
        } = await requestApi().request(GET_ALL_WITHDRAW_REQUESTS, {
          params: {
            page,
            pageSize: 100,
            pagingRange: 5,
            type: withdrawUserType.value,
            startDate: withdrawStartDate,
            endDate: withdrawEndDate,
          },
        });

        if (status) {
          dispatch({
            type: actionType.GET_ALL_WITHDRAW_REQUEST_LIST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_WITHDRAW_REQUEST_LIST_FAIL,
            payload: error,
          });
        }
      } catch (error) {
        dispatch({
          type: actionType.GET_ALL_WITHDRAW_REQUEST_LIST_FAIL,
          payload: error.message,
        });
      }
    }
  };

// ACCEPT WITHDRAW REQUESTS

export const acceptWithdrawRequest = (accountId) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ACCEPT_WITHDRAW_REQUEST_SEND,
    });

    const {
      data: { status, error, message },
    } = await requestApi().request(ACCEPT_WITHDRAW_REQUEST, {
      method: "POST",
      data: {
        id: accountId,
      },
    });

    if (status) {
      dispatch({
        type: actionType.ACCEPT_WITHDRAW_REQUEST_SUCCESS,
        payload: {
          id: accountId,
          message,
        },
      });
    } else {
      dispatch({
        type: actionType.ACCEPT_WITHDRAW_REQUEST_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ACCEPT_WITHDRAW_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

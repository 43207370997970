export const statusFilterOptions = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const couponAppliedOptions = [
  { label: "All", value: "all" },
  { label: "Specific", value: "specific" },
  { label: "New", value: "new" },
  { label: "Global", value: "global" },
];

export const cancelReasonTypes = [
  { label: "Ride", value: "ride" },
  { label: "Bidding", value: "bidding" },
];

export const CancelReasonfilterTypes = [
  { label: "All", value: "all" },
  { label: "Ride", value: "ride" },
  { label: "Bidding", value: "bidding" },
];

export const noticeSentTargets = [
  { label: "All", value: "all" },
  { label: "Ride", value: "ride" },
  { label: "Bidding", value: "bidding" },
];

export const trueFalseOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const offerTypes = [
  { label: "Trip", value: "trip" },
  { label: "Sign Up", value: "signUp" },
];

export const userTypes = [
  { label: "User", value: "user" },
  { label: "Partner", value: "partner" },
];

export const partnerOfferApplyTypes = [
  { label: "Total Complete Trip", value: "tripCount" },
  { label: "Total Trip Earn Amount", value: "earnAmount" },
];

export const userOfferApplyTypes = [
  { label: "Total Complete Trip", value: "tripCount" },
  { label: "Total Trip Spend Amount", value: "spendAmount" },
];

// Ttip type

export const tripTypes = {
  0: "One Way",
  1: "Round Trip",
  2: "Body Rent",
};

// account options

export const accountOptions = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
];

export const userTypesOptions = [
  { label: "All", value: "all" },
  { label: "User", value: "user" },
  { label: "Partner", value: "partner" },
];

export const statusFilterOptionsNew = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Pending", value: "pending" },
  { label: "Cancel", value: "cancel" },
];

export const PARTNER_POINT_WITHDRAW_TYPE = "partnerPointWithdraw";
export const USER_POINT_WITHDRAW_TYPE = "userPointWithdrawByUser";

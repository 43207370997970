import React, { useState } from "react";
import styled from "styled-components";
import GlobalWrapper from "./../components/GlobalWrapper";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import AutocompletedInput from "./AutoCompleteInput";
import {
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import SelectOptionsTwo from "./SelectOptionsTwo";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ImageSelectionDialog from "../pages/Utility/ImageSelectionDialog";
import { removeAllSelectedGalleryImage } from "../store/action/galleryAction";
import { useDispatch } from "react-redux";

const SentNotification = ({
  submitData,
  updateData,
  searchKey,
  setSearchKey,
  messages,
  notification,
  loading,
  hasImage = false,
  deleteImage,
}) => {
  const dispatch = useDispatch();

  const [modalFullscreen, setmodalFullscreen] = useState(false);

  return (
    <React.Fragment>
      <Form className="mb-4" onSubmit={submitData}>
        <div className="sent_notification_wrapper">
          <div className="mb-3">
            <AutocompletedInput
              value={notification.defaultMessage}
              onChange={updateData}
              searchKey={searchKey}
              onInputChange={setSearchKey}
              list={messages}
              name="defaultMessage"
              label="Select Message"
              required={false}
            />
          </div>
          <div className="mb-3">
            <TextField
              id="outlined-required"
              name="title"
              label="Title"
              className="w-100"
              placeholder="Enter Title"
              value={notification.title}
              onChange={updateData}
              required
            />
          </div>
          <div className="mb-3">
            <TextField
              label="Message"
              required
              name="message"
              placeholder="Enter Message"
              fullWidth
              type="text"
              id="message"
              rows={3}
              value={notification.message}
              onChange={updateData}
            />
          </div>
          <div className="mb-3">
            <SelectOptionsTwo
              onChange={updateData}
              name="sendTo"
              label="Sent To"
              value={notification.sendTo}
              options={[
                { label: "User", value: "user" },
                { label: "Partner", value: "partner" },
              ]}
            />
          </div>

          <div className="mb-3">
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="type"
                onChange={updateData}
                value={notification?.type}
                required
              >
                <FormControlLabel
                  value="notification"
                  control={<Radio />}
                  label="Notification"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Sms & Notification"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {hasImage && (
            <div className="mb-3">
              {notification.image ? (
                <ImageView>
                  <img
                    src={notification.image}
                    className="img-thumbnail img__view"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                  <div className="button__wrapper">
                    <i
                      className="fa fa-trash cursor-pointer"
                      style={{ color: "red" }}
                      onClick={deleteImage}
                    />
                  </div>
                </ImageView>
              ) : (
                <div>
                  <label className="me-2">Image</label>
                  <Fab component="span">
                    <AddPhotoAlternateIcon
                      onClick={() => setmodalFullscreen(true)}
                    />
                  </Fab>
                </div>
              )}
            </div>
          )}

          <Button type="submit" className="btn-warning" disabled={loading}>
            {loading ? "Loading..." : "Sent"}
          </Button>
        </div>
      </Form>

      <Modal
        size="xl"
        isOpen={modalFullscreen}
        toggle={() => {
          setmodalFullscreen(!modalFullscreen);
        }}
        className="modal-fullscreen"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            Select Image
          </h5>
          <button
            onClick={() => {
              setmodalFullscreen(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ImageSelectionDialog
            lisener={(list) => {
              const image = list[0].path;

              updateData({ name: "image", value: image });

              dispatch(removeAllSelectedGalleryImage());
              setmodalFullscreen(!modalFullscreen);
            }}
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setmodalFullscreen(!modalFullscreen);
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .heading {
    color: red;
  }
`;

const ImageView = styled.div`
  width: 100% !important;
  max-width: 300px;

  position: relative;
  width: 100%;

  .img_view {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .button__wrapper {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    .remove__btn {
      background-color: yellow;
      font-size: 18px;
      color: red;
    }
  }

  &:hover {
    .img_view {
      opacity: 0.3;
    }
    .button__wrapper {
      opacity: 1;
    }
  }
`;

export default SentNotification;

import {
  ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
  ADD_RIDE_CANCEL_CONDITION_REQUEST_SEND,
  ADD_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
  ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
  ALL_RIDE_CANCEL_CONDITION_REQUEST_SEND,
  ALL_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
  EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL,
  EDIT_RIDE_CANCEL_CONDITION_REQUEST_SEND,
  EDIT_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS,
  SET_STATUS_FALSE,
} from "../actionType";

const initialState = {
  loading: false,
  status: false,
  conditions: [],
  error: null,
};

const RideCancelConditionReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ADD_RIDE_CANCEL_CONDITION_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case ADD_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        conditions: [...state.conditions, payload],
        status: true,
      };
    case ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case ALL_RIDE_CANCEL_CONDITION_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case ALL_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        conditions: payload,
        status: true,
      };
    case ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case EDIT_RIDE_CANCEL_CONDITION_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case EDIT_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS:
      const itemIndex = state.conditions.findIndex(
        (item) => item.id == payload.id
      );
      if (itemIndex !== -1) {
        state.conditions[itemIndex] = payload;
      }

      return {
        ...state,
        loading: false,
        status: true,
        conditions: [...state.conditions],
      };

    case EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case SET_STATUS_FALSE:
      return {
        ...state,
        status: false,
      };

    default:
      return state;
  }
};

export default RideCancelConditionReducer;

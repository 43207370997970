import { alertMsg } from "../../helpers/alertMsg";
import { OFFERS } from "../../network/Api";
import requestApi from "../../network/httpRequest";
import {
  ADD_OFFER_REQUEST_FAIL,
  ADD_OFFER_REQUEST_SEND,
  ADD_OFFER_REQUEST_SUCCESS,
  GET_OFFERS_REQUEST_FAIL,
  GET_OFFERS_REQUEST_SEND,
  GET_OFFERS_REQUEST_SUCCESS,
  SET_STATUS_FALSE,
  UPDATE_OFFER_STATUS_REQUEST_FAIL,
  UPDATE_OFFER_STATUS_REQUEST_SEND,
  UPDATE_OFFER_STATUS_REQUEST_SUCCESS,
} from "../actionType";

export const addOffer = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_OFFER_REQUEST_SEND,
    });

    const {
      data: { status, message, error, data },
    } = await requestApi().request(OFFERS, {
      method: "POST",
      data: values,
    });

    if (status) {
      alertMsg(message, "success");
      dispatch({
        type: ADD_OFFER_REQUEST_SUCCESS,
        payload: data,
      });
    } else {
      alertMsg(error, "warning");
      dispatch({
        type: ADD_OFFER_REQUEST_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: ADD_OFFER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const getOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFERS_REQUEST_SEND,
    });

    const {
      data: { status, message, error, data },
    } = await requestApi().request(OFFERS);

    if (status) {
      dispatch({
        type: GET_OFFERS_REQUEST_SUCCESS,
        payload: data.offers,
      });
    } else {
      dispatch({
        type: GET_OFFERS_REQUEST_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_OFFERS_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const updateOffer =
  ({ id, startDate, endDate, status }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_OFFER_STATUS_REQUEST_SEND,
      });

      const { data } = await requestApi().request(OFFERS + `/${id}`, {
        method: "PUT",
        data: {
          startDate,
          endDate,
          status,
        },
      });

      if (data.status) {
        alertMsg(data.message, "success");
        dispatch({
          type: UPDATE_OFFER_STATUS_REQUEST_SUCCESS,
          payload: data.data,
        });
      } else {
        alertMsg(data.error, "warning");
        dispatch({
          type: UPDATE_OFFER_STATUS_REQUEST_FAIL,
          payload: data.error,
        });
      }
    } catch (e) {
      dispatch({
        type: UPDATE_OFFER_STATUS_REQUEST_FAIL,
        payload: e.message,
      });
    }
  };

export const setStatusFalse = () => (dispatch) => {
  dispatch({
    type: SET_STATUS_FALSE,
  });
};

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import styled from "styled-components";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import requestApi from "../../../network/httpRequest";
import { SINGLE_DRIVER } from "../../../network/Api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Lightbox from "react-image-lightbox";
import { ShimmerThumbnail, ShimmerCircularImage } from "react-shimmer-effects";
import moment from "moment";

const DriverDetails = () => {
  const { id } = useParams();
  const history = useHistory();

  const { drivers } = useSelector((state) => state.driverReducer);

  const [driver, setDriver] = useState(null);
  const [driveOthersImages, setDriverOthersImages] = useState([]);
  const [isZoom, setIsZoom] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    if (id) {
      // console.log("drivers-", drivers);

      const findDriver = drivers.find((driver) => driver.id == id);
      // console.log("find driver=>", findDriver);
      if (findDriver) {
        setDriver(findDriver);

        // let nidImgs = [];
        updateOthersImages(findDriver);
      } else {
        callApi(id);
      }
    } else {
      history.push("/drivers", { replace: true });
    }
  }, [id]);

  // FIND DRIVER FROM API

  const callApi = async (driverId) => {
    try {
      const { data } = await requestApi().request(SINGLE_DRIVER + driverId);
      if (data.status) {
        setDriver(data.data.driver);
        updateOthersImages(data.data.driver);
      }
    } catch (error) {
      console.log("error");
    }
  };

  // UPDATE DRIVER IMAGES

  const updateOthersImages = (driver) => {
    let imgs = [];
    const {
      nidBackPic,
      nidFontPic,
      licenseBackPic,
      licenseFontPic,
      img,
      partner: { img: partnerImg },
    } = driver;

    imgs.push(
      nidFontPic,
      nidBackPic,
      licenseBackPic,
      licenseFontPic,
      img,
      partnerImg
    );
    if (imgs.length > 0) {
      setDriverOthersImages(imgs);
    }
  };

  // DRIVER EDIT

  const handleEdit = (driverId, partnerId) => {
    history.push({
      pathname: `/driver/edit/${driverId}`,
      search: `?pID=${partnerId}`,
      // state: { detail: 'some_value' }
    });
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true} />
          <Breadcrumbs
            maintitle="Quicar"
            breadcrumbItem="Details"
            title="Drivar"
            isRefresh={false}
          />

          {/*  IMAGES ZOOM */}

          {isZoom ? (
            <Lightbox
              // closeLabel={"close button"}
              // closeButtonAriaLabel={"close button"}
              mainSrc={driveOthersImages[photoIndex]}
              nextSrc={
                driveOthersImages[(photoIndex + 1) % driveOthersImages.length]
              }
              prevSrc={
                driveOthersImages[
                  (photoIndex + driveOthersImages.length - 1) %
                    driveOthersImages.length
                ]
              }
              onCloseRequest={() => setIsZoom(false)}
              onMovePrevRequest={
                () =>
                  setPhotoIndex(
                    (photoIndex + driveOthersImages.length - 1) %
                      driveOthersImages.length
                  )
                // setPhotoIndex({
                //   photoIndex:
                //     (photoIndex + carImageGalley.length - 1) %
                //     carImageGalley.length
                // })
              }
              onMoveNextRequest={
                () => setPhotoIndex((photoIndex + 1) % driveOthersImages.length)
                // setPhotoIndex({
                //   photoIndex: (photoIndex + 1) % carImageGalley.length,
                // })
              }
            />
          ) : null}

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <CardTitle>Driver Information</CardTitle>
                    <Button
                      outline={true}
                      color="success"
                      onClick={() => handleEdit(driver.id, driver.partner?.id)}
                    >
                      Edit
                    </Button>
                  </div>
                  <hr />
                  <Row>
                    <Col
                      xl={4}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>
                        {driver?.img ? (
                          <img
                            className="rounded-circle avatar-xl cursor-pointer"
                            alt="partner"
                            src={driver?.img}
                            onClick={() => {
                              setPhotoIndex(4);
                              setIsZoom(true);
                            }}
                          />
                        ) : (
                          <ShimmerCircularImage size={150} />
                        )}
                      </div>
                    </Col>
                    <Col xl={8}>
                      <div className="ps-4">
                        <Details>
                          <h5>Name:</h5>
                          <Value>{driver?.name}</Value>
                        </Details>
                        <Details>
                          <h5>Gmail:</h5>
                          <Value>{driver?.email}</Value>
                        </Details>
                        <Details>
                          <h5>Phone:</h5>
                          <Value>{driver?.phone}</Value>
                        </Details>
                        <Details>
                          <h5>NID:</h5>
                          <Value>{driver?.nid}</Value>
                        </Details>
                        <Details>
                          <h5>Birth Date:</h5>
                          <Value>{new Date(driver?.dob).toDateString()}</Value>
                        </Details>
                        <Details>
                          <h5>Address:</h5>
                          <Value>{driver?.address}</Value>
                        </Details>
                        <Details>
                          <h5>Status:</h5>
                          <Value>{driver?.status}</Value>
                        </Details>
                        <Details>
                          <h5>Join Date:</h5>
                          <Value>{moment(driver?.createdAt).format("MMMM Do YYYY")}</Value>
                        </Details>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <CardTitle>Partner Information</CardTitle>

                    <Button
                      onClick={() =>
                        history.push(`/partner/details/${driver.partner.id}`)
                      }
                      color="success"
                      outline={true}
                    >
                      Details
                    </Button>
                  </div>
                  <hr />
                  <Row>
                    <Col
                      xl={4}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div>
                        {driver?.partner?.img ? (
                          <img
                            className="rounded-circle avatar-xl cursor-pointer"
                            alt="partner"
                            src={driver?.partner?.img}
                            onClick={() => {
                              setPhotoIndex(5);
                              setIsZoom(true);
                            }}
                          />
                        ) : (
                          <ShimmerCircularImage size={150} />
                        )}
                      </div>
                    </Col>
                    <Col
                      xl={8}
                      className="d-flex justify-content-start align-items-center"
                    >
                      <div className="ps-4 ">
                        <Details>
                          <h5>Name:</h5>
                          <Value>{driver?.partner?.name}</Value>
                        </Details>
                        <Details>
                          <h5>Phone:</h5>
                          <Value>{driver?.partner?.phone}</Value>
                        </Details>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* DRIVER OTHERS IMAGES */}

          <Card>
            <CardBody>
              <CardTitle> Driver Others Images</CardTitle>
              <hr />
              <Row>
                {driveOthersImages.length > 0 &&
                  [...driveOthersImages.slice(0, 4)].map((img, index) => (
                    <Col
                      md={3}
                      sm={6}
                      className="d-flex justify-content-center align-content-center"
                      key={index}
                    >
                      {driver && driveOthersImages.length < 1 ? (
                        <ShimmerThumbnail height={180} width={200} rounded />
                      ) : (
                        <img
                          src={img}
                          alt=""
                          style={{ height: "150px" }}
                          className="img-thumbnail"
                          onClick={() => {
                            setPhotoIndex(index);
                            setIsZoom(true);
                          }}
                        />
                      )}
                    </Col>
                  ))}
              </Row>
            </CardBody>
          </Card>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

// const ButtonWrapper = styled.div`
//   .btn {
//     width: 30px;
//     height: 30px;
//     padding: 6px 0px;
//     border-radius: 15px;
//     text-align: center;
//     font-size: 12px;
//     line-height: 1.42857;
//   }
// `;

const Details = styled.div`
  display: flex;
  /* justify-content: space-between; */
`;

const Value = styled.h5`
  color: lightcoral;
  font-style: italic;
  font-weight: 500;
  margin-left: 4px;
  /* padding-left: 5px; */
`;

const ImageWrapper = styled.div`
  text-align: center;

  img {
    object-fit: contain;
    width: 100%;
    height: 90%;
  }
`;

export default DriverDetails;

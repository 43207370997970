export const UPLOAD_IMAGE_REQUEST_SEND = "UPLOAD_IMAGE_REQUEST_SEND";
export const UPLOAD_IMAGE_REQUEST_SUCCESS = "UPLOAD_IMAGE_REQUEST_SUCCESS";
export const UPLOAD_IMAGE_REQUEST_FAIL = "UPLOAD_IMAGE_REQUEST_FAIL";
export const SELECT_IMAGE = "SELECT_IMAGE";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const SELECT_FOLDER = "SELECT_FOLDER";
export const ADD_LIST_FOLDER = "ADD_LIST_FOLDER";
export const IMAGE_SELECTION_ERROR = "IMAGE_SELECTION_ERROR";
export const UPLOAD_IMAGE_DONE = "UPLOAD_IMAGE_DONE";

export const IMAGE_GALLEY_MARK_SELECT = "IMAGE_GALLEY_MARK_SELECT";
export const IMAGE_GALLEY_MARK_REMOVE = "IMAGE_GALLEY_MARK_REMOVE";
export const IMAGE_GALLEY_MARK_REMOVE_ALL = "IMAGE_GALLEY_MARK_REMOVE_ALL";

export const IMAGE_GALLEY_LIST_REQUEST_SEND = "IMAGE_GALLEY_LIST_REQUEST_SEND";
export const IMAGE_GALLEY_LIST_REQUEST_SUCCESS =
  "IMAGE_GALLEY_LIST_REQUEST_SUCCESS";
export const IMAGE_GALLEY_LIST_REQUEST_FAIL = "IMAGE_GALLEY_LIST_REQUEST_FAIL";

export const IMAGE_FOLDER_LIST_REQUEST_SEND = "IMAGE_FOLDER_REQUEST_SEND";
export const IMAGE_FOLDER_LIST_REQUEST_SUCCESS = "IMAGE_FOLDER_REQUEST_SUCCESS";
export const IMAGE_FOLDER_LIST_REQUEST_FAIL = "IMAGE_FOLDER_REQUEST_FAIL";
export const IMAGE_FOLDER_LIST_ADD = "IMAGE_FOLDER_LIST_ADD";

export const IMAGE_FOLDER_ADD_REQUEST_SEND = "IMAGE_FOLDER_ADD_REQUEST_SEND";
export const IMAGE_FOLDER_ADD_REQUEST_SUCCESS =
  "IMAGE_FOLDER_ADD_REQUEST_SUCCESS";
export const IMAGE_FOLDER_ADD_REQUEST_FAIL = "IMAGE_FOLDER_ADD_REQUEST_FAIL";

//banner
export const BANNER_REQUEST_SEND = "BANNER_REQUEST_SEND";
export const BANNER_REQUEST_SUCCESS = "BANNER_REQUEST_SUCCESS";
export const BANNER_REQUEST_FAIL = "BANNER_REQUEST_FAIL";
export const BANNER_REQUEST_ADD = "BANNER_REQUEST_ADD";
export const OPEN_EDIT_PAGE = "OPEN_EDIT_PAGE";

export const BANNER_ADD_REQUEST_SENT = "BANNER_ADD_REQUEST_SENT";
export const BANNER_ADD_REQUEST_FAIL = "BANNER_ADD_REQUEST_FAIL";
export const BANNER_ADD_REQUEST_SUCCESS = "BANNER_ADD_REQUEST_SUCCESS";
export const BANNER_MESSAGE_CLEAR = "BANNER_MESSAGE_CLEAR";
export const BANNER_FILTER_SELECT = "BANNER_FILTER_SELECT";

export const EDIT_BANNER_REQUEST_SEND = "EDIT_BANNER_REQUEST_SEND";
export const EDIT_BANNER_REQUEST_SUCCESS = "EDIT_BANNER_REQUEST_SUCCESS";
export const GET_EDITED_BANNER = "GET_EDITED_BANNER";
export const EDIT_BANNER_REQUEST_FAIL = "EDIT_BANNER_REQUEST_FAIL";

export const DELETE_BANNER_REQUEST_SEND = "DELETE_BANNER_REQUEST_SEND";
export const DELETE_BANNER_REQUEST_SUCCESS = "DELETE_BANNER_REQUEST_SUCCESS";
export const DELETE_BANNER_REQUEST_FAIL = "DELETE_BANNER_REQUEST_FAIL";

// CAR

export const GET_ALL_CARS_REQUEST_SUCCESS = "GET_ALL_CARS_REQUEST_SUCCESS";
export const GET_ALL_CARS_REQUEST_SEND = "GET_ALL_CARS_REQUEST_SEND";
export const GET_ALL_CARS_REQUEST_FAIL = "GET_ALL_CARS_REQUEST_FAIL";

export const CAR_SEARCH_KEY = "GET_CAR_SEARCH_KEY";

// CAR TYPE

export const ADD_CAR_TYPE_REQUEST_SEND = "ADD_CAR_TYPES_REQUEST_SEND";
export const ADD_CAR_TYPE_REQUEST_SUCCESS = "ADD_CAR_TYPES_REQUEST_SUCCESS";
export const ADD_CAR_TYPE_REQUEST_FAIL = "ADD_CAR_TYPES_REQUEST_FAIL";

export const GET_CAR_TYPES_REQUEST_SEND = "GET_CAR_TYPE_REQUEST_SEND";
export const GET_CAR_TYPES_REQUEST_SUCCESS = "GET_CAR_TYPE_REQUEST_SUCCESS";
export const CLEAR_SUCCESS_MESSAGE = "CLEAR_SUCCESS_MESSAGE";
export const GET_CAR_TYPES_REQUEST_FAIL = "GET_CAR_TYPE_REQUEST_FAIL";

export const DELETE_CAR_TYPE_REQUEST_SEND = "DELETE_CAR_TYPE_REQUEST_SEND";
export const DELETE_CAR_TYPE_REQUEST_SUCCESS =
  "DELETE_CAR_TYPE_REQUEST_SUCCESS";
export const DELETE_CAR_TYPE_REQUEST_FAIL = "DELETE_CAR_TYPE_REQUEST_FAIL";

export const EDIT_CAR_TYPE_REQUEST_SEND = "EDIT_CAR_TYPE_REQUEST_SEND";
export const EDIT_CAR_TYPE_REQUEST_SUCCESS = "EDIT_CAR_TYPE_REQUEST_SUCCESS";
export const GET_UPDATE_CAR_DATA = "GET_UPDATE_CAR_DATA";
export const EDIT_CAR_TYPE_REQUEST_FAIL = "EDIT_CAR_TYPE_REQUEST_FAIL";

export const GET_SINGLE_CAR_TYPE_REQUEST_SEND =
  "GET_SINGLE_CAR_TYPE_REQUEST_SEND";
export const GET_SINGLE_CAR_TYPE_REQUEST_SUCCESS =
  "GET_SINGLE_CAR_TYPE_REQUEST_SUCCESS";
export const GET_SINGLE_CAR_TYPE_REQUEST_FAIL =
  "GET_SINGLE_CAR_TYPE_REQUEST_FAIL";

export const SELECT_CAR_TYPE = "SELECT_CAR_TYPE";
export const SELECT_CAR_BRAND = "SELECT_CAR_BRAND";
export const SELECT_CAR_BRAND_MODEL = "SELECT_CAR_BRAND_MODEL";
export const SELECT_CAR_MODEL_COLOR = "SELECT_CAR_MODEL_COLOR";
export const SELECT_CAR_MODEL_YEAR = "SELECT_CAR_MODEL_YEAR";
export const SELECT_CAR_FUEL_TYPE = "SELECT_CAR_FUEL_TYPE";

// COLOR

export const ADD_COLOR_REQUEST_SEND = "ADD_COLOR_REQUEST_SEND";
export const ADD_COLOR_REQUEST_SUCCESS = "ADD_COLOR_REQUEST_SUCCESS";
export const ADD_COLOR_REQUEST_FAIL = "ADD_COLOR_REQUEST_FAIL";
export const ADD_COLOR_IN_COLOR_LIST = "ADD_COLOR_IN_COLOR_LIST";

export const GET_COLORS_REQUEST_SEND = "GET_COLORS_REQUEST_SEND";
export const GET_COLORS_REQUEST_SUCCESS = "GET_COLORS_REQUEST_SUCCESS";
export const GET_COLORS_REQUEST_FAIL = "GET_COLORS_REQUEST_FAIL";

export const EDIT_COLOR_REQUEST_SUCCESS = "EDIT_COLOR_REQUEST_SUCCESS";
export const EDIT_COLOR_REQUEST_FAIL = "EDIT_COLOR_REQUEST_FAIL";
export const EDIT_COLOR_REQUEST_SEND = "EDIT_COLOR_REQUEST_SEND";
export const GET_UPDATE_COLOR_DATA = "GET_UPDATE_COLOR_DATA";

// YEAR

export const ADD_YEAR_REQUEST_SEND = "ADD_YEAR_REQUEST_SEND";
export const ADD_YEAR_REQUEST_SUCCESS = "ADD_YEAR_REQUEST_SUCCESS";
export const ADD_YEAR_REQUEST_FAIL = "ADD_YEAR_REQUEST_FAIL";
export const GET_ADDED_YEAR_DATA = "GET_ADDED_YEAR_DATA";

export const GET_ALL_YEARS_REQUEST_SEND = "GET_ALL_YEARS_REQUEST_SEND";
export const GET_ALL_YEARS_REQUEST_SUCCESS = "GET_ALL_YEARS_REQUEST_SUCCESS";
export const GET_ALL_YEARS_REQUEST_FAIL = "GET_ALL_YEARS_REQUEST_FAIL";

export const EDIT_YEAR_REQUEST_SEND = "EDIT_YEAR_REQUEST_SEND";
export const EDIT_YEAR_REQUEST_SUCCESS = "EDIT_YEAR_REQUEST_SUCCESS";
export const EDIT_YEAR_REQUEST_FAIL = "EDIT_YEAR_REQUEST_FAIL";
export const UPDATE_EDITED_YEAR_DATA = "UPDATE_EDITED_YEAR_DATA";

// ADMIN ROLE

export const CREATE_ADMIN_ROLE_REQUEST_SEND = "CREATE_ADMIN_ROLE_REQUEST_SEND";
export const CREATE_ADMIN_ROLE_REQUEST_SUCCESS =
  "CREATE_ADMIN_ROLE_REQUEST_SUCCESS";
export const CREATE_ADMIN_ROLE_REQUEST_FAIL = "CREATE_ADMIN_ROLE_REQUEST_FAIL";
export const GET_CREATED_ADMIN_ROLE = "GET_CREATED_ADMIN_ROLE";

export const GET_ALL_ROLE_REQUEST_SEND = "GET_ALL_ROLE_REQUEST_SEND";
export const GET_ALL_ROLE_REQUEST_SUCCESS = "GET_ALL_ROLE_REQUEST_SUCCESS";
export const GET_ALL_ROLE_REQUEST_FAIL = "GET_ALL_ROLE_REQUEST_FAIL";

export const EDIT_ADMIN_ROLE_REQUEST_SEND = "EDIT_ADMIN_ROLE_REQUEST_SEND";
export const EDIT_ADMIN_ROLE_REQUEST_SUCCESS =
  "EDIT_ADMIN_ROLE_REQUEST_SUCCESS";
export const EDIT_ADMIN_ROLE_REQUEST_FAIL = "EDIT_ADMIN_ROLE_REQUEST_FAIL";
export const GET_UPDATE_ADMIN_ROLE = "GET_UPDATE_ADMIN_ROLE";

export const DELETE_ROLE_REQUEST_SEND = "DELETE_ROLE_REQUEST_SEND";
export const DELETE_ROLE_REQUEST_SUCCESS = "DELETE_ROLE_REQUEST_SUCCESS";
export const DELETE_ROLE_REQUEST_FAIL = "DELETE_ROLE_REQUEST_FAIL";

export const RESTORE_ROLE_REQUEST_SEND = "RESTORE_ROLE_REQUEST_SEND";
export const RESTORE_ROLE_REQUEST_SUCCESS = "RESTORE_ROLE_REQUEST_SUCCESS";
export const RESTORE_ROLE_REQUEST_FAIL = "RESTORE_ROLE_REQUEST_FAIL";

// PARTNER

export const ADD_PARTNER_REQUEST_SEND = "ADD_PARTNER_REQUEST_SEND";
export const ADD_PARTNER_REQUEST_SUCCESS = "ADD_PARTNER_REQUEST_SUCCESS";
export const ADD_PARTNER_REQUEST_FAIL = "ADD_PARTNER_REQUEST_FAIL";

export const GET_ALL_PARTNER_REQUEST_SEND = "GET_ALL_PARTNER_REQUEST_SEND";
export const GET_ALL_PARTNER_REQUEST_SUCCESS =
  "GET_ALL_PARTNER_REQUEST_SUCCESS";
export const GET_ALL_PARTNER_REQUEST_FAIL = "GET_ALL_PARTNER_REQUEST_FAIL";

export const EDIT_PARTNER_REQUEST_SEND = "EDIT_PARTNER_REQUEST_SEND";
export const EDIT_PARTNER_REQUEST_SUCCESS = "EDIT_PARTNER_REQUEST_SUCCESS";
export const EDIT_PARTNER_REQUEST_FAIL = "EDIT_PARTNER_REQUEST_FAIL";

export const PARTNER_TRANSACTIONS_REQUEST_SEND =
  "PARTNER_TRANSACTIONS_REQUEST_SEND";
export const PARTNER_TRANSACTIONS_REQUEST_SUCCESS =
  "PARTNER_TRANSACTIONS_REQUEST_SUCCESS";
export const PARTNER_TRANSACTIONS_REQUEST_FAIL =
  "PARTNER_TRANSACTIONS_REQUEST_FAIL";

export const PARTNER_REVIEWS_REQUEST_SEND = "PARTNER_REVIEWS_REQUEST_SEND";
export const PARTNER_REVIEWS_REQUEST_SUCCESS =
  "PARTNER_REVIEWS_REQUEST_SUCCESS";
export const PARTNER_REVIEWS_REQUEST_FAIL = "PARTNER_REVIEWS_REQUEST_FAIL";

// BIDDING

export const GET_BIDDING_BY_PARTNER_REQUEST_SEND =
  "GET_BIDDING_BY_PARTNER_REQUEST_SEND";
export const GET_BIDDING_BY_PARTNER_REQUEST_SUCCESS =
  "GET_BIDDING_BY_PARTNER_REQUEST_SUCCESS";
export const GET_BIDDING_BY_PARTNER_REQUEST_FAIL =
  "GET_BIDDING_BY_PARTNER_REQUEST_FAIL";

// DRIVER

export const ADD_DRIVER_REQUEST_SEND = "ADD_DRIVER_REQUEST_SEND";
export const ADD_DRIVER_REQUEST_SUCCESS = "ADD_DRIVER_REQUEST_SUCCESS";
export const ADD_DRIVER_REQUEST_FAIL = "ADD_DRIVER_REQUEST_FAIL";
export const SET_STATUS_FALSE = "SET_STATUS_FALSE";

export const GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SEND =
  "GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SEND";
export const GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SUCCESS =
  "GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SUCCESS";
export const GET_ALL_DRIVERS_BY_PARTNER_REQUEST_FAIL =
  "GET_ALL_DRIVERS_BY_PARTNER_REQUEST_FAIL";

export const EDIT_DRIVER_REQUEST_SEND = "EDIT_DRIVER_REQUEST_SEND";
export const EDIT_DRIVER_REQUEST_SUCCESS = "EDIT_DRIVER_REQUEST_SUCCESS";
export const EDIT_DRIVER_REQUEST_FAIL = "EDIT_DRIVER_REQUEST_FAIL";

export const GET_ALL_DRIVERS_REQUEST_SEND = "GET_ALL_DRIVERS_REQUEST_SEND";
export const GET_ALL_DRIVERS_REQUEST_SUCCESS =
  "GET_ALL_DRIVERS_REQUEST_SUCCESS";
export const GET_ALL_DRIVERS_REQUEST_FAIL = "GET_ALL_DRIVERS_REQUEST_FAIL";

// CAR BRAND

export const ADD_BRAND_REQUEST_SEND = "ADD_BRAND_REQUEST_SEND";
export const ADD_BRAND_REQUEST_SUCCESS = "ADD_BRAND_REQUEST_SUCCESS";
export const ADD_BRAND_REQUEST_FAIL = "ADD_BRAND_REQUEST_FAIL";

export const EDIT_BRAND_REQUEST_SEND = "EDIT_BRAND_REQUEST_SEND";
export const EDIT_BRAND_REQUEST_SUCCESS = "EDIT_BRAND_REQUEST_SUCCESS";
export const EDIT_BRAND_REQUEST_FAIL = "EDIT_BRAND_REQUEST_FAIL";

// BRAND MODEL

export const ADD_MODEL_REQUEST_SEND = "ADD_MODEL_REQUEST_SEND";
export const ADD_MODEL_REQUEST_SUCCESS = "ADD_MODEL_REQUEST_SUCCESS";
export const ADD_MODEL_REQUEST_FAIL = "ADD_MODEL_REQUEST_FAIL";

export const EDIT_MODEL_REQUEST_SEND = "EDIT_MODEL_REQUEST_SEND";
export const EDIT_MODEL_REQUEST_SUCCESS = "EDIT_MODEL_REQUEST_SUCCESS";
export const EDIT_MODEL_REQUEST_FAIL = "EDIT_MODEL_REQUEST_FAIL";

// CAR MODEL COLOR AND YEAR

export const GET_COLORS_YEARS_REQUEST_SEND = "GET_COLORS_YEARS_REQUEST_SEND";
export const GET_COLORS_YEARS_REQUEST_SUCCESS =
  "GET_COLORS_YEARS_REQUEST_SUCCESS";
export const GET_COLORS_YEARS_REQUEST_FAIL = "GET_COLORS_YEARS_REQUEST_FAIL";

// MODEL COLOR

export const ADD_MODEL_COLOR_REQUEST_SEND = "ADD_MODEL_COLOR_REQUEST_SEND";
export const ADD_MODEL_COLOR_REQUEST_SUCCESS =
  "ADD_MODEL_COLOR_REQUEST_SUCCESS";
export const ADD_MODEL_COLOR_REQUEST_FAIL = "ADD_MODEL_COLOR_REQUEST_FAIL";

// MODEL YEAR

export const ADD_MODEL_YEAR_REQUEST_SEND = "ADD_MODEL_YEAR_REQUEST_SEND";
export const ADD_MODEL_YEAR_REQUEST_SUCCESS = "ADD_MODEL_YEAR_REQUEST_SUCCESS";
export const ADD_MODEL_YEAR_REQUEST_FAIL = "ADD_MODEL_YEAR_REQUEST_FAIL";

//GET CAR FUEL TYPES

export const GET_CAR_FUEL_TYPES_REQUEST_SEND =
  "GET_CAR_FUEL_TYPES_REQUEST_SEND";
export const GET_CAR_FUEL_TYPES_REQUEST_SUCCESS =
  "GET_CAR_FUEL_TYPES_REQUEST_SUCCESS";
export const GET_CAR_FUEL_TYPES_REQUEST_FAIL =
  "GET_CAR_FUEL_TYPES_REQUEST_FAIL";

// CAR FOR PARTNER

export const ADD_CAR_REQUEST_SEND = "ADD_CAR_REQUEST_SEND";
export const ADD_CAR_REQUEST_SUCCESS = "ADD_CAR_REQUEST_SUCCESS";
export const ADD_CAR_REQUEST_FAIL = "ADD_CAR_REQUEST_FAIL";

export const GET_ALL_CARS_BY_PARTNER_REQUEST_SEND =
  "GET_ALL_CARS_BY_PARTNER_REQUEST_SEND";
export const GET_ALL_CARS_BY_PARTNER_REQUEST_SUCCESS =
  "GET_ALL_CARS_BY_PARTNER_REQUEST_SUCCESS";
export const GET_ALL_CARS_BY_PARTNER_REQUEST_FAIL =
  "GET_ALL_CARS_BY_PARTNER_REQUEST_FAIL";

export const EDIT_CAR_REQUEST_SEND = "EDIT_CAR_REQUEST_SEND";
export const EDIT_CAR_REQUEST_SUCCESS = "EDIT_CAR_REQUEST_SUCCESS";
export const EDIT_CAR_REQUEST_FAIL = "EDIT_CAR_REQUEST_FAIL";

// RIDE

export const GET_ALL_RIDE_REQUEST_SEND = "GET_ALL_RIDE_REQUEST_SEND";
export const GET_ALL_RIDE_REQUEST_SUCCESS = "GET_ALL_RIDE_REQUEST_SUCCESS";
export const GET_ALL_RIDE_REQUEST_FAIL = "GET_ALL_RIDE_REQUEST_FAIL";

export const SELECT_RIDE_CAR_TYPE = "SELECT_RIDE_CAR_TYPE";
export const SELECT_RIDE_USER = "SELECT_RIDE_USER";
export const FILTER_RIDE_USER = "FILTER_RIDE_USER";

export const UPDATE_RIDE_SEARCH_KEY = "UPDATE_RIDE_SEARCH_KEY";
export const UPDATE_RIDE_TYPE_KEY = "UPDATE_RIDE_TYPE_KEY";

export const SELECT_TRIP = "SELECT_TRIP";
export const SELECT_PICKUP_TIME = "SELECT_PICKUP_TIME";
export const SELECT_RETURN_TIME = "SELECT_RETURN_TIME";

export const ADD_RIDE_REQUEST_SEND = "ADD_RIDE_REQUEST_SEND";
export const ADD_RIDE_REQUEST_SUCCESS = "ADD_RIDE_REQUEST_SUCCESS";
export const ADD_RIDE_REQUEST_FAIL = "ADD_RIDE_REQUEST_FAIL";

export const EXTEND_WAITING_TIME_REQUEST_SEND =
  "EXTEND_WAITING_TIME_REQUEST_SEND";
export const EXTEND_WAITING_TIME_REQUEST_SUCCESS =
  "EXTEND_WAITING_TIME_REQUEST_SUCCESS";
export const EXTEND_WAITING_TIME_REQUEST_FAIL =
  "EXTEND_WAITING_TIME_REQUEST_FAIL";

export const CANCEL_RIDE_REQUEST_SEND = "CANCEL_RIDE_REQUEST_SEND";
export const CANCEL_RIDE_REQUEST_SUCCESS = "CANCEL_RIDE_REQUEST_SUCCESS";
export const CANCEL_RIDE_REQUEST_FAIL = "CANCEL_RIDE_REQUEST_FAIL";

export const NOTIFICATION_DATA_ADD_FOR_RIDE = "NOTIFICATION_DATA_ADD_FOR_RIDE";

// USERS

export const GET_ALL_USERS_REQUEST_SEND = "GET_ALL_USERS_REQUEST_SEND";
export const GET_ALL_USERS_REQUEST_SUCCESS = "GET_ALL_USERS_REQUEST_SUCCESS";
export const GET_ALL_USERS_REQUEST_FAIL = "GET_ALL_USERS_REQUEST_FAIL";

export const ADD_USER_REQUEST_SEND = "ADD_USER_REQUEST_SEND";
export const ADD_USER_REQUEST_SUCCESS = "ADD_USER_REQUEST_SUCCESS";
export const ADD_USER_REQUEST_FAIL = "ADD_USER_REQUEST_FAIL";

export const EDIT_USER_REQUEST_SEND = "EDIT_USER_REQUEST_SEND";
export const EDIT_USER_REQUEST_SUCCESS = "EDIT_USER_REQUEST_SUCCESS";
export const EDIT_USER_REQUEST_FAIL = "EDIT_USER_REQUEST_FAIL";

export const UPDATE_USERS_STATUS_KEY = "UPDATE_USERS_STATUS_KEY";
export const UPDATE_USERS_SEARCH_KEY = "UPDATE_USERS_SEARCH_KEY";
export const UPDATE_USERS_CREATED_BY_KEY = "UPDATE_USERS_CREATED_BY_KEY";

// FILTER KEY

export const UPDATE_SEARCH_KEY = "UPDATE_SEARCH_KEY";
export const UPDATE_APPLIED_KEY = "UPDATE_APPLIED_KEY";
export const UPDATE_STATUS_KEY = "UPDATE_STATUS_KEY";
export const UPDATE_CREATED_BY_KEY = "UPDATE_CREATED_BY_KEY";
export const UPDATE_CURRENT_STATUS_KEY = "UPDATE_CURRENT_STATUS_KEY";
export const UPDATE_TYPE_KEY = "UPDATE_TYPE_KEY";
export const UPDATE_BIDDING_TYPE_KEY = "UPDATE_BIDDING_TYPE_KEY";
export const UPDATE_TRANS_START_DATE = "UPDATE_TRANS_START_DATE";
export const UPDATE_TRANS_END_DATE = "UPDATE_TRANS_END_DATE";
export const UPDATE_SORT_BY_KEY = "UPDATE_SORT_BY_KEY";
// export const SET_STATUS_FALSE = "SET_STATUS_FALSE";

// WITHDRAW REQUESTS

export const UPDATE_WITHDRAW_START_DATE = "UPDATE_WITHDRAW_START_DATE";
export const UPDATE_WITHDRAW_END_DATE = "UPDATE_WITHDRAW_END_DATE";
export const UPDATE_WITHDRAW_TYPE_KEY = "UPDATE_WITHDRAW_TYPE_KEY";
export const UPDATE_WITHDRAW_STATUS_KEY = "UPDATE_WITHDRAW_STATUS_KEY";

export const GET_ALL_WITHDRAW_REQUEST_LIST_SEND =
  "GET_ALL_WITHDRAW_REQUEST_LIST_SEND";
export const GET_ALL_WITHDRAW_REQUEST_LIST_SUCCESS =
  "GET_ALL_WITHDRAW_REQUEST_LIST_SUCCESS";
export const GET_ALL_WITHDRAW_REQUEST_LIST_FAIL =
  "GET_ALL_WITHDRAW_REQUEST_LIST_FAIL";

export const ACCEPT_WITHDRAW_REQUEST_SEND = "ACCEPT_WITHDRAW_REQUEST_SEND";
export const ACCEPT_WITHDRAW_REQUEST_SUCCESS =
  "ACCEPT_WITHDRAW_REQUEST_SUCCESS";
export const ACCEPT_WITHDRAW_REQUEST_FAIL = "ACCEPT_WITHDRAW_REQUEST_FAIL";

// POLICY

export const ADD_POLICY_REQUEST_SEND = "ADD_POLICY_REQUEST_SEND";
export const ADD_POLICY_REQUEST_SUCCESS = "ADD_POLICY_REQUEST_SUCCESS";
export const ADD_POLICY_REQUEST_FAIL = "ADD_POLICY_REQUEST_FAIL";

export const GET_SINGLE_POLICY_REQUEST_SEND = "GET_SINGLE_POLICY_REQUEST_SEND";
export const GET_SINGLE_POLICY_REQUEST_SUCCESS =
  "GET_SINGLE_POLICY_REQUEST_SUCCESS";
export const GET_SINGLE_POLICY_REQUEST_FAIL = "GET_SINGLE_POLICY_REQUEST_FAIL";

// USER PAYMENT CONDITION

export const ADD_USER_PAYMENT_CONDITION_REQUEST_SEND =
  "ADD_USER_PAYMENT_CONDITION_REQUEST_SEND";

export const ADD_USER_PAYMENT_CONDITION_REQUEST_SUCCESS =
  "ADD_USER_PAYMENT_CONDITION_REQUEST_SUCCESS";

export const ADD_USER_PAYMENT_CONDITION_REQUEST_FAIL =
  "ADD_USER_PAYMENT_CONDITION_REQUEST_FAIL";

export const GET_ALL_USER_PAYMENT_CONDITION_REQUEST_SEND =
  "GET_ALL_USER_PAYMENT_CONDITION_REQUEST_SEND";

export const GET_ALL_USER_PAYMENT_CONDITION_REQUEST_SUCCESS =
  "GET_ALL_USER_PAYMENT_CONDITION_REQUEST_SUCCESS";

export const GET_ALL_USER_PAYMENT_CONDITION_REQUEST_FAIL =
  "GET_ALL_PAYMENT_CONDITION_REQUEST_FAIL";

export const EDIT_USER_PAYMENT_CONDITION_REQUEST_SEND =
  "EDIT_USER_PAYMENT_CONDITION_REQUEST_SEND";

export const EDIT_USER_PAYMENT_CONDITION_REQUEST_SUCCESS =
  "EDIT_USER_PAYMENT_CONDITION_REQUEST_SUCCESS";

export const EDIT_USER_PAYMENT_CONDITION_REQUEST_FAIL =
  "EDIT_USER_PAYMENT_CONDITION_REQUEST_FAIL";

// TUTOTIAL

export const UPDATE_TUTORIAL_TYPE_KEY = "UPDATE_TUTORIAL_TYPE_KEY";

export const GET_ALL_TUTORIAL_REQUEST_SEND = "GET_ALL_TUTORIAL_REQUEST_SEND";
export const GET_ALL_TUTORIAL_REQUEST_SUCCESS =
  "GET_ALL_TUTORIAL_REQUEST_SUCCESS";
export const GET_ALL_TUTORIAL_REQUEST_FAIL = "GET_ALL_TUTORIAL_REQUEST_FAIL";

export const ADD_TUTORIAL_REQUEST_SEND = "ADD_TUTORIAL_REQUEST_SEND";
export const ADD_TUTORIAL_REQUEST_SUCCESS = "ADD_TUTORIAL_REQUEST_SUCCESS";
export const ADD_TUTORIAL_REQUEST_FAIL = "ADD_TUTORIAL_REQUEST_FAIL";

export const EDIT_TUTORIAL_REQUEST_SEND = "EDIT_TUTORIAL_REQUEST_SEND";
export const EDIT_TUTORIAL_REQUEST_SUCCESS = "EDIT_TUTORIAL_REQUEST_SUCCESS";
export const EDIT_TUTORIAL_REQUEST_FAIL = "EDIT_TUTORIAL_REQUEST_FAIL";

export const DELETE_TUTORIAL_REQUEST_SEND = "DELETE_TUTORIAL_REQUEST_SEND";
export const DELETE_TUTORIAL_REQUEST_SUCCESS =
  "DELETE_TUTORIAL_REQUEST_SUCCESS";
export const DELETE_TUTORIAL_REQUEST_FAIL = "DELETE_TUTORIAL_REQUEST_FAIL";

// CANCEL REASON

export const ADD_CANCEL_REASON_REQUEST_SEND = "ADD_CANCEL_REASON_REQUEST_SEND";
export const ADD_CANCEL_REASON_REQUEST_SUCCESS =
  "ADD_CANCEL_REASON_REQUEST_SUCCESS";
export const ADD_CANCEL_REASON_REQUEST_FAIL = "ADD_CANCEL_REASON_REQUEST_FAIL";

export const GET_ALL_CANCEL_REASON_REQUEST_SEND =
  "GET_ALL_CANCEL_REASON_REQUEST_SEND";
export const GET_ALL_CANCEL_REASON_REQUEST_SUCCESS =
  "GET_ALL_CANCEL_REASON_REQUEST_SUCCESS";
export const GET_ALL_CANCEL_REASON_REQUEST_FAIL =
  "GET_ALL_CANCEL_REASON_REQUEST_FAIL";

export const EDIT_CANCEL_REASON_REQUEST_SEND =
  "EDIT_CANCEL_REASON_REQUEST_SEND";
export const EDIT_CANCEL_REASON_REQUEST_SUCCESS =
  "EDIT_CANCEL_REASON_REQUEST_SUCCESS";
export const EDIT_CANCEL_REASON_REQUEST_FAIL =
  "EDIT_CANCEL_REASON_REQUEST_FAIL";

export const DELETE_CANCEL_REASON_REQUEST_SEND =
  "DELETE_CANCEL_REASON_REQUEST_SEND";
export const DELETE_CANCEL_REASON_REQUEST_SUCCESS =
  "DELETE_CANCEL_REASON_REQUEST_SUCCESS";
export const DELETE_CANCEL_REASON_REQUEST_FAIL =
  "DELETE_CANCEL_REASON_REQUEST_FAIL";

export const USER_REVIEWS_REQUEST_SEND = "USER_REVIEWS_REQUEST_SEND";
export const USER_REVIEWS_REQUEST_SUCCESS = "USER_REVIEWS_REQUEST_SUCCESS";
export const USER_REVIEWS_REQUEST_FAIL = "USER_REVIEWS_REQUEST_FAIL";

export const USER_TRANSACTIONS_REQUEST_SEND = "USER_TRANSACTIONS_REQUEST_SEND";
export const USER_TRANSACTIONS_REQUEST_SUCCESS =
  "USER_TRANSACTIONS_REQUEST_SUCCESS";
export const USER_TRANSACTIONS_REQUEST_FAIL = "USER_TRANSACTIONS_REQUEST_FAIL";

// DEFAULT MESSAGE

export const ADD_DEFAULT_MESSAGE_REQUEST_SEND =
  "ADD_DEFAULT_MESSAGE_REQUEST_SEND";
export const ADD_DEFAULT_MESSAGE_REQUEST_SUCCESS =
  "ADD_DEFAULT_MESSAGE_REQUEST_SUCCESS";
export const ADD_DEFAULT_MESSAGE_REQUEST_FAIL =
  "ADD_DEFAULT_MESSAGE_REQUEST_FAIL";

export const GET_ALL_DEFAULT_MESSAGE_REQUEST_SEND =
  "GET_ALL_DEFAULT_MESSAGE_REQUEST_SEND";
export const GET_ALL_DEFAULT_MESSAGE_REQUEST_SUCCESS =
  "GET_ALL_DEFAULT_MESSAGE_REQUEST_SUCCESS";
export const GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL =
  "GET_ALL_DEFAULT_MESSAGE_REQUEST_FAIL";

export const UPDATE_DEFAULT_MESSAGE_STATUS_KEY =
  "UPDATE_DEFAULT_MESSAGE_STATUS_KEY";
export const UPDATE_DEFAULT_MESSAGE_SEARCH_KEY =
  "UPDATE_DEFAULT_MESSAGE_SEARCH_KEY";

export const UPDATE_DEFAULT_MESSAGE_REQUEST_SEND =
  "UPDATE_DEFAULT_MESSAGE_REQUEST_SEND";
export const UPDATE_DEFAULT_MESSAGE_REQUEST_SUCCESS =
  "UPDATE_DEFAULT_MESSAGE_REQUEST_SUCCESS";
export const UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL =
  "UPDATE_DEFAULT_MESSAGE_REQUEST_FAIL";

export const DELETE_DEFAULT_MESSAGE_REQUEST_SEND =
  "DELETE_DEFAULT_MESSAGE_REQUEST_SEND";
export const DELETE_DEFAULT_MESSAGE_REQUEST_SUCCESS =
  "DELETE_DEFAULT_MESSAGE_REQUEST_SUCCESS";
export const DELETE_DEFAULT_MESSAGE_REQUEST_FAIL =
  "DELETE_DEFAULT_MESSAGE_REQUEST_FAIL";

//  SYSTEM CONFIGS
export const GET_ALL_CONFIGS_REQUEST_SEND = "GET_ALL_CONFIGS_REQUEST_SEND";
export const GET_ALL_CONFIGS_REQUEST_SUCCESS =
  "GET_ALL_CONFIGS_REQUEST_SUCCESS";
export const GET_ALL_CONFIGS_REQUEST_FAIL = "GET_ALL_CONFIGS_REQUEST_FAIL";

export const UPDATE_CONFIG_REQUEST_SEND = "UPDATE_CONFIG_REQUEST_SEND";
export const UPDATE_CONFIG_REQUEST_SUCCESS = "UPDATE_CONFIG_REQUEST_SUCCESS";
export const UPDATE_CONFIG_REQUEST_FAIL = "UPDATE_CONFIG_REQUEST_FAIL";

// CLEAR SEARCH KEY

export const CLEAR_SEARCH_KEY = "CLEAR_SEARCH_KEY";

// DISCOUNT

export const ADD_DISCOUNT_REQUEST_SEND = "ADD_DISCOUNT_REQUEST_SEND";
export const ADD_DISCOUNT_REQUEST_SUCCESS = "ADD_DISCOUNT_REQUEST_SUCCESS";
export const ADD_DISCOUNT_REQUEST_FAIL = "ADD_DISCOUNT_REQUEST_FAIL";

export const GET_ALL_DISCOUNT_REQUEST_SEND = "GET_ALL_DISCOUNT_REQUEST_SEND";
export const GET_ALL_DISCOUNT_REQUEST_SUCCESS =
  "GET_ALL_DISCOUNT_REQUEST_SUCCESS";
export const GET_ALL_DISCOUNT_REQUEST_FAIL = "GET_ALL_DISCOUNT_REQUEST_FAIL";

export const SELECT_USER_FOR_DISCOUNT = "SELECT_USER_FOR_DISCOUNT";

// COUPON
export const ADD_COUPON_REQUEST_SEND = "ADD_COUPON_REQUEST_SEND";
export const ADD_COUPON_REQUEST_SUCCESS = "ADD_COUPON_REQUEST_SUCCESS";
export const ADD_COUPON_REQUEST_FAIL = "ADD_COUPON_REQUEST_FAIL";

export const GET_ALL_COUPON_REQUEST_SEND = "GET_ALL_COUPON_REQUEST_SEND";
export const GET_ALL_COUPON_REQUEST_SUCCESS = "GET_ALL_COUPON_REQUEST_SUCCESS";
export const GET_ALL_COUPON_REQUEST_FAIL = "GET_ALL_COUPON_REQUEST_FAIL";

export const SELECT_USER_FOR_COUPON = "SELECT_USER_FOR_COUPON";

export const UPDATE_COUPON_STATUS_REQUEST_SEND =
  "UPDATE_COUPON_STATUS_REQUEST_SEND";
export const UPDATE_COUPON_STATUS_REQUEST_SUCCESS =
  "UPDATE_COUPON_STATUS_REQUEST_SUCCESS";
export const UPDATE_COUPON_STATUS_REQUEST_FAIL =
  "UPDATE_COUPON_STATUS_REQUEST_FAIL";

export const VISIBLE_COUPON_REQUEST_SEND = "VISIBLE_COUPON_REQUEST_SEND";
export const VISIBLE_COUPON_REQUEST_SUCCESS = "VISIBLE_COUPON_REQUEST_SUCCESS";
export const VISIBLE_COUPON_REQUEST_FAIL = "VISIBLE_COUPON_REQUEST_FAIL";

// TRANSACTIONS

export const GET_ALL_TRANSCATIONS_REQUEST_SEND =
  "GET_ALL_TRANSCATIONS_REQUEST_SEND";
export const GET_ALL_TRANSCATIONS_REQUEST_SUCCESS =
  "GET_ALL_TRANSCATIONS_REQUEST_SUCCESS";
export const GET_ALL_TRANSCATIONS_REQUEST_FAIL =
  "GET_ALL_TRANSCATIONS_REQUEST_FAIL";

// DASHBOARD

export const GET_ALL_DASHBOARD_DATA_REQUEST_SEND =
  "GET_ALL_DASHBOARD_DATA_REQUEST_SEND";
export const GET_ALL_DASHBOARD_DATA_REQUEST_SUCCESS =
  "GET_ALL_DASHBOARD_DATA_REQUEST_SUCCESS";
export const GET_ALL_DASHBOARD_DATA_REQUEST_FAIL =
  "GET_ALL_DASHBOARD_DATA_REQUEST_FAIL";

// NOTICE

export const ADD_NOTICE_REQUEST_SEND = "ADD_NOTICE_REQUEST_SEND";
export const ADD_NOTICE_REQUEST_SUCCESS = "ADD_NOTICE_REQUEST_SUCCESS";
export const ADD_NOTICE_REQUEST_FAIL = "ADD_NOTICE_REQUEST_FAIL";

export const ALL_NOTICE_REQUEST_SEND = "ALL_NOTICE_REQUEST_SEND";
export const ALL_NOTICE_REQUEST_SUCCESS = "ALL_NOTICE_REQUEST_SUCCESS";
export const ALL_NOTICE_REQUEST_FAIL = "ALL_NOTICE_REQUEST_FAIL";

export const EDIT_NOTICE_REQUEST_SEND = "EDIT_NOTICE_REQUEST_SEND";
export const EDIT_NOTICE_REQUEST_SUCCESS = "EDIT_NOTICE_REQUEST_SUCCESS";
export const EDIT_NOTICE_REQUEST_FAIL = "EDIT_NOTICE_REQUEST_FAIL";

export const DELETE_NOTICE_REQUEST_SEND = "DELETE_NOTICE_REQUEST_SEND";
export const DELETE_NOTICE_REQUEST_SUCCESS = "DELETE_NOTICE_REQUEST_SUCCESS";
export const DELETE_NOTICE_REQUEST_FAIL = "DELETE_NOTICE_REQUEST_FAIL";

export const UPDATE_NOTICE_TYPE_KEY = "UPDATE_NOTICE_TYPE_KEY";

// OFFERS

export const ADD_OFFER_REQUEST_SEND = "ADD_OFFER_REQUEST_SEND";
export const ADD_OFFER_REQUEST_SUCCESS = "ADD_OFFER_REQUEST_SUCCESS";
export const ADD_OFFER_REQUEST_FAIL = "ADD_OFFER_REQUEST_FAIL";

export const GET_OFFERS_REQUEST_SEND = "GET_OFFERS_REQUEST_SEND";
export const GET_OFFERS_REQUEST_SUCCESS = "GET_OFFERS_REQUEST_SUCCESS";
export const GET_OFFERS_REQUEST_FAIL = "GET_OFFERS_REQUEST_FAIL";

export const UPDATE_OFFER_STATUS_REQUEST_SEND =
  "UPDATE_OFFER_STATUS_REQUEST_SEND";
export const UPDATE_OFFER_STATUS_REQUEST_SUCCESS =
  "UPDATE_OFFER_STATUS_REQUEST_SUCCESS";
export const UPDATE_OFFER_STATUS_REQUEST_FAIL =
  "UPDATE_OFFER_STATUS_REQUEST_FAIL";

// RIDE CANCEL CONDTIONS

export const ADD_RIDE_CANCEL_CONDITION_REQUEST_SEND =
  "ADD_RIDE_CANCEL_CONDITION_REQUEST_SEND";
export const ADD_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS =
  "ADD_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS";
export const ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL =
  "ADD_RIDE_CANCEL_CONDITION_REQUEST_FAIL";

export const ALL_RIDE_CANCEL_CONDITION_REQUEST_SEND =
  "ALL_RIDE_CANCEL_CONDITION_REQUEST_SEND";
export const ALL_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS =
  "ALL_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS";
export const ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL =
  "ALL_RIDE_CANCEL_CONDITION_REQUEST_FAIL";

export const EDIT_RIDE_CANCEL_CONDITION_REQUEST_SEND =
  "EDIT_RIDE_CANCEL_CONDITION_REQUEST_SEND";
export const EDIT_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS =
  "EDIT_RIDE_CANCEL_CONDITION_REQUEST_SUCCESS";
export const EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL =
  "EDIT_RIDE_CANCEL_CONDITION_REQUEST_FAIL";

import { Button } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartners,
  updateSearchKey,
  updateStatusKey,
  updateCreatedByKey,
} from "../../../store/partner/partnerActions";
import AppPagination from "../../../components/AppPagination";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Lightbox from "react-image-lightbox";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Search from "../../../components/Search";

const PartnersList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { search, pathname } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParam = searchParams.get("status");

  const [open, setOpen] = useState(false);
  const [isZoom, setIsZoom] = useState(false);
  const [partnerImage, setPartnerImage] = useState(null);

  const {
    loading,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    partners,
    searchKey,
    statusKey,
    createdByKey,
  } = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    if (searchKey || statusKey || createdByKey) {
      callPartnerList(true);
    } else {
      if (open) {
        callPartnerList(true);
      }
    }
  }, [searchKey, statusKey, createdByKey]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (queryParam) {
        dispatch(updateStatusKey(queryParam));
      } else {
        dispatch(updateStatusKey("all"));
      }
    }

    return () => (mounted = false);
  }, [queryParam]);

  const callPartnerList = (refresh = false) => {
    dispatch(getPartners(refresh));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="Quicar"
            breadcrumbItem="List"
            title="Partner"
            hideSettingBtn={true}
            loading={loading}
            callList={callPartnerList}
            isAddNew={true}
            addNewRoute="partner/add"
          />

          {isZoom ? (
            <Lightbox
              mainSrc={partnerImage}
              enableZoom={true}
              onCloseRequest={() => {
                setIsZoom(!isZoom);
              }}
            />
          ) : null}

          <Card>
            <CardBody>
              <Row>
                <Col md={3}>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={statusKey}
                        label="Status"
                        onChange={(event) =>
                          dispatch(updateStatusKey(event.target.value))
                        }
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"pending"}>Pending</MenuItem>
                        <MenuItem value={"block"}>Block</MenuItem>
                        <MenuItem value={"permanent-block"}>
                          Permanent Block
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Col>
                <Col md={6} className="d-flex align-items-center my-3 my-md-0">
                  <Search
                    dispatchFunc={updateSearchKey}
                    placeholder="Search Parnter"
                  />
                </Col>
                <Col md={3}>
                  <div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Created By
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createdByKey}
                        label="CreatedBy"
                        onChange={(event) =>
                          dispatch(updateCreatedByKey(event.target.value))
                        }
                      >
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"self"}>Self</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col md={3} className="text-end" />
              </Row>
              <CardTitle className="h4"> Partner List</CardTitle>
              <Table
                id="tech-companies-1"
                className="table table__wrapper table-striped table-bordered table-hover text-center"
              >
                <Thead>
                  <Tr>
                    <Th>Image</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Phone</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody style={{ position: "relative" }}>
                  {partners.length > 0 &&
                    partners.map((partner, index) => {
                      return (
                        <Tr
                          key={index}
                          className="align-middle"
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          <Th>
                            <div style={{ width: "50px", height: "50px" }}>
                              <img
                                onClick={() => {
                                  setIsZoom(true);
                                  setPartnerImage(partner.img);
                                }}
                                className="img-fluid cursor-pointer"
                                alt=""
                                src={partner?.img}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </Th>

                          <Td>{partner.name ?? "N/A"}</Td>
                          <Td>{partner.email ?? "N/A"}</Td>
                          <Td>{partner.phone ?? "N/A"}</Td>
                          <Td>
                            <ButtonWrapper>
                              <Tooltip title="Edit">
                                <button
                                  className="btn btn-info me-xl-3"
                                  onClick={() =>
                                    history.push(`/partner/edit/${partner.id}`)
                                  }
                                >
                                  <i className="fa fa-edit" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Details">
                                <button
                                  className="btn btn-success "
                                  onClick={() =>
                                    history.push(
                                      `/partner/details/${partner.id}`
                                    )
                                  }
                                >
                                  <i className="fa fa-eye" />
                                </button>
                              </Tooltip>
                            </ButtonWrapper>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
              {!loading && partners.length < 1 && (
                <div className="text-center">
                  <h3>No Data Found</h3>
                </div>
              )}
              {loading && (
                <Spinner
                  style={{
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                  }}
                  animation="border"
                  variant="success"
                />
              )}
            </CardBody>
          </Card>
          <Row>
            <Col xl={12}>
              <div className="d-flex justify-content-center">
                <AppPagination
                  paging={paging}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  currentPage={currentPage}
                  lisener={(page) => dispatch(getPartners(true, page))}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  .btn {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
  }
`;

export default PartnersList;

import React, { useEffect, useRef } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import styled from "styled-components";
import {
  partnerTransactions,
  updateParnterTransEndDate,
  updatePartnerTransSortByKey,
  updatePartnerTransStartDate,
  updatePartnerTransTypeKey,
} from "../../../store/partner/partnerActions";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFile } from "xlsx";
import AppPagination from "../../../components/AppPagination";
import { useHistory, useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import moment from "moment";

export const typeOptions = [
  { label: "All", value: "all" },
  { label: "balance", value: "Balance" },
];

export const sortByOptions = [
  { label: "DESC", value: "DESC" },
  { label: "ASC", value: "ASC" },
];

const PartnerTransactions = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const componentRef = useRef();
  const {
    accounts,
    transType,
    loading,
    balance,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    startDate,
    endDate,
    sortBy,
  } = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    if (id) {
      if (transType || (startDate && endDate) || sortBy) {
        callTransactionsList(true, id);
      }
    } else {
      history.push("/users/list", { replace: true });
    }
  }, [id, transType, startDate, endDate, sortBy]);

  const callTransactionsList = (refresh = false, partnerId) => {
    //   console.log({id})
    dispatch(partnerTransactions(refresh, partnerId));
  };

  const exportFile = () => {
    let wb = utils.book_new(),
      ws = utils.json_to_sheet(accounts);

    utils.book_append_sheet(wb, ws, "MySheet");
    writeFile(wb, "Transactions.xlsx");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Quicar User Transactions",
  });

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Drop"
              title="Partner"
              breadcrumbItem="Transactions"
              // loading={loading}
              // callList={callTransactionsList}
            />

            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-end h-100 mb-3"></div>
                <Row>
                  <Col md={3}>
                    <div>
                      <label className="control-label">Type</label>
                      <Select
                        palceholder="Select Status"
                        options={typeOptions}
                        classNamePrefix="select2-selection"
                        value={transType}
                        onChange={(event) =>
                          dispatch(updatePartnerTransTypeKey(event))
                        }
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex my-3 my-md-0 ">
                      <div className=" w-100">
                        <label>Start Date</label>
                        <div className="form-group mb-0 w-100">
                          <Flatpickr
                            className="form-control d-block"
                            id="startDate"
                            placeholder="Start Date"
                            value={startDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updatePartnerTransStartDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                      <div className="ms-2 w-100">
                        <label>End Date</label>
                        <div className="form-group mb-0">
                          <Flatpickr
                            className="form-control w-100"
                            id="endDate"
                            placeholder="Select End Date"
                            value={endDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateParnterTransEndDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div>
                      <label className="control-label">Sort By</label>
                      <Select
                        palceholder="Select Status"
                        options={sortByOptions}
                        classNamePrefix="select2-selection"
                        value={sortBy}
                        onChange={(event) =>
                          dispatch(updatePartnerTransSortByKey(event))
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mt-4 d-flex justify-content-between align-items-center">
                  <span style={{ color: "green" }}>
                    Balance: {balance?.toFixed(2) ?? 0} Tk.
                  </span>
                  <div>
                    <Button color="success" onClick={exportFile}>
                      <i className="fa fa-download"></i> Export
                    </Button>
                    <Button
                      color="success"
                      className="ms-3"
                      onClick={handlePrint}
                    >
                      <i className="fa fa-print"></i> Print
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div ref={componentRef}>
                  <CardTitle className="h4 ms-3 mt-2">
                    {" "}
                    Transactions List
                  </CardTitle>

                  <hr className="my-2" />
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>Partner Note</Th>
                        <Th>Admin Note</Th>
                        <Th>Coupon Amount</Th>
                        <Th>Discount Amount</Th>
                        <Th>Debits</Th>
                        <Th>Credits</Th>
                        <Th>Balance</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {accounts.length > 0 &&
                        accounts.map((item, index) => {
                          return (
                            <Tr
                              key={index}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </Th>
                              <Td>{item?.message}</Td>
                              <Td>{item?.note} </Td>
                              <Td>
                                {[
                                  "partnerPointWithdraw",
                                  "adminPointReduceForPartner",
                                  "partnerRideCancelAndPointRemove",
                                  "partnerQuicarChargePayWithReducePartnerPoint",
                                  "partner",
                                ].includes(item?.type) && item?.amount}{" "}
                              </Td>
                              <Td>
                                {[
                                  "adminPointAddToPartner",
                                  "partnerPointReceivedForBonus",
                                  "partnerPointReceivedForRideUserAdvancePayment",
                                  "partnerPointGetForUserCancel",
                                  "adminPointAddToUser",
                                  "partnerSignUpBonus",
                                  "partnerReferralAndGetBonus",
                                  "partnerPaidDueAmount",
                                ].includes(item?.type) && item?.amount}
                              </Td>
                              <Td>{item?.couponAmount ?? 0} </Td>
                              <Td>{item?.discountAmount ?? 0} </Td>
                              <Td>{item?.after ?? "N/A"}</Td>
                              {/* <Td>{item?.paymentReceivedNumber}</Td> */}
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </div>
                {loading && (
                  <Spinner
                    style={{
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                    }}
                    animation="border"
                    variant="success"
                  />
                )}
                {accounts.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found!</h3>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) =>
                      dispatch(partnerTransactions(true, id, page))
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 590px) {
    flex-direction: column;
  }

  .status_filter {
    width: 350px;

    @media (max-width: 590px) {
      width: 100%;
    }
  }

  h4 {
    @media (max-width: 590px) {
      margin-top: 15px;
    }
  }
`;

export default PartnerTransactions;

import React, { useEffect, useMemo, useState } from "react";
//@ts-check
import styled from "styled-components";
import GlobalWrapper from "../../../components/GlobalWrapper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import ImageSelectionDialog from "./../../Utility/ImageSelectionDialog";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  Row,
  Spinner,
  Form,
  CardTitle,
} from "reactstrap";
import { SINGLE_PARTNER } from "../../../network/Api";
import requestApi from "./../../../network/httpRequest";
import {
  updateSearchKey,
  getPartners,
  addCar,
  selectCarType,
  selectCarBrand,
  selectCarBrandModel,
  selectModelColor,
  selectModelYear,
  selectCarFuel,
  editCar,
  setStatusFalse,
} from "./../../../store/partner/partnerActions";
import { Autocomplete, Box, TextField } from "@mui/material";
import { removeAllSelectedGalleryImage } from "../../../store/action/galleryAction";
import {
  getCarFuelTypes,
  getCarTypes,
} from "../../../store/Car/carTypes/carTypesAction";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { alertMsg } from "../../../helpers/alertMsg";
import AutocompletedInput from "../../../components/AutoCompleteInput";
import Lightbox from "react-image-lightbox";
import moment from "moment";

const CarAdd = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  let {
    partners,
    searchKey,
    status,
    selectedCarType,
    selectedCarBrand,
    selectedBrandModel,
    selectedModelColor,
    selectedModelYear,
    selectedCarFuel,
    loading,
  } = useSelector((state) => state.partnerReducer);

  const { carTypes, carFuels } = useSelector((state) => state.carTypesReducer);

  const [openPartnerSearch, setOpenPartnerSearch] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState();
  const [searchCarType, setSearchCarType] = useState("");
  const [searchCarBrand, setSearchCarBrand] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchColor, setSearchColor] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [searchCarFuel, setSearchCarFuel] = useState("");
  const [carSmartCardFont, setCarSmartCardFont] = useState(null);
  const [carSmartCardBack, setCarSmartCardBack] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [statusKey, setStatusKey] = useState("");
  const [regDivOne, setRegDivOne] = useState("");
  const [regDivTwo, setRegDivTwo] = useState("");
  const [regDivThree, setRegDivThree] = useState("");
  const [regDivFour, setRegDivFour] = useState("");
  const [carFrontImage, setCarFrontImage] = useState(null);
  const [carBackImage, setCarBackImage] = useState(null);
  const [carInnerImage, setCarInnerImage] = useState(null);
  const [fitnessCertificate, setFitnessCertificate] = useState(null);
  const [taxTokenImage, setTaxTokenImage] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [carImages, setcarImages] = useState([]);

  useEffect(() => {
    dispatch(getCarTypes());
    dispatch(getCarFuelTypes());
  }, []);

  // GET PARTNER

  useEffect(() => {
    if (carTypes?.length > 0) {
      const pID = searchParams.get("pID");
      if (pID) {
        const findPartner = partners?.find((partner) => partner.id == pID);
        if (findPartner) {
          setPartner(findPartner);
        } else {
          callPartner(pID);
        }
      } else {
        setOpenPartnerSearch(true);
      }
    } else {
      alertMsg(
        "Please add car - Types, Brand, Model, Color, Year and Fuel  first",
        "warning"
      );
      // history.goBack();
    }
    return () => {
      dispatch(selectCarType(null));
      setCarSmartCardFont(null);
      setCarSmartCardBack(null);
      setFitnessCertificate(null);
      setTaxTokenImage(null);
      setCarFrontImage(null);
      setCarBackImage(null);
      setCarInnerImage(null);
      setRegDivOne("");
      setRegDivTwo("");
      setRegDivThree("");
      setRegDivFour("");
    };
  }, [carTypes]);

  //  CALL PARTNER

  const callPartner = async (partnerId) => {
    const data = await requestApi().request(SINGLE_PARTNER + partnerId);
    if (data.status) {
      setPartner(data.data.data.partner);
    }
    // else {--
    //   history.goBack();
    // }
  };

  // SELECT PARTNER

  const selectPartner = (partner) => {
    // console.log("id", id)
    setOpenPartnerSearch(false);
    setPartner(partner);
    const params = new URLSearchParams({ pID: partner.id });
    history.replace({ pathname: pathname, search: params.toString() });
  };

  // DEBOUNCE SEARCH

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(args[0]);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    dispatch(updateSearchKey(event.target.value));
  };

  const searchKeyListener = debounce(handleSearchChange, 300);

  // CALL PARTNER WITH SEARCH KEY

  useEffect(() => {
    if (searchKey) {
      callPartnerList(true);
    }
  }, [searchKey]);

  // CALL PARTNER LIST

  const callPartnerList = (refresh = false) => {
    // console.log(searchKey);
    dispatch(getPartners(refresh));
  };

  // SET PARTNER
  const setPartner = (partner) => {
    // partner

    setSelectedPartner(partner);

    if (id) {
      const findCar = partner?.cars.find((car) => car.id == id);

      // selectedCarType = findCar.car_type;
      if (findCar) {
        const {
          car_type,
          car_brand,
          car_fuel_type,
          year,
          color,
          car_model,
          carSmartCardBack,
          carSmartCardFont,
          carBackImage,
          carFrontImage,
          carInnerImage,
          fitnessCertificate,
          taxTokenImage,
          registrationNumberDivider1,
          registrationNumberDivider2,
          registrationNumberDivider3,
          registrationNumberDivider4,
          status,
          currentStatus,
        } = findCar;
        // car_type

        const findCarType = carTypes.find((item) => item.id == car_type.id);
        const findBrand = findCarType.carBrands.find(
          (brand) => brand.id == car_brand.id
        );
        const findModel = findBrand.carModels.find(
          (model) => model.id == car_model.id
        );
        const findColor = findModel.colors.find((c) => c.id == color.id);
        const findYear = findModel.years.find((y) => y.id == year.id);

        dispatch(selectCarType(findCarType));
        dispatch(selectCarBrand(findBrand));
        dispatch(selectCarBrandModel(findModel));
        dispatch(selectModelColor(findColor));
        dispatch(selectModelYear(findYear));
        dispatch(selectCarFuel(car_fuel_type));
        setCarSmartCardFont(carSmartCardFont);
        setCarSmartCardBack(carSmartCardBack);
        setFitnessCertificate(fitnessCertificate);
        setTaxTokenImage(taxTokenImage);
        setCarFrontImage(carFrontImage);
        setCarBackImage(carBackImage);
        setCarInnerImage(carInnerImage);
        setRegDivOne(registrationNumberDivider1);
        setRegDivTwo(registrationNumberDivider2);
        setRegDivThree(registrationNumberDivider3);
        setRegDivFour(registrationNumberDivider4);
        setStatusKey(status);
        setCurrentStatus(currentStatus);

        setcarImages([
          carSmartCardFont,
          carSmartCardBack,
          fitnessCertificate,
          taxTokenImage,
          carFrontImage,
          carInnerImage,
          carBackImage,
        ]);
      }
      // selectedCarType = findCar.carType;
    }
  };

  // SELECT IMAGE

  const handleImage = (imgId) => {
    console.log("id---", imgId);
    const params = new URLSearchParams({ pID: selectedPartner.id });

    history.replace({ pathname: pathname, search: params.toString() });
    setImageId(imgId);
    setmodal_fullscreen(true);
  };

  const handleSubmit = () => {
    if (
      !selectedCarType ||
      !selectedCarBrand ||
      !selectedBrandModel ||
      !selectedModelColor ||
      !selectedModelYear ||
      !selectedCarFuel
    ) {
      return alertMsg("Please Fillup all required Field", "warning");
    } else if (!regDivOne || !regDivTwo || !regDivThree || !regDivFour) {
      return alertMsg("Please add car registration number", "warning");
    } else if (carSmartCardFont == null || carSmartCardBack == null) {
      return alertMsg("Please add car smart card both side image", "warning");
    } else if (!carFrontImage || !carInnerImage || !carBackImage) {
      return alertMsg("Please add car images", "warning");
    } else {
      submitData();
    }
  };

  const submitData = () => {
    const data = {
      partnerId: selectedPartner?.id,
      carTypeId: selectedCarType?.id,
      carBrandId: selectedCarBrand?.id,
      carModelId: selectedBrandModel?.id,
      carColorId: selectedModelColor?.id,
      carYearId: selectedModelYear?.id,
      carFuelId: selectedCarFuel?.id,
      carSmartCardFont,
      carSmartCardBack,
      fitnessCertificate,
      taxTokenImage,
      carFrontImage,
      carBackImage,
      carInnerImage,
      registrationNumberDivider1: regDivOne,
      registrationNumberDivider2: regDivTwo,
      registrationNumberDivider3: regDivThree,
      registrationNumberDivider4: regDivFour,
    };
    if (id) {
      dispatch(
        editCar({
          ...data,
          id,
          status: statusKey,
          currentStatus,
        })
      );
    } else {
      dispatch(addCar(data));
    }
  };

  // SUCCESS

  useEffect(() => {
    if (status) {
      dispatch(selectCarType(null));
      setCarSmartCardFont(null);
      setCarSmartCardBack(null);
      setFitnessCertificate(null);
      setTaxTokenImage(null);
      setCarFrontImage(null);
      setCarBackImage(null);
      setCarInnerImage(null);
      setRegDivOne("");
      setRegDivTwo("");
      setRegDivThree("");
      setRegDivFour("");
      window.scroll(0, 0);
      if (id) {
        history.goBack();
      }
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Partner"
              title="Car"
              breadcrumbItem={id ? "Edit" : "Add"}
              //   hideSettingBtn={true}
              isRefresh={false}
            />

            {/* Image show */}

            {isOpen ? (
              <Lightbox
                // closeLabel={"close button"}
                // closeButtonAriaLabel={"close button"}
                mainSrc={carImages[imgIndex]}
                nextSrc={carImages[(imgIndex + 1) % carImages.length]}
                prevSrc={
                  carImages[
                    (imgIndex + carImages.length - 1) % carImages.length
                  ]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setImgIndex(
                    (imgIndex + carImages.length - 1) % carImages.length
                  )
                }
                onMoveNextRequest={() =>
                  setImgIndex((imgIndex + 1) % carImages.length)
                }
              />
            ) : null}

            {selectedPartner && (
              <Card>
                <CardBody>
                  <div>
                    <CardTitle>Partner</CardTitle>
                  </div>
                  <hr />
                  <Row>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex justify-content-center"
                      style={{ borderRight: "1px solid lightgray" }}
                    >
                      {selectedPartner.img ? (
                        <div
                          style={{ width: "215px", height: "200px" }}
                          className="d-flex justify-content-center"
                        >
                          <img
                            onClick={() => {
                              // setIsZoom(true);
                            }}
                            className="img-fluid cursor-pointer"
                            alt="Partner"
                            src={selectedPartner.img}
                            style={{ height: "100%" }}
                          />
                        </div>
                      ) : (
                        <ShimmerThumbnail height={180} width={200} rounded />
                      )}
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      className="d-flex justify-content-between  align-items-center"
                    >
                      <div className="ps-4">
                        <div>
                          <h5>Partner Name:</h5>
                          <Value>{selectedPartner?.name}</Value>
                        </div>
                        <div>
                          <h5>Phone:</h5>
                          <Value>
                            {selectedPartner ? selectedPartner.phone : "N/A"}
                          </Value>
                        </div>
                        <div>
                          <h5>Gmail:</h5>
                          <Value>
                            {selectedPartner ? selectedPartner.email : "N/A"}
                          </Value>
                        </div>
                        <div>
                          <h5>Join Date:</h5>
                          <Value>
                            {selectedPartner
                              ? moment(selectedPartner?.createdAt).format(
                                  "MMMM Do YYYY"
                                )
                              : "N/A"}
                          </Value>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}

            <Card>
              <CardBody>
                <Row>
                  <Col xl={6}>
                    <AutocompletedInput
                      value={selectedCarType}
                      onChange={(event, newValue) => {
                        dispatch(selectCarType(newValue));
                        // console.log("new",newValue)
                      }}
                      disabled={carTypes.length === 0}
                      searchKey={searchCarType}
                      onInputChange={(event, newInputValue) => {
                        setSearchCarType(newInputValue);
                      }}
                      list={carTypes.length > 0 ? carTypes : []}
                      label="Select a Car Type"
                      name="carType"
                      required={true}
                      showImg={true}
                    />
                  </Col>
                  <Col xl={6} className="py-4 py-xl-0">
                    <AutocompletedInput
                      disabled={!selectedCarType && id && !selectedCarBrand}
                      value={selectedCarType && selectedCarBrand}
                      onChange={(event, newValue) => {
                        dispatch(selectCarBrand(newValue));
                      }}
                      searchKey={searchCarBrand}
                      onInputChange={(event, newInputValue) => {
                        setSearchCarBrand(newInputValue);
                      }}
                      list={
                        selectedCarType !== null
                          ? selectedCarType.carBrands
                          : []
                      }
                      label="Select a Car Brand"
                      name="carBrand"
                      required={true}
                    />
                  </Col>
                </Row>

                <Row className="py-xl-4">
                  <Col xl={6}>
                    <AutocompletedInput
                      disabled={!selectedCarBrand && id && !selectedBrandModel}
                      value={selectedCarBrand && selectedBrandModel}
                      onChange={(event, newValue) => {
                        dispatch(selectCarBrandModel(newValue));
                        // console.log("new",newValue)
                      }}
                      searchKey={searchModel}
                      onInputChange={(event, newInputValue) => {
                        setSearchModel(newInputValue);
                      }}
                      list={
                        selectedCarBrand !== null
                          ? selectedCarBrand.carModels
                          : []
                      }
                      label="Select a Brand Model"
                      name="carModel"
                      required={true}
                    />
                  </Col>
                  <Col xl={6} className="py-4 py-xl-0">
                    <AutocompletedInput
                      disabled={
                        !selectedBrandModel && id && !selectedModelColor
                      }
                      value={selectedBrandModel && selectedModelColor}
                      onChange={(event, newValue) => {
                        dispatch(selectModelColor(newValue));
                        // console.log("new",newValue)
                      }}
                      searchKey={searchColor}
                      onInputChange={(event, newInputValue) => {
                        setSearchColor(newInputValue);
                      }}
                      list={
                        selectedBrandModel !== null
                          ? selectedBrandModel.colors
                          : []
                      }
                      label="Select a Color"
                      name="color"
                      required={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6}>
                    <Autocomplete
                      disabled={!selectedBrandModel && id && !selectedModelYear}
                      value={selectedBrandModel && selectedModelYear}
                      onChange={(event, newValue) => {
                        dispatch(selectModelYear(newValue));
                        // console.log("new",newValue)
                      }}
                      getOptionLabel={(option) =>
                        option.year ? option.year.toString() : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      inputValue={searchYear.toString()}
                      onInputChange={(event, newInputValue) => {
                        setSearchYear(newInputValue);
                        // console.log("input value", newInputValue);
                      }}
                      id="controllable-states-demo5"
                      options={
                        selectedBrandModel !== null
                          ? selectedBrandModel?.years
                          : []
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select a Year" />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <span style={{ color: `${option.colorCode}` }}>
                            {option.year}
                          </span>
                        </Box>
                      )}
                    />
                  </Col>
                  <Col xl={6} className="py-4 py-xl-0">
                    <AutocompletedInput
                      disabled={id && !selectedCarFuel}
                      value={selectedCarFuel}
                      onChange={(event, newValue) => {
                        dispatch(selectCarFuel(newValue));
                        // console.log("new",newValue)
                      }}
                      searchKey={searchCarFuel}
                      onInputChange={(event, newInputValue) => {
                        setSearchCarFuel(newInputValue);
                      }}
                      list={carFuels.length > 0 ? carFuels : []}
                      label="Select a car fuel type"
                      name="carFual"
                      required={true}
                    />
                  </Col>
                </Row>

                <Row className="py-xl-4">
                  <Col xl={6}>
                    <div className="d-flex">
                      <div style={{ flex: "3" }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Metro
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={regDivOne}
                            label="Status"
                            onChange={(event) =>
                              setRegDivOne(event.target.value)
                            }
                            // disabled={id && !regDivOne}
                          >
                            {areaMetro.map((area, index) => (
                              <MenuItem key={index} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ flex: "1", marginLeft: "4px" }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Serial
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={regDivTwo}
                            label="Status"
                            onChange={(event) =>
                              setRegDivTwo(event.target.value)
                            }
                            // disabled={id && !regDivTwo}
                          >
                            {divTwoOptions.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ flex: "2", marginLeft: "4px" }}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Reg No "
                          // defaultValue="Hello World"
                          type="text"
                          style={{ width: "100%" }}
                          value={regDivThree}
                          onChange={(event) =>
                            setRegDivThree(event.target.value)
                          }
                          // disabled={id && !regDivThree}
                        />
                      </div>
                      <div style={{ flex: "2", marginLeft: "4px" }}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Reg No "
                          // defaultValue="Hello World"
                          type="text"
                          style={{ width: "100%" }}
                          value={regDivFour}
                          onChange={(event) =>
                            setRegDivFour(event.target.value)
                          }
                          // disabled={id && !regDivFour}
                        />
                      </div>
                    </div>
                  </Col>
                  {id && (
                    <Col xl={6} className="py-4 py-xl-0">
                      <div>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={statusKey}
                            label="Status"
                            onChange={(event) =>
                              setStatusKey(event.target.value)
                            }
                            disabled={id && !statusKey}
                          >
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"cancel"}>Cancel</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row className="py-xl-4">
                  {id && (
                    <Col xl={6}>
                      <div>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Current Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentStatus}
                            label="Current Status"
                            onChange={(event) =>
                              setCurrentStatus(event.target.value)
                            }
                          >
                            <MenuItem value={"online"}>Online</MenuItem>
                            <MenuItem value={"offline"}>Offline</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Col>
                  )}
                </Row>

                {/* CAR SMART CARD IMAGE */}
                <Row>
                  <Col xl={3} className="py-4 py-xl-0">
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Smart Card Front Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {carSmartCardFont ? (
                            <ImageView>
                              <>
                                <img
                                  src={carSmartCardFont}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    onClick={() => setCarSmartCardFont(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(0);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(1)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Smart Card Back Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {carSmartCardBack ? (
                            <ImageView>
                              <>
                                <img
                                  src={carSmartCardBack}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setCarSmartCardBack(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(1);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(2)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Fitness Certificate Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {fitnessCertificate ? (
                            <ImageView>
                              <>
                                <img
                                  src={fitnessCertificate}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    onClick={() => setFitnessCertificate(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(2);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(3)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Tax Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {taxTokenImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={taxTokenImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setTaxTokenImage(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(3);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(4)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>

                {/* SELECT CAR IMAGE */}
                <Row>
                  <Col xl={4}>
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Front Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {carFrontImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={carFrontImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setCarFrontImage(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(4);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(5)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Inner Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {carInnerImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={carInnerImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setCarInnerImage(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(5);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(6)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="d-flex justify-content-center flex-column">
                      <h6>Upload Car Back Image</h6>
                      <Card className="cursor-pointer">
                        <div
                          className="d-flex justify-content-center align-content-center"
                          style={{
                            border: "1px solid rgb(207 207 207)",
                            height: "145px",
                          }}
                        >
                          {carBackImage ? (
                            <ImageView>
                              <>
                                <img
                                  src={carBackImage}
                                  className="img-thumbnail img__view"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                />
                                <div className="button__wrapper">
                                  <button
                                    className="btn btn-danger "
                                    // onClick={() => handleDelete(item.id)}
                                    onClick={() => setCarBackImage(null)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                  {id && (
                                    <button
                                      className="btn btn-info "
                                      onClick={() => {
                                        setImgIndex(6);
                                        setIsOpen(true);
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  )}
                                </div>
                              </>
                            </ImageView>
                          ) : (
                            <div
                              style={{ width: "100%", height: "100%" }}
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => handleImage(7)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ width: "50px" }}
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>

                {/* SUBMIT BUTTON */}

                <div className="d-flex justify-content-center">
                  <Button
                    style={{ width: "40%" }}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading || carTypes.length === 0}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="info" size="sm" />
                    ) : id ? (
                      "Edit"
                    ) : (
                      "Add"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>

        {/* SELECT PARTNER FROM DIALOG */}

        <Modal isOpen={openPartnerSearch} toggle={() => {}}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Select Partner
            </h5>

            <i
              onClick={() => history.goBack()}
              className="fa fa-window-close cursor-pointer"
              style={{ fontSize: "20px" }}
            ></i>
          </div>
          <div className="modal-body">
            <SearchWrapper>
              <div className="search__wrapper">
                <i className="fa fa-search"></i>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Find Partner "
                  id="search"
                  autoComplete="off"
                  onChange={searchKeyListener}
                />
              </div>
            </SearchWrapper>
            <PartnerListWrapper>
              {partners.length > 0 ? (
                partners.map((partner, index) => (
                  <div key={index}>
                    <div
                      className=" partner__wrapper"
                      onClick={() => selectPartner(partner)}
                    >
                      <div className="img__wrapper">
                        <img src={partner.img} alt="Partner" />
                      </div>
                      <div className="ms-3 d-flex content__wrapper">
                        <span>{partner.name}</span>
                        <span className="ms-1">{partner.phone}</span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <h5>No Partner Found..</h5>
                </div>
              )}
              {loading && (
                <Spinner animation="border" variant="info" size="lg" />
              )}
            </PartnerListWrapper>
          </div>
          <div className="modal-footer">
            {/* <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
              >
                Save changes
              </button> */}
          </div>
        </Modal>

        {/* SELECT IMAGE FOR CAR SMART CAR */}

        <Modal
          size="xl"
          isOpen={modal_fullscreen}
          toggle={() => {
            setmodal_fullscreen(!modal_fullscreen);
          }}
          className="modal-fullscreen"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
              Select Image
            </h5>
            <button
              onClick={() => {
                setmodal_fullscreen(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ImageSelectionDialog
              lisener={(list) => {
                const image = list[0];
                // console.log("full image---", carImage);
                // console.log("")
                if (imageId == 1) {
                  setCarSmartCardFont(image.path);
                }
                if (imageId == 2) {
                  setCarSmartCardBack(image.path);
                }

                if (imageId == 3) {
                  setFitnessCertificate(image.path);
                }

                if (imageId == 4) {
                  setTaxTokenImage(image.path);
                  // console.log("images---", newArray)
                }
                if (imageId == 5) {
                  setCarFrontImage(image.path);
                }
                if (imageId == 6) {
                  setCarInnerImage(image.path);
                }
                if (imageId == 7) {
                  setCarBackImage(image.path);
                }

                dispatch(removeAllSelectedGalleryImage());
                setmodal_fullscreen(!modal_fullscreen);
              }}
              partnerId={selectedPartner?.id}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setmodal_fullscreen(!modal_fullscreen);
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default CarAdd;

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

const Value = styled.h5`
  color: #458110;
  font-style: italic;
  font-weight: bold;
  /* padding-left: 5px; */
`;

const PartnerListWrapper = styled.div`
  padding: 20px;

  .partner__wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    cursor: pointer;
    height: 80px;
    &:hover {
      background-color: #f0f1f2;
    }

    .img__wrapper {
      width: 65px;
      height: 70px;

      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }

    .content__wrapper {
      display: flex;
      flex-direction: column;
      span {
        font-size: 17px;
        color: black;
        font-weight: 400;
      }
    }
  }
`;

const CarTypeWrapper = styled.div`
  img {
    width: 80px;
  }
  span {
    margin-left: 15px;
    font-size: 15px;
  }
`;

const ImageView = styled.div`
  width: 100% !important;
  max-width: 300px;

  position: relative;
  width: 100%;

  .img_view {
    opacity: 1;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .button__wrapper {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    .remove__btn {
      background-color: yellow;
      font-size: 18px;
      color: red;
    }
  }

  &:hover {
    .img_view {
      opacity: 0.3;
    }
    .button__wrapper {
      opacity: 1;
    }
  }
`;

const areaMetro = [
  "ঢাকা-মেট্রো",
  "খুলনা-মেট্রো",
  "দিনাজপুর-মেট্রো",
  "রাজশাহী-মেট্রো",
  "সিলেট-মেট্রো",
  "মাগুলা-মেট্রো",
  "চট্ট-মেট্রো",
  "ময়মনসিংহ-মেট্রো",
  "রংপুর-মেট্রো",
];

const divTwoOptions = ["খ", "গ", "ঘ", "চ", "ছ", "ঝ"];

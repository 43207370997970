import React, { useEffect } from "react";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import Select from "react-select";
import {
  sortByOptions,
  typeOptions,
} from "../Partners/PartnerTransactions/PartnerTransactions";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import GlobalWrapper from "../../components/GlobalWrapper";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAllTransTypeKey,
  updateAllTransStartDate,
  updateAllTransEndDate,
  updateAllTransSortByKey,
  getAllTransactions,
} from "../../store/Transactions/transactionsAction";
import AppPagination from "../../components/AppPagination";
import { useHistory } from "react-router-dom";

const Transactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    accounts,
    transType,
    loading,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    startDate,
    endDate,
    sortBy,
  } = useSelector((state) => state.transactionsReducer);

  useEffect(() => {
    if (transType || startDate || endDate || sortBy) {
      callTransactionsList(true);
    }
  }, [transType, startDate, endDate, sortBy]);

  const callTransactionsList = (refresh = false) => {
    dispatch(getAllTransactions(refresh));
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Transactions"
              loading={loading}
              callList={callTransactionsList}
            />

            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-end h-100 mb-3"></div>
                <Row>
                  <Col md={3}>
                    <div>
                      <label className="control-label">Type</label>
                      <Select
                        palceholder="Select Status"
                        options={typeOptions}
                        classNamePrefix="select2-selection"
                        value={transType}
                        onChange={(event) =>
                          dispatch(updateAllTransTypeKey(event))
                        }
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex my-3 my-md-0 ">
                      <div className=" w-100">
                        <label>Start Date</label>
                        <div className="form-group mb-0 w-100">
                          <Flatpickr
                            className="form-control d-block"
                            id="startDate"
                            placeholder="Start Date"
                            value={startDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateAllTransStartDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                      <div className="ms-2 w-100">
                        <label>End Date</label>
                        <div className="form-group mb-0">
                          <Flatpickr
                            className="form-control w-100"
                            id="endDate"
                            placeholder="Select End Date"
                            value={endDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateAllTransEndDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div>
                      <label className="control-label">Sort By</label>
                      <Select
                        palceholder="Select Status"
                        options={sortByOptions}
                        classNamePrefix="select2-selection"
                        value={sortBy}
                        onChange={(event) =>
                          dispatch(updateAllTransSortByKey(event))
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div>
                  <CardTitle className="h4 ms-3 mt-2">
                    Transactions List
                  </CardTitle>

                  <hr className="my-2" />
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>Name</Th>
                        <Th>Note</Th>
                        <Th>Admin Note</Th>
                        <Th>Coupon Amount</Th>
                        <Th>Discount Amount</Th>
                        <Th>Debits</Th>
                        <Th>Credits</Th>
                        <Th>Type</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {accounts.length > 0 &&
                        accounts.map((item, index) => {
                          return (
                            <Tr
                              key={index}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </Th>
                              <Td
                                className="cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    item?.userId
                                      ? `/user/details/${item?.userId}`
                                      : `/partner/details/${item?.partnerId}`
                                  )
                                }
                                title="See Details"
                              >
                                {item?.userId
                                  ? item?.user?.name
                                  : item?.partner?.name}
                              </Td>
                              <Td>{item?.message}</Td>
                              <Td>{item?.note} </Td>
                              <Td>{item?.couponAmount} </Td>
                              <Td>{item?.discountAmount} </Td>
                              <Td>
                                {(item?.type === "adminPointReduceForPartner" ||
                                  item?.type === "userPointWithdrawByAdmin") &&
                                  item?.amount}
                              </Td>
                              <Td>
                                {(item?.type === "adminPointAddToUser" ||
                                  item?.type === "adminPointAddToPartner") &&
                                  item?.amount}
                              </Td>
                              <Td>{item?.partnerId ? "Partner" : "User"}</Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </div>
                {loading && (
                  <Spinner
                    style={{
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                    }}
                    animation="border"
                    variant="success"
                  />
                )}
                {accounts.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found!</h3>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(getAllTransactions(true, page))}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default Transactions;

import {
  GET_ALL_CONFIGS_REQUEST_FAIL,
  GET_ALL_CONFIGS_REQUEST_SEND,
  GET_ALL_CONFIGS_REQUEST_SUCCESS,
  UPDATE_CONFIG_REQUEST_FAIL,
  UPDATE_CONFIG_REQUEST_SEND,
  UPDATE_CONFIG_REQUEST_SUCCESS,
} from "../actionType";

const initialState = {
  loading: false,
  configs: [],
  error: null,
  status: false,
};

const systemConfigsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_CONFIGS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        status: false,
      };

    case GET_ALL_CONFIGS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: false,
        configs: payload,
      };

    case GET_ALL_CONFIGS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        status: false,
        error: payload,
      };

    case UPDATE_CONFIG_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        status: false,
      };

    case UPDATE_CONFIG_REQUEST_SUCCESS:
      const itemIndex = state.configs.findIndex(
        (item) => item.id === payload.id
      );
      if (itemIndex !== -1) {
        state.configs[itemIndex] = payload;
      }
      return {
        ...state,
        loading: false,
        status: true,
        configs: [...state.configs],
      };

    case UPDATE_CONFIG_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        status: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default systemConfigsReducer;

import * as actionType from "../actionType";
import  moment  from 'moment';

const initialState = {
  laoding: false,
  users: [],
  message: null,
  error: null,
  statusKey: "all",
  searchKey: "",
  createdByKey: "",
  paginate: null,
  paging: [],
  hasNextPage: true,
  currentPage: 1,
  hasPreviousPage: false,
  status: false,
  reviews: [],
  accounts: [],
  balance: 0,
  sortBy: { label: "ASC", value: "ASC" },
  transType: { label: "All", value: "all" },
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(1, 'M').format('YYYY-MM-DD')
  // new Date(new Date().setMonth(new Date().getMonth() + 1)),
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // ALL USERS

    case actionType.GET_ALL_USERS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        message: null,
        errro: null,
      };

    case actionType.GET_ALL_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: payload.users,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        status: false,
      };

    case actionType.GET_ALL_USERS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        message: null,
        errro: payload,
      };

    //   UPDATE STATUS KEY

    case actionType.UPDATE_USERS_STATUS_KEY:
      return {
        ...state,
        statusKey: payload,
      };

    //   UPDATE SEARCH KEY

    case actionType.UPDATE_USERS_SEARCH_KEY:
      return {
        ...state,
        searchKey: payload,
      };

    //   UPDATE CREATED BY KEY

    case actionType.UPDATE_USERS_CREATED_BY_KEY:
      return {
        ...state,
        createdByKey: payload,
      };

    // SORT BY KEY
    case actionType.UPDATE_SORT_BY_KEY:
      return {
        ...state,
        sortBy: payload,
      };

    // ADD USER

    case actionType.ADD_USER_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        status: false,
      };

    case actionType.ADD_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, payload],
        status: true,
      };

    case actionType.ADD_USER_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        status: false,
      };

    // EDIT USER

    case actionType.EDIT_USER_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        error: null,
        status: false,
      };

    case actionType.EDIT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: payload,
      };

    case actionType.EDIT_USER_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        status: false,
      };

    //  REVIEWS

    case actionType.USER_REVIEWS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        errro: null,
      };

    case actionType.USER_REVIEWS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reviews: payload.reviews,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        status: false,
      };

    case actionType.USER_REVIEWS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        message: null,
        errro: payload,
      };

    // TRANSACTIONS

    case actionType.USER_TRANSACTIONS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        errro: null,
      };

    case actionType.USER_TRANSACTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        accounts: payload.accounts,
        balance: payload.userBalance,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
      };

    case actionType.USER_TRANSACTIONS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        errro: payload,
      };

    case actionType.UPDATE_TYPE_KEY:
      return {
        ...state,
        transType: payload,
      };

    case actionType.UPDATE_TRANS_START_DATE:
      return {
        ...state,
        startDate: payload,
      };

    case actionType.UPDATE_TRANS_END_DATE:
      return {
        ...state,
        endDate: payload,
      };

    default:
      return state;
  }
};

export default usersReducer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GlobalWrapper from "../../components/GlobalWrapper";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Label,
  FormGroup,
  Modal,
  Form,
} from "reactstrap";
import { alertMsg } from "../../helpers/alertMsg";
import { useDispatch, useSelector } from "react-redux";
import {
  addRideCancelCondition,
  editRideCancelCondition,
  getAllRideCancelConditions,
  setConditionStatusFalse,
} from "../../store/RideCancelCondition/RideCancelConditionAction";
import EditButton from "../../components/EditButton";

const RideCancelConditions = () => {
  const dispatch = useDispatch();
  const { status, conditions, loading } = useSelector(
    (state) => state.RideCancelConditionReducer
  );

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [rideCancelCondition, setRideCancelCondition] = useState({
    minHour: "",
    maxHour: "",
    userCut: "",
    partnerGet: "",
    partnerCut: "",
  });
  const [conditionId, setConditionId] = useState(null);

  //   GET ALL RIDE CANCEL CONDITIONS

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getConditions(true);
    }

    return () => (mounted = false);
  }, []);

  const getConditions = (refresh = false) => {
    dispatch(getAllRideCancelConditions(refresh));
  };

  //   ADD NEW BUTTON CLICK
  const addNewCondition = () => {
    setIsOpenAddModal(!isOpenAddModal);
    setRideCancelCondition({
      minHour: "",
      maxHour: "",
      userCut: "",
      partnerGet: "",
      partnerCut: "",
    });
  };

  //   HANDLE INPUT FIELDS

  const handleCondtionInputs = (e) => {
    const { name, value } = e.target;
    setRideCancelCondition((prev) => ({ ...prev, [name]: value }));
  };

  //   SUBMIT DATA

  const submitRideCondition = (e) => {
    e.preventDefault();

    const { minHour, maxHour, userCut, partnerGet, partnerCut } =
      rideCancelCondition;

    if (!minHour || !maxHour || !userCut || !partnerCut || !partnerGet) {
      return alertMsg("Invalid Request!", "warning");
    }

    if (conditionId) {
      dispatch(editRideCancelCondition(rideCancelCondition, conditionId));
    } else {
      dispatch(addRideCancelCondition(rideCancelCondition));
    }
  };

  //   HANDLE EDIT BUTTON

  const handleEdit = (id) => {
    setConditionId(id);
    const findCondition = conditions.find((item) => item.id == id);
    setRideCancelCondition(findCondition);
    setIsOpenAddModal(true);
  };

  useEffect(() => {
    if (status) {
      setIsOpenAddModal(false);
      setRideCancelCondition({
        minHour: "",
        maxHour: "",
        userCut: "",
        partnerGet: "",
        partnerCut: "",
      });
      setConditionId(null);
    }
    return () => dispatch(setConditionStatusFalse());
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Settings"
              breadcrumbItem="Ride Cancel Conditions"
              hideSettingBtn={true}
              loading={loading}
              callList={getConditions}
              isAddNew={true}
              isModalOpen={addNewCondition}
            />
            <Row>
              <Col xl={12}>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Card>
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <CardTitle
                            style={{ flex: "2" }}
                            className="h4"
                          ></CardTitle>
                        </div>

                        <Table
                          id="tech-companies-1"
                          className="table table-bordered table-hover text-center"
                        >
                          <Thead>
                            <Tr>
                              <Th>Min Hour</Th>
                              <Th>Max Hour</Th>
                              <Th>User Cut(User Cancel)</Th>
                              <Th>Partner Get(User Cancel)</Th>
                              <Th>Partner Cut(Partner Cancel)</Th>
                              <Th>Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {conditions?.length > 0 &&
                              conditions.map(
                                (
                                  {
                                    minHour,
                                    maxHour,
                                    userCut,
                                    partnerCut,
                                    partnerGet,
                                    id,
                                  },
                                  index
                                ) => {
                                  return (
                                    <Tr
                                      key={id}
                                      className="align-middle"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Th style={{ maxWidth: "250px" }}>
                                        {minHour}h
                                      </Th>
                                      <Td>{maxHour}h</Td>
                                      <Td>{userCut}%</Td>
                                      <Td>{partnerGet}%</Td>
                                      <Td>{partnerCut}%</Td>
                                      <Td>
                                        <EditButton
                                          editItem={() => handleEdit(id)}
                                        />
                                      </Td>
                                    </Tr>
                                  );
                                }
                              )}
                          </Tbody>
                        </Table>
                        {conditions?.length < 1 && !loading && (
                          <div className="text-center">
                            <h4>No Data Found!</h4>
                          </div>
                        )}
                        {loading && (
                          <div className="text-center">
                            <Spinner animation="border" color="info" />
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/*MODAL FOR ADD AND EDIT CANCEL REASON  */}
        <Modal
          isOpen={isOpenAddModal}
          toggle={() => {
            setIsOpenAddModal(!isOpenAddModal);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{`${
              conditionId ? "Update" : "Add New"
            }`}</h5>
            <button
              type="button"
              onClick={() => {
                setIsOpenAddModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={submitRideCondition}>
              <div>
                <Row>
                  <h6 className="text-success">Min and Mix Hours</h6>
                  <hr />
                  <Col xl={6}>
                    <div className="mb-3 mb-xl-0">
                      <Label>Min Hours</Label>
                      <Input
                        value={rideCancelCondition?.minHour}
                        onChange={handleCondtionInputs}
                        name="minHour"
                        id="minHour"
                        className=""
                        type="number"
                        placeholder="Enter Min Hours"
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="mb-3 mb-xl-0">
                      <Label>Max Hours</Label>
                      <Input
                        // style={{ border: '1px solid red' }}
                        value={rideCancelCondition?.maxHour}
                        onChange={handleCondtionInputs}
                        name="maxHour"
                        id="maxHours"
                        className=""
                        type="number"
                        placeholder="Enter Max Hours"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <h6 className="text-success">
                    User Cancel Condition (When a User Cancel a Ride)
                  </h6>
                  <hr />
                  <Col xl={6}>
                    <div className="mb-3 mb-xl-0">
                      <Label>User Cut Percentage(%)</Label>
                      <Input
                        value={rideCancelCondition?.userCut}
                        onChange={handleCondtionInputs}
                        name="userCut"
                        id="userCut"
                        className=""
                        type="number"
                        placeholder="Enter User Cut %"
                      />
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="mb-3 mb-xl-0">
                      <Label>Partner Get Percentage(%)</Label>
                      <Input
                        value={rideCancelCondition?.partnerGet}
                        onChange={handleCondtionInputs}
                        name="partnerGet"
                        id="partnerGet"
                        className=""
                        type="number"
                        placeholder="Enter Partner Get %"
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <h6 className="text-success">
                    Partner Cancel Condition (When a Partner Cancel a Ride)
                  </h6>
                  <hr />
                  <Col xl={6}>
                    <div className="mb-3 mb-xl-0">
                      <Label>Partner Cut Percentage(%)</Label>
                      <Input
                        value={rideCancelCondition?.partnerCut}
                        onChange={handleCondtionInputs}
                        name="partnerCut"
                        id="userCut"
                        className=""
                        type="number"
                        placeholder="Enter User Cut %"
                      />
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-4">
                  <Button
                    color="primary"
                    type="submit"
                    className="w-100"
                    disabled={loading}
                  >
                    {loading ? "Saving.." : conditionId ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default RideCancelConditions;

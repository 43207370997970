import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../components/GlobalWrapper";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import SelectOptions from "../../components/SelectOptions";
import {
  PARTNER_POINT_WITHDRAW_TYPE,
  accountOptions,
  userTypesOptions,
} from "../../assets/staticData";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptWithdrawRequest,
  getAllWithdrawRequests,
  updateWithdrawEndDate,
  updateWithdrawStatusKey,
  updateWithdrawUserTypeKey,
  updateWthdrawStartDate,
} from "../../store/Transactions/transactionsAction";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import AppPagination from "../../components/AppPagination";
import { alertMsg } from "../../helpers/alertMsg";
import Lightbox from "react-image-lightbox";
import { useHistory, useLocation } from "react-router-dom";

const WithdrawRequests = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [id, setId] = useState(null);

  const {
    loading,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    withdrawStartDate,
    withdrawUserType,
    withdrawEndDate,
    withdrawRequests,
    status,
    message,
  } = useSelector((state) => state.transactionsReducer);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (withdrawUserType || withdrawStartDate || withdrawEndDate) {
        callWithdrawList(true);
      }
    }
    return () => (mounted = false);
  }, [withdrawUserType, withdrawStartDate, withdrawEndDate]);

  const callWithdrawList = (refresh = false, page = 1) => {
    dispatch(getAllWithdrawRequests(refresh, page));
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (status) {
        alertMsg(message, "success");
        setId(null);
        setIsOpenConfirmModal(false);
      }
    }
    return () => (mounted = false);
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Withdraw Requests"
              loading={loading}
              callList={callWithdrawList}
            />

            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-end h-100 mb-3"></div>
                <Row>
                  <Col md={3}>
                    <SelectOptions
                      label="User Type"
                      name="userType"
                      options={userTypesOptions}
                      value={withdrawUserType}
                      onChange={(e) => dispatch(updateWithdrawUserTypeKey(e))}
                    />
                  </Col>

                  <Col md={6}>
                    <div className="d-flex my-3 my-md-0 ">
                      <div className=" w-100">
                        <label>Start Date</label>
                        <div className="form-group mb-0 w-100">
                          <Flatpickr
                            className="form-control d-block"
                            id="startDate"
                            placeholder="Start Date"
                            value={withdrawStartDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateWthdrawStartDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                      <div className="ms-2 w-100">
                        <label>End Date</label>
                        <div className="form-group mb-0">
                          <Flatpickr
                            className="form-control w-100"
                            id="endDate"
                            placeholder="Select End Date"
                            value={withdrawEndDate}
                            onChange={(selectedDates, dateStr, instance) =>
                              dispatch(updateWithdrawEndDate(dateStr))
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div>
                  <CardTitle className="h4 ms-3 mt-2">
                    Transactions List
                  </CardTitle>

                  <hr className="my-2" />
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Profile</Th>
                        <Th>Wallat Number</Th>
                        <Th>Amount</Th>
                        <Th>Note</Th>
                        <Th>User Type</Th>
                        <Th>Request Date</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {withdrawRequests?.length > 0 &&
                        withdrawRequests?.map((item, index) => {
                          return (
                            <Tr
                              key={index}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th title="See Details">
                                {item?.partnerId ? (
                                  <div
                                    style={{ width: "80px", height: "60px" }}
                                    onClick={() =>
                                      history.push(
                                        `/partner/details/${item?.partner.id}`
                                      )
                                    }
                                  >
                                    <img
                                      loading="lazy"
                                      className="img-fluid cursor-pointer"
                                      alt="Partner"
                                      src={item?.partner?.img}
                                      style={{
                                        width: "100%",
                                        height: "80%",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <p>{item?.partner?.name}</p>
                                  </div>
                                ) : (
                                  <div
                                    style={{ width: "80px", height: "60px" }}
                                    onClick={() =>
                                      history.push(
                                        `/user/details/${item?.user?.id}`
                                      )
                                    }
                                  >
                                    <img
                                      loading="lazy"
                                      className="img-fluid cursor-pointer"
                                      alt="User"
                                      src={item?.user?.img}
                                      style={{
                                        width: "100%",
                                        height: "80%",
                                        objectFit: "contain",
                                      }}
                                    />
                                    <p>{item?.user?.name}</p>
                                  </div>
                                )}
                              </Th>
                              <Td>{item?.paymentReceivedNumber}</Td>
                              <Td>{item?.amount}</Td>
                              <Td style={{ maxWidth: "150px" }}>
                                {item?.message}{" "}
                              </Td>
                              <Td>{item?.partnerId ? "Partner" : "User"} </Td>
                              <Td>
                                {moment(item?.createdAt).format("DD-MM-YYYY")}
                              </Td>
                              <Td>
                                {!item?.paymentMethod ? (
                                  <Button
                                    className="btn btn-success"
                                    onClick={() => {
                                      setIsOpenConfirmModal(
                                        !isOpenConfirmModal
                                      );
                                      setId(item?.id);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                ) : (
                                  "Approved"
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </div>
                {loading && (
                  <Spinner
                    style={{
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                    }}
                    animation="border"
                    variant="success"
                  />
                )}
                {withdrawRequests?.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found!</h3>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => callWithdrawList(true, page)}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>

      {/* CONFIRMATION DIALOG  */}
      {isOpenConfirmModal && (
        <ConfirmDialog
          isOpen={isOpenConfirmModal}
          dispatchFunc={() => dispatch(acceptWithdrawRequest(id))}
          closeDialog={() => setIsOpenConfirmModal(false)}
          title="Approve Withdraw"
          content="Are you sure! You want to approve this withdraw request?"
          buttonName="Yes"
        />
      )}
    </React.Fragment>
  );
};

export default WithdrawRequests;

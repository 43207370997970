import React from "react";
import GlobalWrapper from "../GlobalWrapper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { alertMsg } from "../../helpers/alertMsg";

const ConfirmDialog = ({
  isOpen,
  title,
  content,
  closeDialog,
  dispatchFunc,
  typeId,
  error,
  message,
  buttonName = null,
  loading = false,
}) => {
  const dispatch = useDispatch();

  // const confirmDelete = () => {
  //   dispatch(dispatchFunc(typeId));
  //   closeDialog();
  //   if (message) {
  //     if (message) {
  //       return alertMsg(message, "success");
  //     }
  //     if (error) {
  //       return alertMsg(error, "warning");
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Dialog
            open={isOpen}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button onClick={dispatchFunc} autoFocus>
                {loading ? "Loading.." : buttonName ?? "Delete"}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default ConfirmDialog;

import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "../../../store/Users/UsersAction";
import { selectCouponUser } from "../../../store/Coupon/CouponAction";
import { addCoupon } from "./../../../store/Coupon/CouponAction";
import { alertMsg } from "../../../helpers/alertMsg";

const CouponAdd = () => {
  const dispatch = useDispatch();

  const { selectedUser, status, loading } = useSelector(
    (state) => state.couponReducer
  );

  const { users } = useSelector((state) => state.usersReducer);

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [applyType, setApplyType] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [maxAmount, setMaxAmount] = useState(0);

  //   GENERATE COUPON CODE

  const generateCode = () => {
    if (!name) {
      return alertMsg("Enter Name First", "warning");
    }

    const couponTitle = name.split(" ").join("");
    const generateCode = couponTitle + Math.round(Math.random() * 100);
    setCode(generateCode);
  };

  //   GET USERS

  useEffect(() => {
    dispatch(usersList());
  }, [users]);

  // HANDLE SUBMIT

  const handleSubmit = () => {
    if (!name || amount <= 0 || !applyType || !code || !type) {
      return alertMsg("Please Fillup all Fields", "warning");
    }
    if (startDate >= endDate) {
      return alertMsg("End Date Can't Be Less Then Start Date", "warning");
    }
    if (applyType == "specific" && selectedUser == null) {
      return alertMsg("Please Select User", "warning");
    }
    if (type == "percentage" && !maxAmount) {
      return alertMsg("Please Enter Max Discount Amount", "warning");
    }

    dispatch(
      addCoupon({
        code,
        amount,
        name,
        startDate,
        endDate,
        type,
        maxAmount,
        applyType,
        applyToUser: applyType == "specific" ? selectedUser.id : null,
      })
    );
  };

  // SUCCESS

  useEffect(() => {
    if (status) {
      setCode("");
      setName("");
      setType("");
      setAmount(0);
      setStartDate(new Date());
      setEndDate(new Date());
      setApplyType("");
      setMaxAmount(0);
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Coupon"
              breadcrumbItem="Add"
              isRefresh={false}
            />

            <Card>
              <CardBody>
                <div className="pb-4">
                  <h4> Coupon Informations</h4>
                  <hr />
                </div>

                <Row>
                  <Col xl={6}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Title"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      variant="outlined"
                      placeholder="Enter Coupon Title"
                    />
                  </Col>
                  <Col xl={6} className="d-flex mt-4 mt-xl-0">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Code"
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      variant="outlined"
                      placeholder="Enter discount name"
                    />
                    <Button
                      onClick={generateCode}
                      className="ms-1"
                      color="success"
                    >
                      Generate
                    </Button>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xl={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        label="Type"
                        onChange={(e) => setType(e.target.value)}
                        placeholder="Enter Discount Type"
                        defaultValue={""}
                      >
                        <MenuItem value="amount">Amount</MenuItem>
                        <MenuItem value="percentage">Percentage</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  <Col xl={6} className="mt-4 mt-xl-0">
                    <TextField
                      fullWidth
                      disabled={type == ""}
                      id="outlined-basic"
                      label={
                        type == "amount"
                          ? "Amount"
                          : type == "percentage"
                          ? "Percentage"
                          : ""
                      }
                      value={amount}
                      onChange={(event) => setAmount(event.target.value)}
                      variant="outlined"
                      placeholder="Enter Discount Number"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="Start Date&Time"
                        value={startDate}
                        onChange={(event) => setStartDate(event)}
                        type="datetime-local"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xl={6} className="mt-4 mt-xl-0">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        label="End Date&Time"
                        value={endDate}
                        type="datetime-local"
                        onChange={(event) => setEndDate(event)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col xl={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={applyType}
                        label="Age"
                        onChange={(e) => setApplyType(e.target.value)}
                        placeholder="Apply Type"
                        defaultValue={""}
                      >
                        <MenuItem value="global">Global</MenuItem>
                        <MenuItem value="specific">Specific</MenuItem>
                        <MenuItem value="new">New</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                  {applyType == "specific" && (
                    <Col xl={6} className="mt-4 mt-xl-0">
                      <Autocomplete
                        value={selectedUser}
                        onChange={(event, newValue) => {
                          dispatch(selectCouponUser(newValue));
                          // console.log("new",newValue)
                        }}
                        getOptionLabel={(option) => (option ? option.name : "")}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        inputValue={searchUser}
                        onInputChange={(event, newInputValue) => {
                          setSearchUser(newInputValue);
                          // console.log("input value", newInputValue);
                        }}
                        id="controllable-states-demo6"
                        options={users.length > 0 ? users : []}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select User" />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="60"
                              src={option.img}
                              alt=""
                            />
                            {option.name}
                          </Box>
                        )}
                      />
                    </Col>
                  )}
                </Row>
                <Row className="mt-4">
                  <Col xl={6}>
                    {type == "percentage" && (
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Max Amount"
                        type="number"
                        value={maxAmount}
                        onChange={(event) => setMaxAmount(event.target.value)}
                        variant="outlined"
                        placeholder="Enter Max Amount"
                      />
                    )}
                  </Col>
                </Row>

                <div className="d-flex justify-content-center mt-5">
                  <Button
                    color="primary"
                    className="px-5"
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <Spinner
                        color="success"
                        animation="border"
                        size="sm"
                      ></Spinner>
                    ) : (
                      "Add"
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default CouponAdd;

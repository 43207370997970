import { toast } from "react-toastify";
import {
  ADD_DRIVER,
  ALL_DRIVERS,
  EDIT_DRIVER,
  GET_ALL_DRIVERS_BY_PARTNER,
} from "../../network/Api";
import requestApi from "../../network/httpRequest";
import * as actionType from "../actionType";

// GET ALL DRIVERS

export const allDrivers =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    try {
      const { drivers, searchKey, statusKey, currentStatusKey } =
        getState().driverReducer;

      if (drivers.length < 1 || refresh) {
        dispatch({
          type: actionType.GET_ALL_DRIVERS_REQUEST_SEND,
        });

        const { data } = await requestApi().request(ALL_DRIVERS, {
          params: {
            searchKey: searchKey,
            page: page,
            pageSize: 100,
            status: statusKey,
            currentStatus: currentStatusKey,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.GET_ALL_DRIVERS_REQUEST_SUCCESS,
            payload: {
              drivers: data.data.drivers,
              paginate: data.data.paginate,
            },
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_DRIVERS_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_DRIVERS_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// UPDATE STATUS KEY

export const updateStatusKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_STATUS_KEY,
    payload: value,
  });
};

// SEARCH KEY UPDATE

export const updateSearchKey = (value) => (dispatch) => {
  // console.log("search value", value)
  dispatch({
    type: actionType.UPDATE_SEARCH_KEY,
    payload: value,
  });
};

//  UPDATE CREATED BY KEY

export const updateCurrntStatusKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_CURRENT_STATUS_KEY,
    payload: value,
  });
};

// CLEAR SEARCH KEY

export const clearSearchKey = () => (dispatch) => {
  dispatch({
    type: actionType.CLEAR_SEARCH_KEY,
  });
};

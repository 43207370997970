import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import GlobalWrapper from "../../../components/GlobalWrapper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import FormControl from "@mui/material/FormControl";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import InputLabel from "@mui/material/InputLabel";
import Lightbox from "react-image-lightbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  AllRides,
  cancelRideByAdmin,
  extendWaitingTime,
  updateNotificationData,
  updateRideSearchKey,
  updateRideTypeKey,
  updateUserKey,
} from "../../../store/Ride/rideAction";
import AppPagination from "../../../components/AppPagination";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { usersList } from "./../../../store/Users/UsersAction";
import {
  Autocomplete,
  TextField,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Search from "../../../components/Search";
import { alertMsg } from "../../../helpers/alertMsg";
import { set } from "lodash";
import moment from "moment";
import SelectOptionsTwo from "../../../components/SelectOptionsTwo";
import { getAllCancelReasons } from "../../../store/CancelReason/cancelReasonAction";
import AutocompletedInput from "../../../components/AutoCompleteInput";
import { getAllDefaultMessages } from "../../../store/DefaultMessage/defaultMessageAction";
import SentNotification from "../../../components/SentNotification";
import { lte } from "lodash";
import requestApi from "../../../network/httpRequest";
import { SENT_RIDE_NOTIFICAITON } from "../../../network/Api";

const RideList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParam = searchParams.get("status");

  const {
    rides,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    typeKey,
    searchKey,
    loading,
    userFilter,
    status,
  } = useSelector((state) => state.rideReducer);
  const { users } = useSelector((state) => state.usersReducer);
  const { cancelReasons } = useSelector((state) => state.cancelReasonReducer);
  const { messages } = useSelector((state) => state.defaultMessageReducer);

  const [searchUser, setSearchUser] = useState("");
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [extendTime, setExtendTime] = useState(null);
  const [selectedRide, setSelectedRide] = useState(null);
  const [cancelRide, setCancelRide] = useState({
    cancelFor: "",
    chargeApply: "no",
    cancelReason: null,
    rideId: null,
  });

  const [notification, setNotification] = useState({
    sendTo: "",
    title: "",
    message: "",
    rideId: null,
    type: "notificaition",
  });

  const [reasonSearchKey, setReasonSearchKey] = useState("");
  const [messageSearchKey, setMessageSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (searchKey || typeKey || userFilter) {
      callRideList(true);
    }
  }, [typeKey, searchKey, userFilter]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (queryParam) {
        dispatch(updateRideTypeKey(queryParam));
      } else {
        dispatch(updateRideTypeKey("all"));
      }
    }

    return () => (mounted = false);
  }, [queryParam]);

  const callRideList = (refresh = false) => {
    dispatch(AllRides(refresh));
  };

  useEffect(() => {
    dispatch(usersList());
  }, []);

  // CLEAR FILTER

  const clearFilter = () => {
    dispatch(updateRideTypeKey("all"));
    dispatch(updateUserKey(null));
    dispatch(updateRideSearchKey(""));
    //  history.push("/ride/list", { replace: true });
  };

  // HANDLE EXTEND TIME

  // SUBMIT EXTEND TIME

  const submitExtendTime = (e) => {
    e.preventDefault();
    if (!extendTime) {
      return alertMsg("Enter Extend Time", "warning");
    }

    dispatch(
      extendWaitingTime({
        rideId: selectedRide?.id,
        minutes: extendTime,
      })
    );
  };

  //HANDLE CHANGE CANCEL RIDE INPUT

  const handleCancelRideInput = (e) => {
    const { name, value } = e.target;
    setCancelRide((prev) => ({ ...prev, [name]: value }));
  };

  // SUBMIT CANCEL RIDE

  const submitRideCancel = (e) => {
    e.preventDefault();
    const { rideId, cancelReason, cancelFor, chargeApply } = cancelRide;
    if (!selectedRide || !cancelReason || !cancelFor) {
      return alertMsg("Invalid Request!");
    }

    dispatch(
      cancelRideByAdmin({
        rideId: selectedRide.id,
        chargeApply,
        cancelReasonId: cancelReason?.id,
        cancelFor,
      })
    );
  };

  // CLICK NOTIFICATION BUTTON

  function clickNotification(ride) {
    setOpenNotificationModal(!openNotificationModal);
    dispatch(getAllDefaultMessages(true));
    setNotification({
      sendTo: "",
      title: "",
      message: "",
      rideId: ride.id,
      type: "notificaition",
      defaultMessage: null,
    });
  }

  // UPDATE NOTIFICATION INPUTS

  const updateNotificationInputs = (event, data) => {
    const { name, value } = event.target;
    if (name) {
      setNotification((prev) => ({ ...prev, [name]: value }));
    } else {
      setNotification((prev) => ({
        ...prev,
        defaultMessage: data,
        title: data?.title ?? "",
        message: data?.content ?? "",
      }));
    }
  };

  // SENT NOTIFICATION

  const sentRideNotification = async (e) => {
    e.preventDefault();

    const { rideId, sendTo, title, message, type } = notification;

    if (!rideId || !sendTo || !title || !message || !type) {
      return alertMsg("Invalid sent notification data!", "warning");
    }

    setIsLoading(true);

    try {
      const { data } = await requestApi().request(SENT_RIDE_NOTIFICAITON, {
        method: "POST",
        data: {
          rideId,
          sendTo,
          title,
          message,
          type,
        },
      });
      setIsLoading(false);
      if (data.status) {
        alertMsg(data.message, "success");
        setOpenNotificationModal(false);
      } else {
        alertMsg(data.message, "warning");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // SUCCESS STATUS

  useEffect(() => {
    if (status) {
      setExtendTime(null);
      setSelectedRide(null);
      setOpenTimeModal(false);
      setOpenCancelModal(false);
      setCancelRide({
        cancelFor: "",
        chargeApply: "no",
        cancelReason: null,
        rideId: null,
      });
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Quicar"
              breadcrumbItem="List"
              title="Ride"
              loading={loading}
              callList={callRideList}
              isAddNew={true}
              addNewRoute="ride/add"
            />

            {/* FILTER OPTIONS */}

            <Card>
              <CardBody>
                <Row>
                  <Col md={2}>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={typeKey}
                          label="Status"
                          onChange={(event) =>
                            dispatch(updateRideTypeKey(event.target.value))
                          }
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"send"}>Send</MenuItem>
                          <MenuItem value={"accepted"}>Accepted</MenuItem>
                          <MenuItem value={"unaccepted"}>Unaccepted</MenuItem>
                          <MenuItem value={"completed"}>completed</MenuItem>
                          <MenuItem value={"canceled"}>Canceled</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Col>

                  <Col
                    md={6}
                    className="d-flex align-items-center my-3 mt-md-0"
                  >
                    <Search
                      dispatchFunc={updateRideSearchKey}
                      placeholder="Search ride by ride id"
                      value={searchKey}
                    />
                  </Col>

                  <Col md={4}>
                    <Autocomplete
                      clearOnBlur
                      className="cursor-pointer"
                      value={userFilter}
                      onChange={(event, value) => {
                        dispatch(updateUserKey(value));

                        // console.log("new",newValue)
                      }}
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.id == value?.id
                      }
                      inputValue={searchUser}
                      onInputChange={(event, inputValue) => {
                        setSearchUser(inputValue);
                        // console.log("input value", newInputValue);
                      }}
                      id="controllable-states"
                      options={users.length > 0 ? users : []}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select User" />
                      )}
                      renderOption={(props, option, index) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.id}
                        >
                          <div
                            style={{
                              width: "40px",
                              height: "60px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              loading="lazy"
                              style={{ width: "100%", height: "100%" }}
                              src={option.img}
                              alt=""
                            />
                          </div>
                          {option.name}
                        </Box>
                      )}
                    />
                  </Col>
                </Row>
                {/* <Row className="mt-3"></Row>
                {(userFilter || typeKey != "all" || searchKey) && (
                  <Button onClick={() => clearFilter()} color="danger">
                    Clear Filter
                  </Button>
                )} */}
              </CardBody>
            </Card>

            {/* RIDE LIST */}

            <TableWrapper>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={3} className="text-end" />
                  </Row>
                  <CardTitle className="h4"> Ride List</CardTitle>
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Booking Id</Th>
                        <Th>Pickup(Place/Time) </Th>
                        <Th>Drop Off(Place/Time) </Th>
                        <Th>Bids</Th>
                        <Th>User</Th>
                        <Th>Visible Time</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {rides.length > 0 &&
                        rides.map((ride, index) => {
                          return (
                            <Tr
                              key={index}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th>{ride?.bookingId}</Th>

                              <Td className="address">
                                <div>
                                  <p>{ride?.pickupLocation?.address}</p>
                                  <span>
                                    <mark>{ride?.pickUpTimeText}</mark>
                                  </span>
                                </div>
                              </Td>

                              <Td className="address">
                                <div>
                                  <p>{ride?.dropOffLocation?.address}</p>
                                  {/* <span>{ride.returnTimeText}</span> */}
                                </div>
                              </Td>
                              <Td>
                                <div>
                                  <p>{ride?.biddings?.length}</p>
                                  {/* <span>{ride.returnTimeText}</span> */}
                                </div>
                              </Td>
                              <Td>
                                <div>
                                  <p>{ride?.user?.name}</p>
                                  <span>{ride?.user?.phoneNumber}</span>
                                </div>
                              </Td>
                              <Td>
                                {new Date(ride?.onBiddingTime).toLocaleString()}
                              </Td>
                              <Td className="text-capitalize">
                                {ride?.status === "send" &&
                                new Date(ride?.onBiddingTime) < new Date()
                                  ? "Unaccepted"
                                  : `${ride?.status} 
                                      ${
                                        ride?.status === "canceled" &&
                                        `(By ${ride?.ride_cancels[0]?.cancelBy})`
                                      }`}
                              </Td>
                              <Td>
                                <div>
                                  <Tooltip title="Details">
                                    <button
                                      className="btn btn-success button"
                                      onClick={() =>
                                        history.push(
                                          `/ride/details/${ride?.id}`
                                        )
                                      }
                                    >
                                      <i className="fa fa-eye" />
                                    </button>
                                  </Tooltip>
                                  {ride.status == "send" ? (
                                    <Tooltip title="Extend Waiting Time">
                                      <button
                                        className="btn btn-info button "
                                        onClick={() => {
                                          setOpenTimeModal(!openTimeModal);
                                          setSelectedRide(ride);
                                        }}
                                      >
                                        <i className="fa fa-clock"></i>
                                      </button>
                                    </Tooltip>
                                  ) : null}

                                  {["send", "accepted"].includes(
                                    ride?.status
                                  ) ? (
                                    <Tooltip title="Cancel Ride">
                                      <button
                                        className="btn btn-danger button "
                                        onClick={() => {
                                          setOpenCancelModal(!openCancelModal);
                                          setSelectedRide(ride);
                                          dispatch(
                                            getAllCancelReasons(true, "ride")
                                          );
                                        }}
                                      >
                                        <i className="fa fa-window-close"></i>
                                      </button>
                                    </Tooltip>
                                  ) : null}

                                  <Tooltip title="Notification">
                                    <button
                                      className="btn btn-info button"
                                      onClick={() => clickNotification(ride)}
                                    >
                                      <i className="fa fa-bell"></i>
                                    </button>
                                  </Tooltip>
                                </div>
                              </Td>
                            </Tr>
                          );
                        })}
                      {loading && (
                        <Tr>
                          <Td>
                            <Spinner
                              style={{
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                              }}
                              animation="border"
                              color="warning"
                            />
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  {rides.length < 1 && !loading && (
                    <div className="text-center">
                      <h3>No Data Found</h3>
                    </div>
                  )}
                </CardBody>
              </Card>
            </TableWrapper>
            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(AllRides(true, page))}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/*MODAL FOR RIDE EXTEND TIME  */}

        <Modal
          isOpen={openTimeModal}
          toggle={() => setOpenTimeModal(!openTimeModal)}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Extend Bidding Time</h5>
            <button
              type="button"
              onClick={() => {
                setOpenTimeModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={submitExtendTime}>
              <div>
                <div className="mb-3">
                  <Label>Title</Label>
                  <Input
                    // style={{ border: '1px solid red' }}
                    // value={messageItem?.title}
                    onChange={(e) => setExtendTime(e.target.value)}
                    name="extendTime"
                    className=""
                    type="number"
                    placeholder="Enter Minutes"
                    min={1}
                  />
                </div>

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    className="px-4"
                    disabled={loading}
                  >
                    {loading ? "Saving.." : "Extend"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>

        {/* MODAL FOR CANCEL RIDE */}

        <Modal
          isOpen={openCancelModal}
          toggle={() => setOpenCancelModal(!openCancelModal)}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Cancel Ride (Booking Id : {selectedRide?.bookingId})
            </h5>
            <button
              type="button"
              onClick={() => {
                setOpenCancelModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={submitRideCancel}>
              <div className="cancel_ride_wrapper">
                <div className="mb-3">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Cancel For
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="cancelFor"
                      onChange={handleCancelRideInput}
                      value={cancelRide?.cancelFor}
                      required
                    >
                      <FormControlLabel
                        value="user"
                        control={<Radio />}
                        label="User"
                      />
                      <FormControlLabel
                        value="partner"
                        control={<Radio />}
                        label="Partner"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="mb-3">
                  <SelectOptionsTwo
                    onChange={handleCancelRideInput}
                    name="chargeApply"
                    label="Charge Apply"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                    value={cancelRide?.chargeApply}
                  />
                </div>
                <div className="mb-3">
                  <AutocompletedInput
                    value={cancelRide?.cancelReason}
                    onChange={(event, value) =>
                      setCancelRide((prev) => ({
                        ...prev,
                        cancelReason: value,
                      }))
                    }
                    searchKey={reasonSearchKey}
                    onInputChange={(event, inputValue) =>
                      setReasonSearchKey(inputValue)
                    }
                    list={cancelReasons}
                    name="cancelReason"
                    label="Cancel Reason"
                    required={true}
                  />
                </div>

                <Button
                  type="submit"
                  className="btn-warning"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* MODAL FOR SENT NOTIFICATION */}

        <Modal
          isOpen={openNotificationModal}
          toggle={() => setOpenNotificationModal(!openNotificationModal)}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Sent Notification</h5>
            <button
              type="button"
              onClick={() => {
                setOpenNotificationModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SentNotification
              updateData={updateNotificationInputs}
              searchKey={messageSearchKey}
              messages={messages}
              notification={notification}
              loading={isLoading}
              setSearchKey={(event, inputValue) =>
                setMessageSearchKey(inputValue)
              }
              submitData={sentRideNotification}
            />
          </div>
        </Modal>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid #c9bfbf;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

const TableWrapper = styled.div`
  .address {
    width: 250px;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
`;

export default RideList;

import React, { useCallback, useEffect, useState, useMemo } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import styled from "styled-components";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useDispatch, useSelector } from "react-redux";
import {
  carSearchKeyUpdate,
  getAllCars,
  setCarStatusFalse,
  updateStatusKey,
} from "../../../store/Car/carAction";
import Lightbox from "react-image-lightbox";
import AppPagination from "../../../components/AppPagination";
import { useHistory, useLocation } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import Search from "../../../components/Search";
import SelectOptionsTwo from "../../../components/SelectOptionsTwo";
import { statusFilterOptionsNew } from "../../../assets/staticData";

const CarList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParam = searchParams.get("status");

  const {
    loading,
    cars,
    paging,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    carSearchKey,
    statusKey,
  } = useSelector((state) => state.carReducer);

  const [isZoom, setIsZoom] = useState(false);
  const [carImageGalley, setCarImageGallery] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (queryParam) {
        dispatch(updateStatusKey(queryParam));
      } else {
        dispatch(updateStatusKey("all"));
      }
    }

    return () => (mounted = false);
  }, [queryParam]);

  useEffect(() => {
    // callCarList()
    let mounted = true;
    if (mounted) {
      dispatch(setCarStatusFalse());
      callCarList(true);
    }
    return () => (mounted = false);
  }, [carSearchKey, statusKey]);

  const callCarList = (refresh = false) => {
    dispatch(getAllCars(refresh));
  };

  const showImageGallery = (images) => {
    setCarImageGallery(images);
    setIsZoom(true);
  };

  // EDIT CAR EVENT

  const editCar = (carId, id) => {
    history.push({
      pathname: `/car/edit/${carId}`,
      search: `?pID=${id}`,
      // state: { detail: 'some_value' }
    });
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Car"
              breadcrumbItem="List"
              loading={loading}
              callList={callCarList}
              isAddNew={true}
              addNewRoute="car/add"
            />

            {isZoom ? (
              <Lightbox
                // closeLabel={"close button"}
                // closeButtonAriaLabel={"close button"}
                mainSrc={carImageGalley[photoIndex]}
                nextSrc={
                  carImageGalley[(photoIndex + 1) % carImageGalley.length]
                }
                prevSrc={
                  carImageGalley[
                    (photoIndex + carImageGalley.length - 1) %
                      carImageGalley.length
                  ]
                }
                onCloseRequest={() => setIsZoom(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + carImageGalley.length - 1) %
                      carImageGalley.length
                  )
                }
                onMoveNextRequest={
                  () => setPhotoIndex((photoIndex + 1) % carImageGalley.length)
                  // setPhotoIndex({
                  //   photoIndex: (photoIndex + 1) % carImageGalley.length,
                  // })
                }
              />
            ) : null}

            {/* Filter */}
            <Card>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <SelectOptionsTwo
                      label="Status"
                      options={statusFilterOptionsNew}
                      value={statusKey}
                      name="status"
                      required
                      onChange={(event) =>
                        dispatch(updateStatusKey(event.target.value))
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <Search
                      dispatchFunc={carSearchKeyUpdate}
                      placeholder="Search"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* Table */}

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Cars List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Image</Th>
                      <Th>Parnter Name</Th>
                      <Th>Car Type</Th>
                      <Th>Model</Th>
                      <Th>Registration No</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {cars?.map((car, index) => {
                      return (
                        <Tr
                          key={index}
                          className="align-middle"
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          <Th>
                            <div style={{ width: "50px", height: "50px" }}>
                              <img
                                onClick={() => {
                                  showImageGallery([
                                    car.carFrontImage,
                                    car.carBackImage,
                                    car.carInnerImage,
                                  ]);
                                }}
                                className="img-fluid cursor-pointer"
                                alt=""
                                src={car?.carFrontImage}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </Th>

                          <Td>{car?.partner?.name}</Td>
                          <Td>{car?.car_type?.name}</Td>
                          <Td>{car?.car_brand?.name}</Td>
                          <Td>{car?.carRegisterNumber}</Td>
                          <Td className="text-capitalize">{car?.status}</Td>
                          <Td>
                            <div>
                              <Tooltip title="Edit">
                                <button
                                  className="btn btn-info me-3 button"
                                  onClick={() =>
                                    editCar(car.id, car.partner.id)
                                  }
                                >
                                  <i className="fa fa-edit" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Details">
                                <button
                                  className="btn btn-success button"
                                  onClick={() =>
                                    history.push(`/car/details/${car.id}`)
                                  }
                                >
                                  <i className="fa fa-eye" />
                                </button>
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
                {!loading && cars?.length < 1 && (
                  <div className="text-center">
                    <h3>No Data!</h3>
                  </div>
                )}
                {loading && (
                  <Spinner
                    style={{ position: "fixed", left: "50%", top: "50%" }}
                    animation="border"
                    variant="info"
                  />
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(getAllCars(true, page))}
                  />
                  {/* <h2>Paginate</h2> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 50%;
  border: 1px solid #d3d1d1;
  border-radius: 6px;

  @media (max-width: 800px) {
    width: 100%;
  }

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

export default CarList;

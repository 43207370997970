import React, { useState } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import styled from "styled-components";
import GlobalWrapper from "../../components/GlobalWrapper";
import Breadcrumb from "../../components/Common/Breadcrumb";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-json";
import { useDispatch } from "react-redux";
import { addPolicy } from "../../store/Policy/policyAction";
import { alertMsg } from "../../helpers/alertMsg";
import requestApi from "../../network/httpRequest";
import { GET_SINGLE_POLICY } from "../../network/Api";

const ContactUs = () => {
  const dispatch = useDispatch();

  const [contactUs, setContactUs] = useState(null);

  useState(() => {
    let mounted = true;

    if (mounted) {
      const callApi = async () => {
        try {
          // console.log("call api--------", type);
          const { data } = await requestApi().request(GET_SINGLE_POLICY, {
            params: {
              type: "contactUs",
            },
          });

          if (!data?.status) {
            throw new Error(data?.message);
          }

          const {
            policies: { contactUs },
          } = data.data;
          setContactUs(contactUs);
        } catch (error) {
          console.log(error);
        }
      };
      callApi();
    }

    return () => (mounted = false);
  }, []);

  const changeContactUs = (value) => {
    setContactUs(value);
  };

  const submitData = () => {
    if (!Boolean(contactUs)) {
      return alertMsg("Invalid Data!", "warning");
    }

    const data = {
      type: "contactUs",
      value: contactUs,
    };

    dispatch(addPolicy(data));
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Contact Us"
              isRefresh={false}
            />

            <Row>
              <Col md={12}>
                <AceEditor
                  placeholder="Placeholder Text"
                  mode="json"
                  theme="monokai"
                  name="blah2"
                  width="100%"
                  value={contactUs}
                  onChange={changeContactUs}
                  fontSize={16}
                  showPrintMargin={true}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                  style={{ height: "300px" }}
                />
              </Col>
            </Row>
            <Button className="btn btn-success mt-5" onClick={submitData}>
              Submit
            </Button>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default ContactUs;

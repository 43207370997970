import * as actionType from "../actionType";

const initialState = {
  loading: false,
  discounts: [],
  error: null,
  status: false,
  searchKey: "",
  statusKey: "all",
  appliedKey: "all",
  paginate: null,
  paging: [],
  hasNextPage: true,
  currentPage: 1,
  hasPreviousPage: false,
  selectedUser: null
};

const discountReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {

    case actionType.ADD_DISCOUNT_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case actionType.ADD_DISCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        discounts: [...state.discounts, payload],
        error: null,
      };

    case actionType.ADD_DISCOUNT_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    //   GET ALL

    case actionType.GET_ALL_DISCOUNT_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_ALL_DISCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        discounts: payload.discounts,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        status: false,
      };

    case actionType.GET_ALL_DISCOUNT_REQUEST_FAIL:
      return {
        ...state,
        error: payload,
        status: false,
        loading: false
      };

      case actionType.SELECT_USER_FOR_DISCOUNT:
      return {
        ...state,
        loading: false,
        selectedUser: payload
      };


    //   FILTER KEY 

    case actionType.UPDATE_SEARCH_KEY:
      return {
        ...state,
        loading: false,
        searchKey: payload
      };

    case actionType.UPDATE_STATUS_KEY:
      return {
        ...state,
        loading: false,
        statusKey: payload
      };

      case actionType.UPDATE_APPLIED_KEY:
        return {
          ...state,
          loading: false,
          appliedKey: payload
        };

    default:
      return state;
  }
};


export default discountReducer;

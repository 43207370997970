import { toast } from "react-toastify";
import {
  ADD_DRIVER,
  ADD_PARTNER,
  ALL_PARTNER,
  EDIT_DRIVER,
  EDIT_PARTNER,
  GET_ALL_DRIVERS_BY_PARTNER,
  ADD_CAR,
  EDIT_CAR,
  GET_BIDDINGS_BY_PARTNER,
  PARTNER_TRANSACTIONS,
  PARTNER_REVIEWS,
  PARTNER_STATUS_CHANGE,
} from "../../network/Api";
import requestApi from "../../network/httpRequest";
import * as actionType from "../actionType";
import partnerReducer from "./partnerReducers";

export const addPartner = (partner) => async (dispatch) => {
  // console.log("before add",partner);
  try {
    dispatch({
      type: actionType.ADD_PARTNER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_PARTNER, {
      method: "POST",
      data: partner,
    });
    // console.log("response",data);
    if (data.status) {
      toast.success(data.message, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: actionType.ADD_PARTNER_REQUEST_SUCCESS,
        payload: {
          partner: data.data.partner,
          message: data.message,
        },
      });

      dispatch({
        type: actionType.SET_STATUS_FALSE,
      });
    } else {
      toast.warn(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: actionType.ADD_PARTNER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_PARTNER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL PARTNER REQUEST

export const getPartners =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    // console.log("searchKey",searchKey);
    try {
      const { partners, searchKey, statusKey, createdByKey } =
        getState().partnerReducer;
      // console.log("searchKey",searchKey);
      if (partners.length <= 0 || refresh) {
        dispatch({
          type: actionType.GET_ALL_PARTNER_REQUEST_SEND,
        });

        const { data } = await requestApi().request(ALL_PARTNER, {
          params: {
            searchKey: searchKey,
            page: page,
            pageSize: 100,
            status: statusKey,
            createdBy: createdByKey,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.GET_ALL_PARTNER_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_PARTNER_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_PARTNER_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// EDIT PARTNER

export const editPartner = (partnerData) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.EDIT_PARTNER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(EDIT_PARTNER, {
      method: "POST",
      data: partnerData,
    });

    if (data.status) {
      toast.success(data.message, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (data.data.partner) {
        dispatch({
          type: actionType.EDIT_PARTNER_REQUEST_SUCCESS,
          payload: { message: data.message, partner: data.data.partner },
        });
      }
    } else {
      toast.success(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: actionType.EDIT_PARTNER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch(
      dispatch({
        type: actionType.EDIT_PARTNER_REQUEST_FAIL,
        payload: error.message,
      })
    );
  }
};

// ADD DRIVER BY PARTNER

export const addDriver = (driver) => async (dispatch) => {
  // console.log("driver info", driver);
  // console.log("before add",partner);
  try {
    dispatch({
      type: actionType.ADD_DRIVER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_DRIVER, {
      method: "POST",
      data: driver,
    });
    // console.log("|===================", data);
    // console.log("response", data);
    if (data.status) {
      toast.success(data.message, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: actionType.ADD_DRIVER_REQUEST_SUCCESS,
        payload: data.data.driver,
      });
      dispatch({
        type: actionType.SET_STATUS_FALSE,
      });
    } else {
      toast.warn(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: actionType.ADD_DRIVER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_DRIVER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// EDIT DRIVER

export const editDriver = (updateData) => async (dispatch) => {
  // console.log("props", updateData);
  try {
    dispatch({
      type: actionType.EDIT_DRIVER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(EDIT_DRIVER, {
      method: "POST",
      data: updateData,
    });
    // console.log("response", data);
    if (data.status) {
      toast.success(data.message, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: actionType.EDIT_DRIVER_REQUEST_SUCCESS,
        payload: data.data.driver,
      });

      dispatch({
        type: actionType.SET_STATUS_FALSE,
      });
    } else {
      toast.warn(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: actionType.EDIT_DRIVER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.EDIT_DRIVER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL DRIVER BY PARTNER

export const getAllDriversByPartner = (partnerId) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(
      GET_ALL_DRIVERS_BY_PARTNER + partnerId
    );
    // console.log("response", data);
    if (data.status) {
      dispatch({
        type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SUCCESS,
        payload: data.data.drivers,
      });
    } else {
      dispatch({
        type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// GET ALL BIDDDING BY PARTNER

export const getBiddingByPartner =
  (refresh = false, id) =>
  async (dispatch, getState) => {
    try {
      const { biddings, biddingType } = getState().partnerReducer;

      if (biddings.length < 1 || refresh) {
        dispatch({
          type: actionType.GET_BIDDING_BY_PARTNER_REQUEST_SEND,
        });

        const { data } = await requestApi().request(GET_BIDDINGS_BY_PARTNER, {
          params: {
            partnerId: id,
            type: biddingType,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.GET_BIDDING_BY_PARTNER_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.GET_BIDDING_BY_PARTNER_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_BIDDING_BY_PARTNER_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// TRANSACTIONS

export const partnerTransactions =
  (refresh = false, id, page = 1) =>
  async (dispatch, getState) => {
    // console.log({id})
    try {
      const { accounts, transType, startDate, endDate, sortBy } =
        getState().usersReducer;

      if (accounts.length < 1 || refresh) {
        dispatch({
          type: actionType.PARTNER_TRANSACTIONS_REQUEST_SEND,
        });

        const { data } = await requestApi().request(PARTNER_TRANSACTIONS, {
          params: {
            page,
            pageSize: 100,
            pagingRange: 5,
            partnerId: id,
            type: transType.value,
            startDate,
            endDate,
            sortBy: sortBy.value,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.PARTNER_TRANSACTIONS_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.PARTNER_TRANSACTIONS_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.USER_TRANSACTIONS_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// REIVIEW

export const partnerReviews =
  (refresh = false, id, page = 1) =>
  async (dispatch, getState) => {
    // console.log({id})
    try {
      const { reviews } = getState().partnerReducer;

      if (reviews.length < 1 || refresh) {
        dispatch({
          type: actionType.PARTNER_REVIEWS_REQUEST_SEND,
        });

        const { data } = await requestApi().request(PARTNER_REVIEWS, {
          params: {
            page,
            pageSize: 100,
            pagingRange: 5,
            partnerId: id,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.PARTNER_REVIEWS_REQUEST_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: actionType.USER_REVIEWS_REQUEST_FAIL,
            payload: data.message,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.PARTNER_REVIEWS_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// TRANSACTIONS TYPE KEY

export const updatePartnerTransTypeKey = (status) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TYPE_KEY,
    payload: status,
  });
};

// PARTNER BIDDING TYPE

export const partnerBiddingType = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_BIDDING_TYPE_KEY,
    payload: value,
  });
};

// SEARCH KEY UPDATE

export const updateSearchKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_SEARCH_KEY,
    payload: value,
  });
};

// UPDATE STATUS KEY

export const updateStatusKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_STATUS_KEY,
    payload: value,
  });
};

// UPDATE CREATED BY KEY

export const updateCreatedByKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_CREATED_BY_KEY,
    payload: value,
  });
};

// SELECT CAR TYPE

export const selectCarType = (selectedType) => (dispatch) => {
  // console.log("selected car type", selectedType);
  dispatch({
    type: actionType.SELECT_CAR_TYPE,
    payload: selectedType,
  });
};

// SELECT CAR BRAND

export const selectCarBrand = (selectedBrand) => (dispatch) => {
  // console.log("selected car brand", selectedBrand);
  dispatch({
    type: actionType.SELECT_CAR_BRAND,
    payload: selectedBrand,
  });
};

// SELECT CAR BRAND MODEL

export const selectCarBrandModel = (selectedModel) => (dispatch) => {
  // console.log("selected car brand model", selectedModel);
  dispatch({
    type: actionType.SELECT_CAR_BRAND_MODEL,
    payload: selectedModel,
  });
};

// SELECT MODEL COLOR

export const selectModelColor = (selectedColor) => (dispatch) => {
  // console.log("selected car brand model", selectedColor);
  dispatch({
    type: actionType.SELECT_CAR_MODEL_COLOR,
    payload: selectedColor,
  });
};

// SELECT MODEL YEAR

export const selectModelYear = (selectedYear) => (dispatch) => {
  // console.log("selected car brand model", selectedYear);
  dispatch({
    type: actionType.SELECT_CAR_MODEL_YEAR,
    payload: selectedYear,
  });
};

// SELECT CAR FUEL TYPE

export const selectCarFuel = (selectedFuel) => (dispatch) => {
  // console.log("selected car brand model", selectedYear);
  dispatch({
    type: actionType.SELECT_CAR_FUEL_TYPE,
    payload: selectedFuel,
  });
};

// ADD NEW CAR FOR PARTNER

export const addCar = (carData) => async (dispatch) => {
  // console.log({carData})
  try {
    dispatch({
      type: actionType.ADD_CAR_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_CAR, {
      method: "POST",
      data: carData,
    });

    if (data.status) {
      toast.success("Car Added SuccessFully", {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        dispatch({
          type: actionType.ADD_CAR_REQUEST_SUCCESS,
          payload: data.data.car,
        });
      }, 400);
    } else {
      toast.warn(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: actionType.ADD_CAR_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_CAR_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// EDIT CAR

export const editCar = (value) => async (dispatch) => {
  // console.log("values--", value);

  try {
    dispatch({
      type: actionType.EDIT_CAR_REQUEST_SEND,
    });

    const { data } = await requestApi().request(EDIT_CAR, {
      method: "POST",
      data: value,
    });

    // console.log("car edit response", data)

    if (data.status) {
      toast.success("Car Update Successfully", {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        dispatch({
          type: actionType.EDIT_CAR_REQUEST_SUCCESS,
          payload: data.data.car,
        });
      }, 500);
    } else {
      toast.warn(data.error, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: actionType.EDIT_CAR_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    toast.warn(error.message, {
      // position: "bottom-right",
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    dispatch({
      type: actionType.EDIT_CAR_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// ALL CARS BY PARTNER

export const getAllCarsByPartner = (partnerId) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.GET_ALL_CARS_BY_PARTNER_REQUEST_SEND,
    });

    const { data } = await requestApi().request(
      GET_ALL_DRIVERS_BY_PARTNER + partnerId
    );
    // console.log("response", data);
    if (data.status) {
      dispatch({
        type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_SUCCESS,
        payload: data.data.drivers,
      });
    } else {
      dispatch({
        type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.GET_ALL_DRIVERS_BY_PARTNER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// SET STATUS FALSE

export const setStatusFalse = () => (dispatch) => {
  dispatch({
    type: actionType.SET_STATUS_FALSE,
  });
};

export const updatePartnerTransSortByKey = (type) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_SORT_BY_KEY,
    payload: type,
  });
};

export const updatePartnerTransStartDate = (startDate) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TRANS_START_DATE,
    payload: startDate,
  });
};

export const updateParnterTransEndDate = (date) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_TRANS_END_DATE,
    payload: date,
  });
};

import { alertMsg } from "../../helpers/alertMsg";
import { GET_ALL_CONFIGS, UPDATE_CONFIG } from "../../network/Api";
import requestApi from "../../network/httpRequest";
import {
  GET_ALL_CONFIGS_REQUEST_FAIL,
  GET_ALL_CONFIGS_REQUEST_SEND,
  GET_ALL_CONFIGS_REQUEST_SUCCESS,
  UPDATE_CONFIG_REQUEST_FAIL,
  UPDATE_CONFIG_REQUEST_SEND,
  UPDATE_CONFIG_REQUEST_SUCCESS,
} from "../actionType";

export const getAllConfigs =
  (refresh = false) =>
  async (dispatch) => {
    try {
      if (refresh) {
        dispatch({
          type: GET_ALL_CONFIGS_REQUEST_SEND,
        });

        const { data } = await requestApi().request(GET_ALL_CONFIGS);

        if (data.status) {
          const { configs } = data.data;
          dispatch({
            type: GET_ALL_CONFIGS_REQUEST_SUCCESS,
            payload: configs,
          });
        } else {
          dispatch({
            type: GET_ALL_CONFIGS_REQUEST_FAIL,
            paylaod: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_CONFIGS_REQUEST_FAIL,
        paylaod: error.message,
      });
    }
  };

export const updateConfig = (values) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CONFIG_REQUEST_SEND,
    });

    const { data } = await requestApi().request(UPDATE_CONFIG, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      const { config } = data.data;

      dispatch({
        type: UPDATE_CONFIG_REQUEST_SUCCESS,
        payload: config,
      });
      alertMsg(data.message, "success");
    } else {
      dispatch({
        type: UPDATE_CONFIG_REQUEST_FAIL,
        paylaod: data.error,
      });
      alertMsg(data.error, "warning");
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CONFIG_REQUEST_FAIL,
      paylaod: error.message,
    });
  }
};

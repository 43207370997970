import React from "react";

const EditButton = ({ editItem }) => {
  return (
    <>
      <button className="btn btn-info button" onClick={editItem}>
        <i className="fa fa-edit" />
      </button>
    </>
  );
};

export default EditButton;

import React, { useEffect } from "react";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { partnerBiddingType } from "../../../store/partner/partnerActions";
import { getBiddingByPartner } from "./../../../store/partner/partnerActions";
import AppPagination from "../../../components/AppPagination";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import styled from "styled-components";

const PartnerBidding = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loading,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
    biddings,
    biddingType,
  } = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    if (id) {
      if (biddingType) {
        callBiddingList(true, id);
      }
    } else {
      history.push("/partner/list", { replace: true });
    }
  }, [id, biddingType]);

  const callBiddingList = (refresh = false, partnerId) => {
    dispatch(getBiddingByPartner(refresh, partnerId));
  };

  // BIDDING DETAILS ROUTE

  const biddingDetails = (bidId, user) => {
    history.push({
      pathname: `/ride/bidding/details/${bidId}`,
      state: { user: user },
    });
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Drop"
              breadcrumbItem="Bidding's"
              title="Partner"
              // loading={loading}
              // callList={callPartnerList}
            />

            <Card>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={biddingType}
                          label="Type"
                          onChange={(event) =>
                            dispatch(partnerBiddingType(event.target.value))
                          }
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"waiting"}>Waiting</MenuItem>
                          <MenuItem value={"unAccepted"}>Unaccepted</MenuItem>
                          <MenuItem value={"upComingRide"}>
                            Upcoming Ride
                          </MenuItem>
                          <MenuItem value={"completed"}>Completed</MenuItem>
                          <MenuItem value={"canceled"}>Canceled</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Partner List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Car</Th>
                      <Th>Driver</Th>
                      <Th>Bidding Address</Th>
                      <Th>User</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {biddings.length > 0 &&
                      biddings.map((item, index) => {
                        return (
                          <Tr
                            key={index}
                            className="align-middle"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            <Th>
                              <div>
                                <img
                                  src={item?.car?.car_type?.image}
                                  className="avatar-xs rounded-circle me-2"
                                  alt="Partner"
                                />
                                <span>{item?.car?.car_type?.name}</span>
                              </div>
                            </Th>
                            <Td>
                              <div>
                                <img
                                  src={item?.driver.img}
                                  className="avatar-xs rounded-circle me-2"
                                  alt="Driver"
                                />
                                <span>{item?.driver?.name}</span>
                              </div>
                            </Td>
                            <Td>
                              <AddressWrapper>
                                <p className='address'>{item?.bidding_address?.address ?? "N/A"}</p>
                                {/* <span>{ride.returnTimeText}</span> */}
                              </AddressWrapper>
                            </Td>
                            <Td>
                              <div>
                                <img
                                  src={item?.ride?.user?.img}
                                  className="avatar-xs rounded-circle me-2"
                                  alt="Driver"
                                />
                                <span>{item?.ride?.user?.name}</span>
                              </div>
                            </Td>
                            <Td style={{ color:item?.status == 'accepted' ? "green" : item?.status == 'canceled' ? "red" : item?.status == 'waiting' ?  "purple" : "black"}}>{item?.status ?? "N/A"}
                            
                            </Td>
                            <Td>
                              <button
                                className="btn btn-success button"
                               
                                    onClick={() => biddingDetails(item.id,item?.ride?.user)}
                                
                              >
                                <i className="fa fa-eye" />
                              </button>
                            </Td>
                          </Tr>
                        );
                      })}
                    {loading && (
                      <Tr>
                        <Td>
                          <Spinner
                            style={{
                              position: "fixed",
                              left: "50%",
                              top: "50%",
                            }}
                            animation="border"
                            variant="success"
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {!loading && biddings.length < 1 && (
                  <div className="text-center">
                    <h3>No Data Found</h3>
                  </div>
                )}
              </CardBody>
            </Card>
            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) =>
                      dispatch(callBiddingList(true, id, page))
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const AddressWrapper = styled.div`
  .address {
    width: 270px;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
`;

export default PartnerBidding;

import React from "react";
import ReactApexChart from "react-apexcharts";

const CompleteMonthEarningChart = ({months = []}) => {
    const seriesData = months?.map((item) => item.income);

  const state = {
    options: {
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
          },
        },
      },
      colors: ["#626ed4", "#02a499", "#f8b425"],
    },
    series: seriesData,
    labels: [1, 2, 3],
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="donut"
        height="220"
      />
    </React.Fragment>
  );
};

export default CompleteMonthEarningChart;

import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";
import EmailCompose from "../pages/Email/email-compose";

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert";
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic";
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Icons
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import TypiconsIcon from "../pages/Icons/IconTypicons";
import IconIon from "../pages/Icons/IconIon";
import ThemifyIcon from "../pages/Icons/IconThemify";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FileUpload from "../pages/Forms/FileUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesInvoice from "../pages/Utility/PagesInvoice";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesGallery from "../pages/Utility/PagesGallery";
import PagesDirectory from "../pages/Utility/PagesDirectory";

// Gallery
import ImageGallery from "../pages/gallery/ImageGallery";
import ImageFolder from "../pages/gallery/ImageFolder";
import BannerPage from "../pages/banner/BannerPage";
import AddBanner from "../pages/banner/AddBanner";
import Texteditor from "../pages/banner/Texteditor";

// User List

import UsersList from "../pages/Users/UsersList/UsersList";
import PartnersList from "../pages/Partners/PartnersList/PartnersList";
import CarTypes from "../pages/Car/CarTypes/CarTypes";
import AddCarType from "../pages/Car/AddCarType/AddCarType";
import Color from "../pages/Car/Color/Color";
import Year from "../pages/Car/Year/Year";
import AdminList from "../pages/AdminControl/Admins/AdminList.js/AdminList";
import CreateAdmin from "../pages/AdminControl/Admins/CreateAdmin/CreateAdmin";
import Role from "../pages/AdminControl/Role/Role";
import CarTypeDetails from "../pages/Car/CarTypeDetails/CarTypeDetails";
import PartnerAdd from "../pages/Partners/PartnerAdd/PartnerAdd";

import PartnerDetails from "../pages/Partners/PartnerDetails/PartnerDetails";
import DriverAdd from "../pages/Driver/DriverAdd/DriverAdd";
import CarBrand from "../pages/Car/CarBrand/CarBrand";
import CarModel from "../pages/Car/CarModel/CarModel";
import DriversList from "./../pages/Driver/DriversList/DriversList";
import DriverDetails from "../pages/Driver/DriverDetails/DriverDetails";
import CarAdd from "../pages/Car/CarAdd/CarAdd";
import RideList from "../pages/Ride/List/RideList";
import CarDetails from "../pages/Car/CarDetails/CarDetails";
import AddUser from "../pages/Users/AddUser/AddUser";
import RideAdd from "../pages/Ride/RideAdd/RideAdd";

import UserDetails from "../pages/Users/UserDetails/UserDetails";
import PartnerPrivacyPolicy from "../pages/Policy/Partner/PartnerPrivacyPolicy/PartnerPrivacyPolicy";
import PartnerPaymentPolicy from "../pages/Policy/Partner/PartnerPaymentPolicy/PartnerPaymentPolicy";
import PartnerCancellationPolicy from "./../pages/Policy/Partner/PartnerCancellationPolicy/PartnerCancellationPolicy";
import UserPaymentPolicy from "../pages/Policy/User/UserPaymentPolicy/UserPaymentPolicy";
import UserCancellationPolicy from "../pages/Policy/User/UserCancellationPolicy/UserCancellationPolicy";
import PartnerAboutUs from "../pages/Policy/Partner/PartnerAboutUs/PartnerAboutUs";
import UserAboutUs from "../pages/Policy/User/UserAboutUs/UserAboutUs";
import PartnerRefundPolicy from "../pages/Policy/Partner/PartnerRefundPolicy/PartnerRefundPolicy";
import UserPrivacyPolicy from "../pages/Policy/User/UserPrivacyPolicy/UserPrivacyPolicy";
import UserRefundPolicy from "../pages/Policy/User/UserRefundPolicy/UserRefundPolicy";
import UserPaymentCondition from "../pages/Setting/UserPaymentCondition/UserPaymentCondition";
import CarList from "../pages/Car/CarLlist/CarList";
import TutorialList from "./../pages/Tutorial/TutorialList/TutorialList";
import TutorialAdd from "./../pages/Tutorial/TutorialAdd/TutorialAdd";

import DiscountList from "../pages/Discount/DiscountList/DiscountList";
import DiscountAdd from "../pages/Discount/DiscountAdd/DiscountAdd";
import CouponList from "../pages/Coupon/CouponList/CouponList";
import CouponAdd from "../pages/Coupon/CouponAdd/CouponAdd";
import RideDetails from "../pages/Ride/RideDetails/RideDetails";
import BiddingDetails from "../pages/Ride/BiddingDetails/BiddingDetails";
import UserReview from "../pages/Users/UserReview/UserReview";
import UserTransactions from "../pages/Users/UserTransactions/UserTransactions";
import PartnerTransactions from "../pages/Partners/PartnerTransactions/PartnerTransactions";
import PartnerReview from "../pages/Partners/PartnerReview/PartnerReview";
import PartnerBidding from "./../pages/Partners/PartnerBidding/PartnerBidding";
import Transactions from "../pages/Transactions/Transactions";
import CancelReason from "../pages/Setting/CancelReason/CancelReason";
import DefaultMessages from "../pages/Setting/DefaultMessages/DefaultMessages";
import SystemConfigs from "../pages/Setting/SystemConfigs/SystemConfigs";
import GlobalNotification from "../pages/GlobalNotification/GlobalNotification";
import Notices from "../pages/Notice/Notices";
import Offers from "../pages/Offers/Offers";
import RideCancelConditions from "../pages/RideCancelConditions/RideCancelConditions";
import WithdrawRequests from "../pages/WithdrawRequests/WithdrawRequests";
import ContactUs from "../pages/Policy/ContactUs";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/image-gallery", component: PagesGallery },
  { path: "/image-folder", component: ImageFolder },
  // { path: "/image-recycle-bin", component: FileUpload },
  { path: "/image-upload", component: FormUpload },

  // Banner
  { path: "/banner", component: BannerPage },
  { path: "/banner-add", component: AddBanner },
  { path: "/banner-edit/:id", component: AddBanner },
  // { path: "/banner-add", component: Texteditor },

  // Users

  { path: "/users/list", component: UsersList },
  { path: "/users/add", component: AddUser },
  { path: "/users/edit/:id", component: AddUser },
  { path: "/user/details/:id", component: UserDetails },
  { path: "/user/review/list/:id", component: UserReview },
  { path: "/user/transactions/list/:id", component: UserTransactions },

  // Partners

  { path: "/partner/list", component: PartnersList },
  { path: "/partner/add", component: PartnerAdd },
  { path: "/partner/edit/:id", component: PartnerAdd },
  { path: "/partner/details/:id", component: PartnerDetails },
  { path: "/partner/transactions/list/:id", component: PartnerTransactions },
  { path: "/partner/review/list/:id", component: PartnerReview },
  { path: "/partner/bidding/list/:id", component: PartnerBidding },

  // DRIVER

  { path: "/driver/add", component: DriverAdd },
  { path: "/driver/edit/:id", component: DriverAdd },
  { path: "/driver/details/:id", component: DriverDetails },

  { path: "/drivers", component: DriversList },

  // CAR
  { path: "/car/add", component: CarAdd },
  { path: "/car/edit/:id", component: CarAdd },
  { path: "/car/details/:id", component: CarDetails },

  // CAR TYPES
  { path: "/car-types", component: CarTypes },
  { path: "/add-car-type", component: AddCarType },
  { path: "/car-type/edit/:id", component: AddCarType },
  { path: "/car-type/:id", component: CarTypeDetails },
  { path: "/car/list", component: CarList },

  // CAR BRAND

  { path: "/car-type/brand/:id", component: CarBrand },

  // CAR MODEL

  { path: "/car-type/brand/model/:id", component: CarModel },

  // COLOR

  { path: "/color", component: Color },

  // YEAR

  { path: "/year", component: Year },

  // RIDE
  { path: "/ride/list", component: RideList },
  { path: "/ride/add", component: RideAdd },
  { path: "/ride/details/:id", component: RideDetails },
  { path: "/ride/bidding/details/:id", component: BiddingDetails },

  // PARTNER POLICY

  { path: "/partner/privacy-policy", component: PartnerPrivacyPolicy },
  { path: "/partner/payment-policy", component: PartnerPaymentPolicy },
  {
    path: "/partner/cancellation-policy",
    component: PartnerCancellationPolicy,
  },
  { path: "/partner/about-us", component: PartnerAboutUs },
  { path: "/partner/refund-policy", component: PartnerRefundPolicy },

  // USER POLICY

  { path: "/user/privacy-policy", component: UserPrivacyPolicy },
  { path: "/user/payment-policy", component: UserPaymentPolicy },
  { path: "/user/cancellation-policy", component: UserCancellationPolicy },
  { path: "/user/about-us", component: UserAboutUs },
  { path: "/user/refund-policy", component: UserRefundPolicy },

  // CONTACT US
  { path: "/contact-us", component: ContactUs },

  // ADMIN CONTROL

  { path: "/admin-list", component: AdminList },
  { path: "/create-admin", component: CreateAdmin },
  { path: "/role", component: Role },

  // DISCOUNT

  { path: "/discount/list", component: DiscountList },
  { path: "/discount/add", component: DiscountAdd },

  // COUPON

  { path: "/coupon/list", component: CouponList },
  { path: "/coupon/add", component: CouponAdd },

  // USER PAYMENT CONDITION

  { path: "/user-payment-condition", component: UserPaymentCondition },

  // TUTORIALS

  { path: "/tutorials/list", component: TutorialList },
  { path: "/tutorials/add", component: TutorialAdd },
  { path: "/tutorials/edit/:id", component: TutorialAdd },

  // CANCEL REASON

  { path: "/cancel-reason", component: CancelReason },

  // TRANSACTIONS

  { path: "/transactions", component: Transactions },

  { path: "/withdraw-requests", component: WithdrawRequests },

  // DEFAULT MESSAGES

  { path: "/default-messages", component: DefaultMessages },

  // SYSTEM CONFIGURATION

  { path: "/system-configuration", component: SystemConfigs },

  // GLOBAL NOTIFICATION
  { path: "/global-notification", component: GlobalNotification },

  // NOTICES

  { path: "/notices", component: Notices },

  // OFFERS

  { path: "/offers", component: Offers },

  // RIDE CANCEL CONDITIONS

  { path: "/ride-cancel-conditions", component: RideCancelConditions },

  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },

  //Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },
  // { path: "/email-compose", component: EmailCompose },

  // Email Template
  // { path: "/email-template-alert", component: Emailtemplatealert },
  // { path: "/email-template-basic", component: Emailtemplatebasic },
  // { path: "/email-template-billing", component: Emailtemplatebilling },

  //Charts
  // { path: "/apex-charts", component: ChartApex },
  // { path: "/chartist-charts", component: ChartistChart },
  // { path: "/chartjs-charts", component: ChartjsChart },
  // { path: "/e-charts", component: EChart },
  // { path: "/sparkline-charts", component: SparklineChart },

  // Icons
  // { path: "/icons-dripicons", component: IconDripicons },
  // { path: "/icons-materialdesign", component: IconMaterialdesign },
  // { path: "/icons-fontawesome", component: IconFontawesome },
  // { path: "/icons-ion", component: IconIon },
  // { path: "/icons-themify", component: ThemifyIcon },
  // { path: "/icons-typicons", component: TypiconsIcon },

  // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  // { path: "/form-elements", component: FormElements },
  // { path: "/form-advanced", component: FormAdvanced },
  // { path: "/form-editors", component: FormEditors },
  // { path: "/form-mask", component: FormMask },
  // { path: "/form-repeater", component: FormRepeater },
  // { path: "/form-uploads", component: FormUpload },
  // { path: "/form-wizard", component: FormWizard },
  // { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },

  // Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  // { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-colors", component: UiColors },
  // { path: "/ui-dropdowns", component: UiDropdown },
  // { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  // { path: "/ui-rangeslider", component: UiRangeSlider },

  //Utility
  // { path: "/pages-starter", component: PagesStarter },
  // { path: "/pages-timeline", component: PagesTimeline },
  // { path: "/pages-invoice", component: PagesInvoice },
  // { path: "/pages-directory", component: PagesDirectory },
  // { path: "/pages-faqs", component: PagesFaqs },
  // { path: "/pages-pricing", component: PagesPricing },
  // { path: "/pages-gallery", component: PagesGallery },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

export { userRoutes, authRoutes };

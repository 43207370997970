import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Label,
  FormGroup,
  Modal,
  Form,
} from "reactstrap";
import styled from "styled-components";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { alertMsg } from "../../../helpers/alertMsg";
import { useDispatch, useSelector } from "react-redux";
import {
  addDefaultMessage,
  deleteDefaultMessage,
  editDefaultMessage,
  getAllDefaultMessages,
  updateDefaultMessageSearchKey,
  updateDefaultMessageStatusKey,
} from "../../../store/DefaultMessage/defaultMessageAction";
import SelectOptions from "../../../components/SelectOptions";
import { statusFilterOptions, statusOptions } from "../../../assets/staticData";
import Search from "../../../components/Search";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import DeleteButton from "../../../components/DeleteButton";
import EditButton from "../../../components/EditButton";

const DefaultMessages = () => {
  const dispatch = useDispatch();

  const { loading, status, messages, statusKey, searchKey } = useSelector(
    (state) => state.defaultMessageReducer
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false);
  const [messageItem, setMessageItem] = useState({
    id: null,
    title: "",
    content: "",
    status: "",
  });

  useEffect(() => {
    if (statusKey || searchKey) {
      callMessagesList(true);
    }
  }, [statusKey, searchKey]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessageItem({ ...messageItem, [name]: value });
  };

  // SUBMIT DATA

  const submitMessageData = (e) => {
    e.preventDefault();
    const { title, content, status, id } = messageItem;
    if (!title) {
      return alertMsg("Enter Title", "warning");
    }
    if (!content) {
      return alertMsg("Enter Message Conent", "warning");
    }
    if (id && !status) {
      return alertMsg("Select Status", "warning");
    }

    if (id) {
      dispatch(
        editDefaultMessage({ ...messageItem, status: messageItem.status.value })
      );
    } else {
      dispatch(
        addDefaultMessage({
          ...messageItem,
        })
      );
    }
  };

  // CALL LIST

  const callMessagesList = (refresh = false) => {
    dispatch(getAllDefaultMessages(refresh));
  };

  // ADD BUTTON HANDEL

  const addNewItem = () => {
    setIsOpen(!isOpen);
    setMessageItem({
      ...messageItem,
      title: "",
      content: "",
      id: null,
      status: null,
    });
  };

  // EDIT BUTTON HANDLE

  const editMessage = (mId) => {
    if (!mId) {
      return alertMsg("Default Message Not Found");
    }

    const { id, title, content, status } = messages.find(({ id }) => id == mId);

    if (id) {
      const findStatus = statusFilterOptions.find(
        ({ value }) => value === status
      );
      setMessageItem({
        ...messageItem,
        id,
        title,
        content,
        status: findStatus,
      });
      setIsOpen(true);
    }
  };

  // DELETE

  const deleteMessage = (mId) => {
    setMessageItem({ ...messageItem, id: mId });
    setIsOpenDeleteConfirmModal(!isOpenDeleteConfirmModal);
  };

  useEffect(() => {
    if (status) {
      setMessageItem({
        ...messageItem,
        title: "",
        id: null,
        content: "",
        status: null,
      });
      setIsOpen(false);
    }
  }, [status]);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              breadcrumbItem="Default Messages"
              loading={loading}
              callList={callMessagesList}
              isAddNew={true}
              isModalOpen={addNewItem}
            />
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3}>
                    <SelectOptions
                      label="Status"
                      value={statusKey}
                      options={statusFilterOptions}
                      onChange={(e) =>
                        dispatch(updateDefaultMessageStatusKey(e))
                      }
                      name="type"
                    />
                  </Col>
                  <Col lg={6}>
                    <label>Search</label>
                    <Search
                      dispatchFunc={updateDefaultMessageSearchKey}
                      placeholder="Search by title"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4">Default Messages</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper  table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>SL</Th>
                      <Th>Title</Th>
                      <Th>Content</Th>
                      <Th>Status</Th>
                      <Th>Created At</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {messages.length > 0 &&
                      messages.map(
                        (
                          {
                            id = "",
                            title = "",
                            content = "",
                            status = "",
                            createdAt = "",
                          },
                          index
                        ) => {
                          return (
                            <Tr
                              key={index}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Td>{index + 1}</Td>

                              <Th>{title}</Th>
                              <Td style={{ maxWidth: "200px" }}>{content}</Td>
                              <Td
                                className={`${
                                  status === "active"
                                    ? "active_color"
                                    : "inactive_color"
                                } text-capitalize`}
                              >
                                {status}
                              </Td>
                              <Td>{new Date(createdAt).toLocaleString()}</Td>
                              <Td>
                                <div>
                                  <EditButton
                                    editItem={() => editMessage(id)}
                                  />
                                  <span className="mx-2"></span>
                                  <DeleteButton
                                    deleteItem={() => deleteMessage(id)}
                                  />
                                </div>
                              </Td>
                            </Tr>
                          );
                        }
                      )}
                  </Tbody>
                </Table>
                {loading && (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      style={{
                        position: "fixed",
                        left: "50%",
                        top: "50%",
                      }}
                      animation="border"
                      color="success"
                    />
                  </div>
                )}
                {!loading && messages?.length < 1 && (
                  <div className="text-center">
                    <h3>No Data!</h3>
                  </div>
                )}
              </CardBody>
            </Card>
          </Container>
        </div>

        {/*MODAL FOR ADD AND EDIT CANCEL REASON  */}
        <Modal
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{`${
              messageItem?.id ? "Edit" : "Add"
            } Message`}</h5>
            <button
              type="button"
              onClick={() => {
                setIsOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form className="mb-4" onSubmit={submitMessageData}>
              <div>
                <div className="mb-3">
                  <Label>Title</Label>
                  <Input
                    // style={{ border: '1px solid red' }}
                    value={messageItem?.title}
                    onChange={handleInputChange}
                    name="title"
                    className=""
                    type="text"
                    placeholder="Enter Title"
                  />
                </div>

                <div className="mb-3">
                  <Label>Content</Label>
                  <Input
                    value={messageItem?.content}
                    onChange={handleInputChange}
                    name="content"
                    className=""
                    type="textarea"
                    rows={4}
                    placeholder="Enter Message"
                  />
                </div>

                {messageItem?.id && (
                  <div className="mb-3">
                    <SelectOptions
                      label="Status"
                      value={messageItem?.status}
                      options={statusOptions}
                      onChange={(e) =>
                        setMessageItem({
                          ...messageItem,
                          status: e,
                        })
                      }
                      name="status"
                    />
                  </div>
                )}

                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    type="submit"
                    className="px-4"
                    disabled={loading}
                  >
                    {loading ? "Saving.." : messageItem?.id ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>

        {/* DELETE CONFIRMATION MODAL */}

        {isOpenDeleteConfirmModal && (
          <ConfirmDialog
            typeId={messageItem.id}
            isOpen={isOpenDeleteConfirmModal}
            dispatchFunc={deleteDefaultMessage}
            closeDialog={() => setIsOpenDeleteConfirmModal(false)}
            title="Delete Message"
            content="Are you sure! You want to delete this message?"
          />
        )}
      </GlobalWrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .heading {
    color: red;
  }
`;

export default DefaultMessages;

import * as actionType from "../actionType";
const initialState = {
  loading: false,
  notices: [],
  error: null,
  status: false,
  statusKey: "all",
  paginate: null,
  paging: [],
  hasNextPage: true,
  currentPage: 1,
  hasPreviousPage: false,
};

const noticeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // ADD

    case actionType.ADD_NOTICE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case actionType.ADD_NOTICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        notices: [...state.notices, payload],
      };

    case actionType.ADD_NOTICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionType.UPDATE_NOTICE_TYPE_KEY:
      return {
        ...state,
        statusKey: payload,
      };

    //   GET ALL

    case actionType.ALL_NOTICE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };
    case actionType.ALL_NOTICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        notices: payload.notices,
        status: false,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
      };

    case actionType.ALL_NOTICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // EDIT

    case actionType.EDIT_NOTICE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case actionType.EDIT_NOTICE_REQUEST_SUCCESS:
      const update = state.notices.map((item) =>
        item.id == payload.id ? payload : item
      );

      return {
        ...state,
        loading: false,
        status: true,
        notices: [...update],
      };

    case actionType.EDIT_NOTICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // DELETE

    case actionType.DELETE_NOTICE_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
      };

    case actionType.DELETE_NOTICE_REQUEST_SUCCESS:
      const updateData = state.notices.filter((item) => item.id != payload);

      return {
        ...state,
        loading: false,
        status: true,
        notices: [...updateData],
      };

    case actionType.DELETE_NOTICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default noticeReducer;

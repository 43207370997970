import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { ACCEPT_BID, RIDE_DETAILS, SEND_OFFER } from "../../../network/Api";
import requestApi from "../../../network/httpRequest";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
  Spinner,
} from "reactstrap";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { Tooltip } from "@mui/material";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { alertMsg } from "../../../helpers/alertMsg";
import { tripTypes } from "../../../assets/staticData";

const RideDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  const { rides, biddings } = useSelector((state) => state.rideReducer);

  const [ride, setRide] = useState(null);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [bidOfferModalOpen, setBidOfferModalOpen] = useState(false);
  const [bidId, setBidId] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      callApi(id);
    }
  }, [id]);

  // CALL API FOR GET SINGLE RIDE

  const callApi = async (rideId) => {
    const { data } = await requestApi().request(RIDE_DETAILS, {
      params: {
        rideId,
      },
    });

    if (data.status) {
      const { ride } = data?.data;
      setRide(ride);
    } else {
      history.push("/ride/list", { replace: true });
    }
  };

  // const get bidding time

  const getBiddingTimeRemaining = (bidTime) => {
    // console.log()

    // const diff = new Date(bidTime).getTime() - new Date().getTime() ;
    let biddingTime = new Date(bidTime);
    let presentTime = new Date();
    // if(presentTime < biddingTime){
    //   // presentTime.setDate(presentTime.getDate() + 1);
    //   console.log(presentTime.setDate(presentTime.getDate()))
    // }

    let diff = biddingTime - presentTime;
    if (diff < 0) {
      return 0;
    } else {
      const remainMin = Math.floor((diff / (1000 * 60)) % 60);
      return remainMin;
    }
  };

  // BIDDING DETAILS ROUTE

  const biddingDetails = (bidId) => {
    history.push({
      pathname: `/ride/bidding/details/${bidId}`,
      state: { user: ride.user },
    });
  };

  // GET PARTNER WISE LOWEST BID

  const getLowestBid = (bidAmounts, biddingUser) => {
    let amounts = [];
    let lowestAmount = null;

    bidAmounts.map((amount) => {
      if (amount.offerSendBy === biddingUser) {
        amounts = [...amounts, amount?.biddingAmount];
      }
    });

    if (amounts.length > 0) {
      lowestAmount = Math.min(...amounts);
    } else {
      lowestAmount = "No Offer Sent Yet";
    }

    return lowestAmount;
  };

  // Bidding Advance Amount

  const userAdvanceAmount = (bidAmount) => {
    const advanceAmount = bidAmount.map((info) => {
      if (info?.userAdvancePayment > 0) {
        return info?.userAdvancePayment;
      }
    });

    return advanceAmount;
  };

  // HANDLE CLICK ON ACCEPT BID BUTTON

  const handlePartnerBidAccept = (bidding) => {
    let partnerBids = [];
    let lowestAmountId = null;
    bidding.map((amount) => {
      if (amount.offerSendBy === "partner") {
        partnerBids = [...partnerBids, amount];
      }
    });

    const bidLength = partnerBids.length;
    if (bidLength === 0) {
      return alertMsg("Panert not bidding yet!", "warning");
    }

    if (bidLength === 1) {
      lowestAmountId = partnerBids[0].id;
    } else {
      let minBid = partnerBids[0].biddingAmount;

      for (let i = 1; i < partnerBids.length; i++) {
        if (partnerBids[i].biddingAmount < minBid) {
          minBid = partnerBids[i].biddingAmount;
          lowestAmountId = partnerBids[i].id;
        }
      }
    }

    setBidId(lowestAmountId);
    setAcceptModalOpen(true);
  };

  // ACCEPT BID

  const acceptPartnerBid = async () => {
    if (!bidId) {
      return alertMsg("Invalid Bidding Amount", "warning");
    }
    setIsLoading(true);
    try {
      const { data } = await requestApi().request(ACCEPT_BID, {
        method: "POST",
        data: {
          amountId: bidId,
        },
      });
      setAcceptModalOpen(false);
      setIsLoading(false);
      setBidId(null);
      setBidOfferModalOpen(false);
      const { status, message, error } = data;
      if (status) {
        alertMsg(message, "success");
        callApi(id);
      } else {
        alertMsg(error, "warning");
      }
    } catch (error) {
      console.log("accept bid error", error.message);
    }
  };

  // SENT BID OFFER TO PARTNER

  const sendBiddingOfferToPartner = async (e) => {
    e.preventDefault();

    if (!bidId) {
      return alertMsg("Bidding Not Found!", "warning");
    }

    if (bidAmount <= 0) {
      return alertMsg("Enter Bid Amount", "warning");
    }

    try {
      setIsLoading(true);
      const { data } = await requestApi().request(SEND_OFFER, {
        method: "POST",
        data: {
          bidId,
          biddingAmount: bidAmount,
        },
      });

      setIsLoading(false);
      setBidAmount(0);
      setBidId(null);
      const { status, message, error } = data;
      if (status) {
        alertMsg(message, "success");
      } else {
        alertMsg(error, "warning");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const isOfferAccept = (biddings, person) => {
    let isAccepted = "No";
    biddings.map((bid) => {
      if (bid.offerSendBy === person) {
        if (person === "user" && bid?.acceptedPrice) {
          isAccepted = "Yes";
        } else {
          if (bid?.partnerAcceptUserOffer) {
            isAccepted = "Yes";
          }
        }
      }
    });

    return isAccepted;
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Ride"
              breadcrumbItem="Details"
              hideSettingBtn={true}
              isRefresh={false}
            />

            <Card>
              <CardBody>
                <Row>
                  <div>
                    <h5>Ride Details</h5>
                    <hr />
                  </div>
                  <Col xl={6}>
                    <DetailsWrapper>
                      <span className="me-2 title">Booking Id :</span>
                      <span className="value">{ride?.bookingId}.</span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Pickup :</span>
                      <span className="value">
                        {ride?.pickupLocation?.address}.
                      </span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Drop off :</span>
                      <span className="value">
                        {ride?.dropOffLocation?.address}.
                      </span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Distance :</span>
                      <span className="value">{ride?.distanceText}.</span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Duration :</span>
                      <span className="value">{ride?.durationText}.</span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Pickup Time :</span>
                      <span className="value">{ride?.pickUpTimeText}.</span>
                    </DetailsWrapper>
                  </Col>
                  <Col xl={6}>
                    <DetailsWrapper>
                      <span className="me-2 title">Bidding Time :</span>
                      <span className="value">{ride?.onBiddingTimeText}.</span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">
                        Bidding Time Remaining :
                      </span>
                      <span className="value">
                        {getBiddingTimeRemaining(ride?.onBiddingTime)} Min.
                      </span>
                    </DetailsWrapper>

                    <DetailsWrapper>
                      <span className="me-2 title">Car Type:</span>
                      <div className="value">
                        <img
                          src={ride?.carType?.image}
                          className="avatar-xs rounded-circle me-2"
                          alt="ride"
                        />
                        <span>{ride?.carType.name}.</span>
                      </div>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Status :</span>
                      <span
                        className="value text-capitalize"
                        style={{
                          color:
                            ride?.status == "accepted"
                              ? "green"
                              : ride?.status == "canceled"
                              ? "red"
                              : ride?.status == "waiting"
                              ? "purple"
                              : "black",
                        }}
                      >
                        {ride?.status}{" "}
                        {ride?.status === "canceled" &&
                          `(By ${ride?.ride_cancels[0]?.cancelBy})`}
                        .
                      </span>
                    </DetailsWrapper>
                    <DetailsWrapper>
                      <span className="me-2 title">Trip Type :</span>
                      <span className="value">
                        {tripTypes[ride?.tripType]}.
                      </span>
                    </DetailsWrapper>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              {/* PICKUP INFO */}
              <Col xl={6}>
                {LocationCard(
                  "Pickup Location",
                  ride?.pickupLocation?.address,
                  ride?.pickupLocation?.subLocality,
                  ride?.dropOffLocation?.locality,
                  ride?.dropOffLocation?.country
                )}
              </Col>
              {/* DROP INFO */}
              <Col xl={6}>
                {LocationCard(
                  "Drop Off Location",
                  ride?.dropOffLocation?.address,
                  ride?.dropOffLocation?.subLocality,
                  ride?.dropOffLocation?.locality,
                  ride?.dropOffLocation?.country
                )}
              </Col>
            </Row>

            <Row className="pb-3">
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <CardTitle className="h4">User</CardTitle>
                      <strong
                        className="cursor-pointer text-primary"
                        onClick={() =>
                          history.push(`/user/details/${ride?.user?.id}`)
                        }
                      >
                        See Details
                      </strong>
                    </div>
                    <hr />
                    <Row>
                      <Col sm={6}>
                        <ImageWrapper>
                          {ride?.user?.img ? (
                            <img
                              className="rounded-circle avatar-lg cursor-pointer"
                              alt="partner"
                              src={ride?.user?.img}
                              // onClick={()=>{setPhotoIndex(4); setIsZoom(true)} }
                            />
                          ) : (
                            <ShimmerCircularImage size={150} />
                          )}
                        </ImageWrapper>
                      </Col>
                      <Col sm={6} className="mt-3">
                        <div>
                          <h5>Name: {ride?.user?.name}</h5>
                        </div>
                        <div>
                          <h5>Phone: {ride?.user?.phoneNumber}</h5>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* BIDDING LISTS */}
            <Row>
              <Card>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table table__wrapper table-striped table-bordered table-hover text-center"
                  >
                    <Thead>
                      <Tr>
                        <Th>Partner</Th>
                        <Th>Partner Last Offer</Th>
                        <Th>User Last Offer</Th>
                        <Th>User Offer Accept</Th>
                        <Th>User Advance Payment(TK)</Th>
                        <Th>Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody style={{ position: "relative" }}>
                      {ride && ride?.biddings.length > 0
                        ? ride?.biddings?.map((bid) => (
                            <Tr
                              key={bid.id}
                              className="align-middle"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              <Th>
                                <p>{bid?.partner?.name}</p>
                                <p>{bid?.partner?.phone}</p>
                              </Th>

                              <Td>
                                {getLowestBid(bid?.bidding_amounts, "partner")}
                              </Td>
                              <Td>
                                {getLowestBid(bid?.bidding_amounts, "user")}
                              </Td>
                              <Td>
                                {isOfferAccept(bid?.bidding_amounts, "user")}
                              </Td>
                              <Td>{userAdvanceAmount(bid?.bidding_amounts)}</Td>
                              <Td
                                className={`text-capitalize ${
                                  bid?.status === "accepted" ||
                                  bid?.status === "completed"
                                    ? "text-success"
                                    : bid?.status === "canceled"
                                    ? "text-danger"
                                    : "text-primary"
                                }`}
                              >
                                {bid?.status} {}
                              </Td>
                              <Td>
                                {ride.status == "send" &&
                                new Date(ride?.onBiddingTime) > new Date() ? (
                                  <div>
                                    {/* <Tooltip title="Accept Bid">
                                      <button
                                        className="btn btn-success button "
                                        onClick={() => {
                                          handlePartnerBidAccept(
                                            bid?.bidding_amounts
                                          );
                                        }}
                                      >
                                        <i className="fas fa-handshake"></i>
                                      </button>
                                    </Tooltip> */}
                                    <Tooltip title="Sent Offer">
                                      <button
                                        className="btn btn-primary button "
                                        onClick={() => {
                                          setBidOfferModalOpen(
                                            !bidOfferModalOpen
                                          );
                                          setBidId(bid.id);
                                        }}
                                      >
                                        <i className="fas fa-dollar-sign"></i>
                                      </button>
                                    </Tooltip>
                                    <Tooltip title="Cancel Bid">
                                      <button
                                        className="btn btn-danger button "
                                        // onClick={() => {
                                        //   setOpenTimeModal(!openTimeModal);
                                        //   setSelectedRide(ride);
                                        // }}
                                      >
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    </Tooltip>
                                  </div>
                                ) : null}
                                <Tooltip title="Details">
                                  <button
                                    className="btn btn-info button"
                                    onClick={() => biddingDetails(bid?.id)}
                                  >
                                    <i className="fa fa-eye" />
                                  </button>
                                </Tooltip>
                              </Td>
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                  {ride?.biddings?.length === 0 && (
                    <div className="text-center w-100">
                      <h5>No Data !</h5>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>

      {/* SEND BIDDING OFFER TO PARTNER FROM USER */}

      <Modal
        isOpen={bidOfferModalOpen}
        toggle={() => setBidOfferModalOpen(!bidOfferModalOpen)}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Sent Offer</h5>
          <button
            type="button"
            onClick={() => {
              setBidOfferModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="mb-4" onSubmit={sendBiddingOfferToPartner}>
            <div>
              <div className="mb-3">
                <Label>Bidding Amount</Label>
                <Input
                  // style={{ border: '1px solid red' }}
                  // value={messageItem?.title}
                  onChange={(e) => setBidAmount(e.target.value)}
                  name="bidAmount"
                  className=""
                  type="number"
                  placeholder="Enter Bidding Amount"
                  min={1}
                />
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  type="submit"
                  className="px-4"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending.." : "Send"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* DELETE CONFIRMATION MODAL */}

      {acceptModalOpen && (
        <ConfirmDialog
          isOpen={acceptModalOpen}
          dispatchFunc={() => acceptPartnerBid()}
          closeDialog={() => setAcceptModalOpen(false)}
          title="Accept Bid?"
          content="Are you sure! You want to accept this bid?"
          buttonName="Accept"
          loading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0px;
  .title {
    font-size: 15px;
    color: #424242;
    font-weight: 450;
  }
  .value {
    font-size: 15px;
    color: #0e2c24;
    font-weight: 500;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-right: 1px solid lightgray;
  @media (max-width: 580px) {
    border-right: none;
  }
`;

export default RideDetails;

// PICKUP AND DROP OFF LOCATION CARD

const LocationCard = (title, address, sublocality, locality, country) => {
  return (
    <Card>
      <CardBody>
        <div>
          <h5>{title}</h5>
          <hr />
        </div>

        <DetailsWrapper>
          <span className="me-2 title">Address :</span>
          <span className="value">{address}.</span>
        </DetailsWrapper>

        <DetailsWrapper>
          <span className="me-2 title">Sub Locality:</span>
          <span className="value">{sublocality ? sublocality : "N/A"}.</span>
        </DetailsWrapper>

        <DetailsWrapper>
          <span className="me-2 title">Locality :</span>
          <span className="value">{locality}.</span>
        </DetailsWrapper>

        <DetailsWrapper>
          <span className="me-2 title">Country :</span>
          <span className="value">{country}.</span>
        </DetailsWrapper>
      </CardBody>
    </Card>
  );
};

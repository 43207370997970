import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";

const AutocompletedInput = ({
  value,
  onChange,
  searchKey,
  onInputChange,
  list,
  disabled,
  label,
  name,
  required = false,
  showImg = false,
}) => {
  return (
    <Autocomplete
      className="cursor-pointer"
      disabled={disabled}
      value={value}
      onChange={onChange}
      getOptionLabel={(option) =>
        option?.name ? option?.name : option?.title ? option?.title : ""
      }
      isOptionEqualToValue={(option, item) => option?.id == item?.id}
      inputValue={searchKey}
      onInputChange={onInputChange}
      id="controllable-states-demo"
      options={list.length > 0 ? list : []}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} name={name} />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option?.id}
        >
          {showImg && (
            <img loading="lazy" width="60" src={option?.image} alt="" />
          )}
          {option?.name ?? option?.title}
        </Box>
      )}
    />
  );
};

export default AutocompletedInput;

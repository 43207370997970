import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import GlobalWrapper from "../../components/GlobalWrapper";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SentNotification from "../../components/SentNotification";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { getAllDefaultMessages } from "../../store/DefaultMessage/defaultMessageAction";
import { alertMsg } from "../../helpers/alertMsg";
import requestApi from "../../network/httpRequest";
import { SENT_GLOBAL_NOTIFICAITON } from "../../network/Api";

const GlobalNotification = () => {
  const dispatch = useDispatch();

  const { messages } = useSelector((state) => state.defaultMessageReducer);

  const [messageSearchKey, setMessageSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    sendTo: "",
    title: "",
    message: "",
    type: "notificaition",
    image: "",
    defaultMessage: "",
  });

  useEffect(() => {
    dispatch(getAllDefaultMessages(true));
  }, []);

  // UPDATE NOTIFICATION INPUTS

  const updateNotificationInputs = (event, data) => {
    if (event?.name === "image") {
      setNotification((prev) => ({ ...prev, image: event.value }));
    } else {
      const { name, value } = event.target;
      if (name) {
        setNotification((prev) => ({ ...prev, [name]: value }));
      } else {
        setNotification((prev) => ({
          ...prev,
          defaultMessage: data,
          title: data?.title ?? "",
          message: data?.content ?? "",
        }));
      }
    }
  };

  // SENT NOTIFICATION

  const sentGlobalNotification = async (e) => {
    e.preventDefault();

    const { image, sendTo, title, message, type } = notification;

    if (!sendTo || !title || !message || !type) {
      return alertMsg("Invalid data!", "warning");
    }

    setIsLoading(true);

    try {
      const { data } = await requestApi().request(SENT_GLOBAL_NOTIFICAITON, {
        method: "POST",
        data: {
          image,
          sendTo,
          title,
          message,
          type,
        },
      });

      setIsLoading(false);
      if (data.status) {
        alertMsg(data.message, "success");
        setNotification({
          sendTo: "",
          title: "",
          message: "",
          type: "notificaition",
          image: "",
          defaultMessage: "",
        });
      } else {
        alertMsg(data.message, "warning");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //   DELETE IMAGE

  const deleteImage = () => {
    setNotification((prev) => ({ ...prev, image: "" }));
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Quicar"
              breadcrumbItem="Global Notification"
              isRefresh={false}
              //   loading={loading}
              //   callList={callColorList}
            />

            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <SentNotification
                      updateData={updateNotificationInputs}
                      searchKey={messageSearchKey}
                      messages={messages}
                      notification={notification}
                      loading={isLoading}
                      setSearchKey={(event, inputValue) =>
                        setMessageSearchKey(inputValue)
                      }
                      deleteImage={deleteImage}
                      submitData={sentGlobalNotification}
                      hasImage={true}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  .heading {
    color: red;
  }
`;

export default GlobalNotification;

import { toast } from "react-toastify";
import { alertMsg } from "../../helpers/alertMsg";
import {
  ADD_RIDE,
  ALL_RIDES,
  CANCEL_RIDE,
  EXTEND_WAITING_TIME,
  GET_SINGLE_BIDDING,
} from "../../network/Api";
import * as actionType from "../actionType";
import requestApi from "./../../network/httpRequest";

// GET ALL RIDE

export const AllRides =
  (refresh = false, page = 1) =>
  async (dispatch, getState) => {
    // console.log("call----------");
    try {
      const { rides, typeKey, searchKey, userFilter } = getState().rideReducer;

      if (rides.length < 1 || refresh) {
        dispatch({
          type: actionType.GET_ALL_RIDE_REQUEST_SEND,
        });

        const { data } = await requestApi().request(ALL_RIDES, {
          params: {
            searchKey: searchKey,
            page: page,
            pageSize: 100,
            type: typeKey,
            userId: userFilter ? userFilter.id : null,
          },
        });

        if (data.status) {
          dispatch({
            type: actionType.GET_ALL_RIDE_REQUEST_SUCCESS,
            payload: { rides: data.data.rides, paginate: data.data.paginate },
          });
        } else {
          dispatch({
            type: actionType.GET_ALL_RIDE_REQUEST_FAIL,
            payload: data.error,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_ALL_RIDE_REQUEST_FAIL,
        payload: error.message,
      });
    }
  };

// UPDATE SEARCH KEY

export const updateRideSearchKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_RIDE_SEARCH_KEY,
    payload: value,
  });
};

// UPDATE STATUS KEY

export const updateRideTypeKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.UPDATE_RIDE_TYPE_KEY,
    payload: value,
  });
};

export const updateUserKey = (value) => (dispatch) => {
  dispatch({
    type: actionType.FILTER_RIDE_USER,
    payload: value,
  });
};

// SELECT CAR TYPE

export const selectCarType = (carType) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_RIDE_CAR_TYPE,
    payload: carType,
  });
};

// SELECT RIDE USER

export const selectUser = (user) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_RIDE_USER,
    payload: user,
  });
};

// SELECT TRIP

export const selectTrip = (value) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_TRIP,
    payload: value,
  });
};

// SELECT PICKUP TIME

export const selectPickupTime = (value) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_PICKUP_TIME,
    payload: value,
  });
};

// SELECT RETURN TIME

export const selectReturnTime = (value) => (dispatch) => {
  dispatch({
    type: actionType.SELECT_RETURN_TIME,
    payload: value,
  });
};

// ADD RIDE

export const addRide = (rideData) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_RIDE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(ADD_RIDE, {
      method: "POST",
      data: rideData,
    });

    if (data.status) {
      toast.success(data.message, {
        // position: "bottom-right",
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: actionType.ADD_RIDE_REQUEST_SUCCESS,
        payload: data.data.ride,
      });
    } else {
      dispatch({
        type: actionType.ADD_RIDE_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.ADD_RIDE_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// EXTEND WAITING TIME

export const extendWaitingTime = (values) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.EXTEND_WAITING_TIME_REQUEST_SEND,
    });

    const { data } = await requestApi().request(EXTEND_WAITING_TIME, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      alertMsg(data.message, "success");

      dispatch({
        type: actionType.EXTEND_WAITING_TIME_REQUEST_SUCCESS,
        payload: data.data.updateRide,
      });
    } else {
      alertMsg(data.error, "warning");

      dispatch({
        type: actionType.EXTEND_WAITING_TIME_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.EXTEND_WAITING_TIME_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// CANCEL RIDE BY ADMIN

export const cancelRideByAdmin = (values) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.CANCEL_RIDE_REQUEST_SEND,
    });

    const { data } = await requestApi().request(CANCEL_RIDE, {
      method: "POST",
      data: values,
    });

    if (data.status) {
      const { ride } = data.data;
      alertMsg(data.message, "success");

      dispatch({
        type: actionType.CANCEL_RIDE_REQUEST_SUCCESS,
        payload: ride,
      });
    } else {
      alertMsg(data.error, "warning");

      dispatch({
        type: actionType.CANCEL_RIDE_REQUEST_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.CANCEL_RIDE_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// CREATE RIDE SENT NOTIFICATION DATA

export const updateNotificationData = (name, value) => (dispatch) => {
  dispatch({
    type: actionType.NOTIFICATION_DATA_ADD_FOR_RIDE,
    payload: {
      name,
      value,
    },
  });
};

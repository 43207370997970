import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import { userReviews } from "../../../store/Users/UsersAction";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { Rating } from "@mui/material";
import AppPagination from "../../../components/AppPagination";

const UserReview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
    const history = useHistory();
//   const [userId, setUserId] = useState(null)

  const {
    loading,
    reviews,
    paging,
    hasNextPage,
    hasPreviousPage,
    currentPage,
  } = useSelector((state) => state.usersReducer);

  useEffect(() => {
    if (id) {

      callReviewList(true, id)
    }else{
        history.push('/users/list', {replace: true})
    }
  }, [id]);

  const callReviewList = (refresh= false, userId) =>{
    //   console.log({id})
    dispatch(userReviews(refresh, userId));
  }

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Drop"
              title="User"
              breadcrumbItem="Review"
                loading={loading}
                callList={callReviewList}
            />

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Review List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Review For</Th>
                      <Th>Reviewer</Th>
                      <Th>Review</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {reviews.length > 0 &&
                      reviews.map((item, index) => {
                        return (
                          <Tr
                            key={index}
                            className="align-middle"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            <Th>
                              <div>
                                <img
                                  src={item?.user?.img}
                                  className="avatar-xs rounded-circle me-2"
                                  alt="Partner"
                                />
                                <span>{item?.user?.name}</span>
                              </div>
                            </Th>

                            <Td>
                              <div>
                                <img
                                  src={item?.reviewerPartner?.img}
                                  className="avatar-xs rounded-circle me-2"
                                  alt="Partner"
                                />
                                <span>{item?.reviewerPartner?.name}</span>
                              </div>
                            </Td>
                            <Td>
                              <Rating name="read-only" precision={0.5} value={item?.rating} defaultValue={0} readOnly />
                              <p>{item?.review}</p>
                            </Td>
                          </Tr>
                        );
                      })}
                    {loading && (
                      <Tr>
                        <Td>
                          <Spinner
                            style={{
                              position: "fixed",
                              left: "50%",
                              top: "50%",
                            }}
                            animation="border"
                            variant="success"
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>

                {reviews.length < 1 && !loading && (
                  <div className="text-center">
                    <h3>No Data Found</h3>
                  </div>
                )}
              </CardBody>
            </Card>

            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(userReviews(true,id, page))}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

export default UserReview;

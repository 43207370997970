import * as actionType from "../actionType";

const initialState = {
  loading: false,
  coupons: [],
  error: null,
  status: false,
  searchKey: "",
  statusKey: { label: "All", value: "all" },
  appliedKey: { label: "All", value: "all" },
  paginate: null,
  paging: [],
  hasNextPage: true,
  currentPage: 1,
  hasPreviousPage: false,
  selectedUser: null,
};

const couponReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // ADD
    case actionType.ADD_COUPON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case actionType.ADD_COUPON_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        coupons: [...state.coupons, payload],
        error: null,
      };

    case actionType.ADD_COUPON_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // UPDATE STATUS

    case actionType.UPDATE_COUPON_STATUS_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case actionType.UPDATE_COUPON_STATUS_REQUEST_SUCCESS:
      const itemIndex = state.coupons.findIndex(
        (item) => item.id === payload.id
      );
      if (itemIndex !== -1) {
        state.coupons[itemIndex] = payload;
      }

      return {
        ...state,
        loading: false,
        status: true,
        coupons: [...state.coupons],
        error: null,
      };

    case actionType.UPDATE_COUPON_STATUS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actionType.SELECT_USER_FOR_COUPON:
      return {
        ...state,
        selectedUser: payload,
      };

    //   GET ALL

    case actionType.GET_ALL_COUPON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_ALL_COUPON_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        coupons: payload.coupons,
        paginate: payload.paginate,
        paging: payload.paginate.metadata.paging,
        hasNextPage: payload.paginate.metadata.hasNextPage,
        currentPage: payload.paginate.metadata.page.currentPage,
        hasPreviousPage: payload.paginate.metadata.hasPreviousPage,
        status: false,
      };

    case actionType.GET_ALL_COUPON_REQUEST_FAIL:
      return {
        ...state,
        error: payload,
        status: false,
        loading: false,
      };

    //   FILTER KEY

    case actionType.UPDATE_SEARCH_KEY:
      return {
        ...state,
        loading: false,
        searchKey: payload,
      };

    case actionType.UPDATE_STATUS_KEY:
      return {
        ...state,
        loading: false,
        statusKey: payload,
      };

    case actionType.UPDATE_APPLIED_KEY:
      return {
        ...state,
        loading: false,
        appliedKey: payload,
      };

    // VISIBLE COUPON TO USER

    case actionType.VISIBLE_COUPON_REQUEST_SEND:
      return {
        ...state,
        loading: true,
        status: false,
        error: null,
      };

    case actionType.VISIBLE_COUPON_REQUEST_SUCCESS:
      const couponIndex = state.coupons.findIndex(
        (item) => item.id === payload.id
      );
      if (couponIndex !== -1) {
        state.coupons[couponIndex] = payload;
      }

      return {
        ...state,
        loading: false,
        status: true,
        coupons: [...state.coupons],
        error: null,
      };

    case actionType.VISIBLE_COUPON_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default couponReducer;

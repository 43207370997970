import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import styled from "styled-components";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import GlobalWrapper from "../../../components/GlobalWrapper";
import {
  getAllDiscounts,
  updateDiscountAppliedKey,
  updateDiscountSearchKey,
  updateDiscountStatusKey,
} from "../../../store/Discount/discountAction";
import AppPagination from "../../../components/AppPagination";

const DiscountList = () => {
  const dispatch = useDispatch();

  const {
    loading,
    searchKey,
    statusKey,
    appliedKey,
    discounts,
    paging,
    hasNextPage,
    currentPage,
    hasPreviousPage,
  } = useSelector((state) => state.discountReducer);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (searchKey || statusKey || appliedKey) {
      calDiscountsList(true);
    } else {
      calDiscountsList();
    }
  }, [searchKey, statusKey, appliedKey]);

  const calDiscountsList = (refresh = false) => {
    // console.log(searchKey);
    dispatch(getAllDiscounts(refresh));
  };

  // DEBOUNCE SEARCH

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      // const context = this;
      timer = setTimeout(() => {
        func(args[0]);
      }, delay);
    };
    // console.log("yes....");
  };

  const handleSearchChange = (event) => {
    // console.log("event", event.target.value)
    // setOpen(true);
    dispatch(updateDiscountSearchKey(event.target.value));
  };

  const searchKeyListener = debounce(handleSearchChange, 300);

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumb
              maintitle="Quicar"
              title="Discount"
              breadcrumbItem="List"
              loading={loading}
              callList={calDiscountsList}
              isAddNew={true}
              addNewRoute="discount/add"
            />

            <Card>
              <CardBody>
                <Row>
                  <Col md={3}>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={statusKey}
                          label="Status"
                          onChange={(event) =>
                            dispatch(
                              updateDiscountStatusKey(event.target.value)
                            )
                          }
                        >
                          <MenuItem value={"all"}>All</MenuItem>
                          <MenuItem value={"active"}>Active</MenuItem>
                          <MenuItem value={"inactive"}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <SearchWrapper>
                      <div className="search__wrapper">
                        <i className="fa fa-search" />
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Find discount"
                          id="search"
                          onChange={searchKeyListener}
                        />
                      </div>
                    </SearchWrapper>
                  </Col>
                  <Col md={3}>
                    <div>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Applied By
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={appliedKey}
                          label="Applied To"
                          onChange={(event) =>
                            dispatch(
                              updateDiscountAppliedKey(event.target.value)
                            )
                          }
                        >
                          <MenuItem value="global">Global</MenuItem>
                          <MenuItem value="specific">Specific</MenuItem>
                          <MenuItem value="new">New</MenuItem>
                          <MenuItem value="all">All</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={3} className="text-end" />
                </Row>
                <CardTitle className="h4"> Discount List</CardTitle>
                <Table
                  id="tech-companies-1"
                  className="table table__wrapper table-striped table-bordered table-hover text-center"
                >
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Apply Type</Th>
                      <Th>Expriy Date</Th>
                      <Th>Status</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody style={{ position: "relative" }}>
                    {discounts.length > 0 &&
                      discounts.map((item, index) => {
                        return (
                          <Tr
                            key={index}
                            className="align-middle"
                            style={{
                              fontSize: "15px",
                              fontWeight: "500",
                            }}
                          >
                            <Th>{item.name}</Th>

                            <Td>{item.applyType}</Td>
                            <Td>{new Date(item.endAt).toLocaleDateString()}</Td>
                            <Td>{item.status}</Td>
                            <Td>
                              <div>
                                <button
                                  className="btn btn-info me-xl-3 button"
                                  // onClick={() =>
                                  //   history.push(`/partner/edit/${partner.id}`)
                                  // }
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  className="btn btn-success button"
                                  // onClick={() =>
                                  //   history.push(`/partner/details/${partner.id}`)
                                  // }
                                >
                                  <i className="fa fa-eye" />
                                </button>
                              </div>
                            </Td>
                          </Tr>
                        );
                      })}
                    {loading && (
                      <Tr>
                        <Td>
                          <Spinner
                            style={{
                              position: "fixed",
                              left: "50%",
                              top: "50%",
                            }}
                            animation="border"
                            variant="success"
                          />
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {!loading && discounts.length < 1 && (
                  <div className="text-center">
                    <h3>No Data</h3>
                  </div>
                )}
              </CardBody>
            </Card>
            <Row>
              <Col xl={12}>
                <div className="d-flex justify-content-center">
                  <AppPagination
                    paging={paging}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    currentPage={currentPage}
                    lisener={(page) => dispatch(getAllDiscounts(true, page))}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;

  .search__wrapper {
    padding: 7px 10px;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
    }
    input {
      border: none;
      color: black !important;
    }
  }
`;

export default DiscountList;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import GlobalWrapper from "../../../components/GlobalWrapper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import requestApi from "../../../network/httpRequest";
import { SINGLE_USER, USER_STATUS_CHANGE } from "../../../network/Api";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import { Rating } from "@mui/material";
import { updateUserKey } from "../../../store/Ride/rideAction";
import BalanceAdd from "../../../components/BalanceAdd/BalanceAdd";
import WithdrawBalance from "../../../components/WithdrawBalance/WithdrawBalance";
import { alertMsg } from "../../../helpers/alertMsg";

const UserDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading, users } = useSelector((state) => state.usersReducer);

  const [user, setUser] = useState({});
  const [selectedImg, setSelectedImg] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalCenter, setModalCenter] = useState(false);
  const [withdrowModalCenter, setWithdrowModalCenter] = useState(false);

  useEffect(() => {
    if (id) {
      const findUser = users.find((user) => user.id == id);
      if (findUser) {
        setUser(findUser);
      } else {
        // console.log("call api---");
        callApi(id);
      }
    } else {
      history.push("/users/list", { replace: true });
    }
  }, [id]);

  //   CALL API FOR SINGLE USER

  const callApi = async (userId) => {
    try {
      const { data } = await requestApi().request(SINGLE_USER, {
        params: {
          id: userId,
        },
      });

      if (data.status) {
        setUser(data.data.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // GO TO RIDE LIST

  const goToRideList = () => {
    // console.log("clicked----")
    dispatch(updateUserKey(user));
    history.push({
      pathname: "/ride/list",
      search: `?user=${user.id}`,
    });
  };

  // CHANGE PARTNER STATUS

  const changeUserStatus = async () => {
    if (!id || !user?.status) {
      return alertMsg("Invalid Request!.", "warn");
    }

    const { data } = await requestApi().request(USER_STATUS_CHANGE, {
      method: "POST",
      data: {
        userId: id,
        status: user?.status === "active" ? "block" : "active",
      },
    });

    if (data?.status) {
      const { user } = data?.data;

      setUser(user);
    }
    alertMsg(data?.message);
  };

  return (
    <React.Fragment>
      <GlobalWrapper>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              maintitle="Drop"
              title="User"
              breadcrumbItem="Details"
              isRefresh={false}
              //   loading={loading}
              //   callList={callColorList}
            />

            {isOpen && (
              <Lightbox
                mainSrc={selectedImg}
                enableZoom={true}
                imageCaption="img"
                onCloseRequest={() => {
                  setIsOpen(!isOpen);
                }}
              />
            )}

            <CardWrapper>
              <Card
                className="cursor-pointer item_wrapper "
                onClick={goToRideList}
              >
                <CardBody className="item">Ride</CardBody>
              </Card>
              <Card
                className="cursor-pointer item_wrapper "
                onClick={() =>
                  history.push(`/user/transactions/list/${user.id}`)
                }
              >
                <CardBody className="item">Transactions</CardBody>
              </Card>
              <Card
                className="cursor-pointer item_wrapper"
                onClick={() => history.push(`/user/review/list/${user.id}`)}
              >
                <CardBody className="item">Review</CardBody>
              </Card>
              <Card className="cursor-pointer item_wrapper">
                <CardBody
                  className="item"
                  onClick={() => {
                    setModalCenter(!modalCenter);
                    document.body.classList.add("no_padding");
                  }}
                >
                  Add Money
                </CardBody>
              </Card>
              <Card className="cursor-pointer item_wrapper">
                <CardBody
                  className="item"
                  onClick={() => {
                    setWithdrowModalCenter(!withdrowModalCenter);
                    document.body.classList.add("no_padding");
                  }}
                >
                  Withdrow Money
                </CardBody>
              </Card>
              <Card className="cursor-pointer item_wrapper">
                <CardBody className="item">Activity</CardBody>
              </Card>
              <Card className="cursor-pointer item_wrapper">
                <CardBody className="item" onClick={changeUserStatus}>
                  {["block", "pending"].includes(user?.status)
                    ? "Active"
                    : "Block"}
                </CardBody>
              </Card>
            </CardWrapper>

            <Card>
              <CardBody>
                <Row>
                  <div className="d-flex justify-content-end w-100">
                    <button
                      onClick={() => history.push(`/users/edit/${id}`)}
                      className="btn btn-success"
                    >
                      Edit
                    </button>
                  </div>
                </Row>
                <Row>
                  <Col md={6}>
                    {user?.img ? (
                      <ImageWrapper>
                        <img
                          onClick={() => {
                            setIsOpen(true);
                            setSelectedImg(user?.img);
                          }}
                          className="img-fluid cursor-pointer"
                          alt="Veltrix"
                          src={user?.img}
                          width="100%"
                        />
                      </ImageWrapper>
                    ) : null}
                  </Col>
                  <Col md={6} className="mt-3 mt-md-0">
                    <div>
                      <Details>
                        <h5 className="title">Name:</h5>
                        <h4 className="value">{user?.name ?? "N/A"}.</h4>
                      </Details>
                      <Details>
                        <h5 className="title">Phone:</h5>
                        <h4 className="value">{user?.phoneNumber ?? "N/A"}.</h4>
                      </Details>
                      <Details>
                        <h5 className="title">Gmail:</h5>
                        <h4 className="value">{user?.email ?? "N/A"}.</h4>
                      </Details>
                      <Details>
                        <h5 className="title">DoB:</h5>
                        <h4 className="value">
                          {new Date(user?.dob).toDateString() ?? "N/A"}.
                        </h4>
                      </Details>
                      <Details>
                        <h5 className="title">Rating:</h5>

                        <Rating
                          name="read-only"
                          defaultValue={0.5}
                          value={user?.rating ?? 0}
                          precision={0.5}
                          readOnly
                        />
                      </Details>
                      <Details>
                        <h5 className="title">Balance:</h5>
                        <h4 className="value">
                          {parseInt(user?.balance) ?? "0.00"} TK.
                        </h4>
                      </Details>
                      <Details>
                        <h5 className="title">Status:</h5>
                        <h4 className="value">{user?.status}</h4>
                      </Details>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>

        {/* BALANCE ADD */}

        <Modal
          isOpen={modalCenter}
          toggle={() => {
            setModalCenter(!modalCenter);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add Balance</h5>
            <button
              type="button"
              onClick={() => {
                setModalCenter(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <BalanceAdd
              lisener={(data) => {
                user.balance = data.after;
                // console.log(amount)
                setModalCenter(false);
              }}
              id={user?.id}
              type="user"
            />
          </div>
        </Modal>

        {/* BALANCE WITHDROW */}

        <Modal
          isOpen={withdrowModalCenter}
          toggle={() => {
            setWithdrowModalCenter(!withdrowModalCenter);
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Withdrow Balance</h5>
            <button
              type="button"
              onClick={() => {
                setWithdrowModalCenter(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <WithdrawBalance
              lisener={(data) => {
                // console.log(data);
                user.balance = data.after;
                // console.log(amount)
                setWithdrowModalCenter(false);
              }}
              id={user?.id}
              type="user"
            />
          </div>
        </Modal>
      </GlobalWrapper>
    </React.Fragment>
  );
};

const ImageWrapper = styled.div`
  text-align: center;
  border-right: 1px solid lightgray;
  height: 230px;
  @media (max-width: 580px) {
    border-right: none;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 700px) {
    justify-content: center;
  }
  .item_wrapper {
    width: 150px;
    text-align: center;
    margin-right: 3px;
    color: green;
    font-size: 15px;
    font-weight: 500;
    font-style: italic;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1200px) {
    justify-content: center;
  }

  .title {
    color: black;
    margin-right: 8px px;
    font-weight: 600;
    margin-right: 10px;
  }
  .value {
    font-family: "themify";
    font-weight: 600;
    color: #0e0c0c;
  }
`;

export default UserDetails;
